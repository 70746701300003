<template>
  <div>
    <div class="cart" v-bind:style="`height:${window.height - 70}px`">
      <div
        :class="'cart-left ' + (extended ? 'extended' : '')"
        v-bind:style="`height:${window.height - 70}px`"
        v-if="extended"
      >
        <div class="cart-left_filter">
          <div class="extended-filter_header">
            <div class="title">{{ $store.getters.strings[$store.getters.currentLang].filter }}</div>
            <div class="close" @click="hideModal">
              <img src="../assets/images/close.svg" alt />
            </div>
          </div>
          <div class="extended-filter_body">
            <div class="form">
              <div class="form-region" @click="handleSelect">
                <div class="left">
                  <img src="../assets/images/pin-dark.svg" alt />
                  <div class="title">
                    {{
                    !this.currentRegion
                    ? $store.getters.strings[$store.getters.currentLang]
                    .uzbekistan
                    : this.currentRegion
                    }}
                  </div>
                </div>
                <div class="icon d-flex" :class="{ active: select === true }">
                  <img src="../assets/images/dropdown2.svg" alt />
                </div>
              </div>
              <div class="regions" v-if="select">
                <div class="regions-title" @click="updateChooseRegion()">
                  <span>
                    {{
                    $store.getters.strings[$store.getters.currentLang].every
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right-dark.svg" alt />
                  </div>
                </div>
                <div
                  class="reg"
                  v-for="region in getMainRegions"
                  :key="region.id"
                  @click="updateChooseParent(region)"
                >
                  <span v-if="$store.getters.currentLang == 1">
                    {{
                    region.name_ru
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 2">
                    {{
                    region.name_uz
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 3">
                    {{
                    region.name_kr
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right.svg" alt />
                    <img src="../assets/images/drop-right-hover.svg" alt />
                  </div>
                </div>
              </div>
              <div class="region-city" v-if="region_id && isOpen === true">
                <div class="region-city_title">
                  <span @click="updateChooseRegion(getChildRegions[0].parent_id)">
                    {{
                    $store.getters.strings[$store.getters.currentLang]
                    .everyChild
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right-dark.svg" alt />
                  </div>
                </div>
                <div
                  class="region-city_tash"
                  v-for="(reg, index) in getChildRegions"
                  :key="index"
                  @click="updateChooseRegion(reg.id)"
                >
                  <span v-if="$store.getters.currentLang == 1">
                    {{
                    reg.name_ru
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 2">
                    {{
                    reg.name_uz
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 3">
                    {{
                    reg.name_kr
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right.svg" alt />
                    <img src="../assets/images/drop-right-hover.svg" alt />
                  </div>
                </div>
              </div>
            </div>
            <div class="form">
              <SearchMulti
                :parent="categories"
                :children="childCategories"
                :setter="setGlobalCategory"
                :chosen="$route.params.id"
                v-if="categories.length > 0"
              />
              <div class="form-region" style="overflow:hidden;justify-content:center;" v-else>
                <ActivityLoader />
              </div>
            </div>
            <div class="input-wrap">
              <span>{{ $store.getters.strings[$store.getters.currentLang].price }}</span>
              <div class="inputs">
                <input
                  type="number"
                  name="from"
                  :value="getFrom"
                  @input="setFrom($event)"
                  class="from"
                  :placeholder="
                  $store.getters.strings[$store.getters.currentLang].priceFrom
                "
                />
                <input
                  type="number"
                  name="to"
                  class="to"
                  :value="getTo"
                  @input="setTo($event)"
                  :placeholder="
                  $store.getters.strings[$store.getters.currentLang].priceTo
                "
                />
              </div>
            </div>
            <template v-for="type in childTypes">
              <div :key="type.id">
                <div class="input-wrap">
                  <SelectMenu v-if="type.children.length >= 3" :data="type.children" :type="type" />
                  <RadioChoice
                    v-else-if="
                  type.children.length < 3 && type.children.length >= 1
                "
                    :data="type.children"
                    :type="type"
                  />
                  <TextChoice v-else :data="type" />
                </div>
                <template v-for="inner in typesChildren">
                  <div
                    class="input-wrap"
                    v-if="
                  inner.children.length >= 1 &&
                    checkForRelevance(inner) &&
                    parseInt(type.changeable) === 2
                "
                    :key="inner.id + 'child'"
                  >
                    <SelectMenu :data="inner.children" :type="inner" />
                  </div>
                </template>
              </div>
            </template>

            <div class="buttons">
              <!-- <button>Очистить</button> -->
              <button @click="apply">{{ $store.getters.strings[$store.getters.currentLang].apply }}</button>
            </div>
          </div>
        </div>
      </div>
      <div class="cart-center">
        <GmapMap
          :center="center"
          :zoom="12"
          map-type-id="roadmap"
          :options="{
              zoomControl: true,
              mapTypeControl: false,
              scaleControl: true,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUI: false,
              draggable: true,
              clickable: false,
            }"
          style="width: 100%; height: 100%;"
        >
          <template v-for="(m, index) in adverts">
            <GmapMarker
              :key="m.id"
              v-if="m.lat"
              :position="{ lat: Number(m.lat), lng: Number(m.long) }"
              :clickable="true"
              :draggable="false"
              :icon="markerOptions"
              :label="{'text': m.name, 'color': 'white'}"
              @click="toggleInfoWindow(m, index)"
            />
          </template>
          <gmap-info-window
            :options="infoOptions"
            :position="infoWindowPos"
            :opened="infoWinOpen"
            @closeclick="closeInfoWindow"
          >
            <img :src="currentImage ? currentImage :require('@/assets/images/photomap.png')" alt />
            <p class="info">{{infoContent}}</p>
            <div class="price">{{getPrice}} UZS</div>
          </gmap-info-window>
        </GmapMap>
      </div>
      <div class="cart-right">
        <div class="cart-right_wrap">
          <div class="title">{{ $store.getters.strings[$store.getters.currentLang].adverts }}</div>
          <div class="form">
            <OrderSelect :data="sorts" />
          </div>
          <template v-if="!advertLoading">
            <Products :products="adverts" />
            <AdvertNotFound v-if="!adverts.length" />
          </template>
          <AdvertLoading v-else />
          <pagination v-if="adverts.length > 20" :setter="setCurrentPage" :lastPage="lastPage" />
        </div>
      </div>
      <div class="cart-tablet" v-if="currentMidx">
        <div class="cart-tablet_remove">
          <button @click="closeInfoWindow">x</button>
        </div>
        <router-link :to="'/prewiew/'+getAdvertByIndex.id" class="cart-tablet_product">
          <div
            class="cart-tablet_product_left"
            :style="getAdvertByIndex.image.length
            ? `background-image: url(${getAdvertByIndex.image[0]});`
            : '' "
          >
            <div class="img-right">
              <img src="../assets/images/top-right.svg" />
            </div>
            <div class="img-bottom">
              <img src="../assets/images/orange.svg" alt />
            </div>
          </div>
          <div class="cart-tablet_product_right">
            <div class="title">{{getAdvertByIndex.name}}</div>
            <div class="about">
              <div class="city">
                <template v-if="$store.getters.currentLang == 1">
                  {{
                  getAdvertByIndex.region.name_ru
                  }}
                </template>
                <template v-else-if="$store.getters.currentLang == 2">
                  {{
                  getAdvertByIndex.region.name_uz
                  }}
                </template>
                <template v-else-if="$store.getters.currentLang == 3">
                  {{
                  getAdvertByIndex.region.name_kr
                  }}
                </template>
              </div>
              <div class="price">{{getPrice}} UZS</div>
            </div>
          </div>
        </router-link>
      </div>
    </div>
    <div class="filter-button" v-if="!extended" @click="showModal">
      <img src="../assets/images/filter-button.svg" alt />
      <span>{{ $store.getters.strings[$store.getters.currentLang].filterSimple }}</span>
    </div>
  </div>
</template>

<script>
import Vue from "vue";
import { mapGetters, mapActions } from "vuex";
import * as VueGoogleMaps from "vue2-google-maps";
import Products from "@/components/Products.vue";
import pagination from "@/components/Pagination.vue";
import SelectMenu from "@/components/SelectMenu";
import SearchSelect from "@/components/SearchSelect";
import SearchMulti from "@/components/SearchMulti";
import RadioChoice from "@/components/RadioChoice";
import TextChoice from "@/components/TextChoice";
import OrderSelect from "@/components/OrderSelect";
import ActivityLoader from "@/components/Preloaders/ActivityLoader.vue";
import AdvertLoading from "./AdvertLoading.vue";
import AdvertNotFound from "./AdvertNotFound.vue";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDyu7WJw2mR_TJ3xK-wZDv_k9Luk9HPq6c",
    language: "ru",
  },
});
const mapMarker = require("@/assets/images/marker.svg");

let vm;
export default {
  components: {
    Products,
    pagination,
    SelectMenu,
    SearchMulti,
    SearchSelect,
    ActivityLoader,
    TextChoice,
    RadioChoice,
    OrderSelect,
    AdvertNotFound,
    AdvertLoading,
  },
  data() {
    return {
      google: null,
      map: null,
      currentImage: null,
      getPrice: 0,
      /// google maps
      markerOptions: {
        url: mapMarker,
        size: { width: 170, height: 50, f: "px", b: "px" },
        scaledSize: { width: 170, height: 50, f: "px", b: "px" },
        labelColor: "white",
      },
      center: { lat: 41.311081, lng: 69.240562 },
      infoContent: "",
      infoOptions: {
        pixelOffset: {
          width: 0,
          height: 0,
        },
      },
      currentMidx: null,
      infoWindowPos: null,
      infoWinOpen: false,
      currentPage: 1,
      /// google maps end
      select: false,
      tashkentShow: false,
      extSelect: false,
      extended: false,
      select: false,
      isOpen: false,
      filter: false,
      extended: false,
      sorts: [
        {
          name_ru: "Сначала новые",
          name_uz: "Eng yangi",
          name_kr: "Энг янги",
          id: 1,
        },
        {
          name_ru: "Сначала старые",
          name_uz: "Eng eski",
          name_kr: "Энг эски",
          id: 2,
        },
        {
          name_ru: "Сначала дорогие",
          name_uz: "Eng qimmat",
          name_kr: "Энг қиммат",
          id: 3,
        },
        {
          name_ru: "Сначала дешёвые",
          name_uz: "Eng arzon",
          name_kr: "Энг арзон",
          id: 4,
        },
      ],
      window: {
        width: 0,
        height: 0,
      },
    };
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    vm = this;
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  mounted: function () {
    const routeId = parseInt(this.$route.params.id);
    if (routeId > 0) {
      this.searchTypeByCategory(routeId);
    }
    this.sendSearch();
  },
  methods: {
    setCurrentPage: function (value) {
      this.currentPage = value;
      this.sendSearch();
    },
    closeInfoWindow: function () {
      this.currentMidx = null;
      this.infoWinOpen = false;
    },
    handleResize: function () {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    toggleInfoWindow: function (marker, idx) {
      this.infoWindowPos = {
        lat: Number(marker.lat),
        lng: Number(marker.long),
      };
      this.infoContent = marker.name;
      this.getPrice = marker.price;
      this.currentImage = marker.image[0];
      if (this.currentMidx == idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    showModal: function () {
      document.body.style.overflow = "hidden";
      this.extended = true;
    },
    hideModal: function () {
      document.body.style.overflow = "auto";
      this.extended = false;
    },
    apply: function () {
      this.sendSearch();
      if (this.window.width < 992) {
        this.hideModal();
      }
    },
    setKeyword: function ($event) {
      this.$store.commit("setKeyword", $event.target.value);
    },
    sendSearch: function () {
      const routeId = parseInt(this.$route.params.id);
      const infinityNumber = 9999999999;
      const getTypeValue = document.querySelectorAll(".type_value");
      const sortBy = document.querySelector(".sort_by");
      const orderBy = document.querySelector(".order_by");
      const price_range = `${
        this.getFrom.length ? parseInt(this.getFrom) : 0
      },${this.getTo.length ? parseInt(this.getTo) : infinityNumber}`;
      let type_value = [];
      if (getTypeValue.length)
        getTypeValue.forEach((element) => {
          type_value.push(JSON.parse(element.value));
        });
      const body = {
        category_id: routeId,
        region_id: this.region_id,
        ...(this.keyword.length && { keyword: this.keyword }),
        price_range: price_range,
        ...(sortBy && { sort_by: sortBy.value }),
        ...(orderBy && { order_by: orderBy.value }),
        ...(type_value.length && { type_value: JSON.stringify(type_value) }),
        page: this.currentPage,
      };
      this.searchAdvert(body);
    },
    hideModal: function () {
      this.extended = false;
      document.body.style.overflow = "auto";
      document.querySelector(".myModal").className = "myModal";
    },
    handleFilter: function (event) {
      const getClass = event.target.classList;
      if (!(getClass.contains("item") || getClass.contains("links")))
        this.filter = !this.filter;
    },
    ...mapActions([
      "fetchData",
      "regionHandler",
      "handleChooseRegion",
      "handleChooseParent",
      "searchTypeByCategory",
      "searchAdvert",
      "setGlobalItem",
    ]),
    checkForRelevance: function (inner) {
      return this.chosenTypes.filter(
        (choice) => choice.value === parseInt(inner.parent_id)
      ).length;
    },
    updateChooseParent: function (region) {
      this.isOpen = true;
      this.select = true;
      this.handleChooseParent(region);
    },
    updateChooseRegion: function (id) {
      this.isOpen = false;
      this.select = false;
      if (parseInt(id)) this.handleChooseRegion(id);
      else this.setGlobalItem();
    },
    handleSelect: function () {
      this.select = !this.select;
    },
    setGlobalCategory: function (id) {
      if (parseInt(id) !== parseInt(this.$route.params.id)) {
        this.$router.push({ path: "/cart/" + id });
        this.sendSearch();
        if (id != 0) this.searchTypeByCategory(id);
        else this.$store.commit("setChildTypes", []);
      }
    },
    getChildren: function (parent_id) {
      return this.childCategories.filter(
        (child) => child.parent_id === parent_id
      );
    },
    setTo: function ($event) {
      this.$store.commit("handleTo", $event.target.value);
    },
    setFrom: function ($event) {
      this.$store.commit("handleFrom", $event.target.value);
    },
  },
  computed: {
    ...mapGetters([
      "regions",
      "currentRegion",
      "region_id",
      "parent_id",
      "getMainRegions",
      "getMainParent",
      "getChildRegions",
      "childCategories",
      "categories",
      "childTypes",
      "adverts",
      "advertLoading",
      "keyword",
      "getFrom",
      "getTo",
      "typesChildren",
      "chosenTypes",
      "lastPage",
    ]),
    getAdvertByIndex: function () {
      return this.adverts.filter((item, i) => i === this.currentMidx)[0];
    },
    getChosenChildren: function () {
      const breadcrumb = this.getBreadCrumbItems;
      const getChosen = breadcrumb[breadcrumb.length - 1];
      if (!getChosen) return this.categories;
      const getChosenChildren = this.childCategories.filter(
        (child) => parseInt(child.parent_id) === getChosen.id
      );
      return getChosenChildren;
    },
  },
};
</script>
