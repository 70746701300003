<template>
  <div>
    <span v-if="$store.getters.currentLang == 1">{{ data.name_ru }}</span>
    <span v-else-if="$store.getters.currentLang == 2">{{ data.name_uz }}</span>
    <span v-else-if="$store.getters.currentLang == 3">{{ data.name_kr }}</span>
    <div class="inputs">
      <input
        type="number"
        v-model="from"
        :placeholder="$store.getters.strings[$store.getters.currentLang].from"
      />
      <input
        type="number"
        v-model="to"
        :placeholder="$store.getters.strings[$store.getters.currentLang].to"
      />
    </div>
    <input
      type="hidden"
      class="type_value"
      v-if="from.length > 0 || to.length > 0"
      :value="getValue"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      from: "",
      to: "",
    };
  },
  computed: {
    getValue: function() {
      return JSON.stringify([
        this.data.id,
        [
          this.from.length ? this.from * 1 : 0,
          this.to.length ? this.to * 1 : 999,
        ],
      ]);
    },
    ...mapGetters(["strings"]),
  },
};
</script>
