export default {
  actions: {
    async getPaymentUrl({ getters }, value) {
      const { price, type } = value;
      const url = type == 1 ? "uzcard" : "octo";
      const body = {
        price: price,
      };
      const res = await fetch(
        "https://admin.topin.uz/api/payment/" +
          url +
          "?" +
          new URLSearchParams(body),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getters.token}`,
          },
        }
      );
      const val = await res.json();
      return val.data;
    },
    async changeToBusiness({ dispatch, getters }) {
      const response = await fetch(
        "https://admin.topin.uz/api/business/limit",
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getters.token}`,
          },
        }
      );
      if (response.status === 200) {
        dispatch("getUserData");
      }
      return response.status === 200;
    },
  },
};
