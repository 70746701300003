<template>
  <div class="enter">
    <form @submit.prevent="submit" autocomplete="off" class="enter-registration container">
      <div class="enter-registration_header">
        <span>{{ $store.getters.strings[$store.getters.currentLang].profile }}</span>
        <span>{{ $store.getters.strings[$store.getters.currentLang].simpleAcc }}</span>
      </div>
      <div class="enter-registration_body">
        <div class="enter-registration_body_data">
          <div class="title">{{ $store.getters.strings[$store.getters.currentLang].personal }}</div>
          <div class="content">
            <div class="big">
              <div class="block">
                <label>{{ $store.getters.strings[$store.getters.currentLang].fio }}:</label>
                <input type="text" required="required" name="name" class="name" />
              </div>
              <div class="block">
                <label>{{ $store.getters.strings[$store.getters.currentLang].birthday }}:</label>
                <input type="date" required="required" name="birthday" class="birthdate" />
              </div>
              <div class="block-2">
                <div class="column">
                  <SelectGender
                    :data="gender"
                    :title="$store.getters.strings[$store.getters.currentLang].sex+':'"
                    name="sex"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="wrap">
          <div class="enter-registration_body_location">
            <div class="title">{{ $store.getters.strings[$store.getters.currentLang].location }}</div>
            <div class="content">
              <div class="country">
                <label>{{ $store.getters.strings[$store.getters.currentLang].region }}:</label>
                <input
                  type="hidden"
                  name="country"
                  v-bind:value="this.currentRegion ? $store.getters.strings[$store.getters.currentLang].uzbekistan : this.currentRegion"
                />
                <div class="uzb" @click="handleSelect">
                  <span>
                    {{
                    !this.currentRegion ? $store.getters.strings[$store.getters.currentLang].uzbekistan : this.currentRegion
                    }}
                  </span>
                  <div class="icon" :class="{ active: select === true }">
                    <img src="../assets/images/dropdown2.svg" alt />
                  </div>
                </div>
                <div class="regions" v-if="select">
                  <div class="regions-title">
                    <span>{{ $store.getters.strings[$store.getters.currentLang].every }}</span>
                    <div class="icon">
                      <img src="../assets/images/drop-right-dark.svg" alt />
                    </div>
                  </div>
                  <div
                    class="reg"
                    v-for="region in getMainRegions"
                    :key="region.id"
                    @click="updateChooseParent(region)"
                  >
                    <span>
                      <template v-if="$store.getters.currentLang == 1">{{ region.name_ru }}</template>
                      <template v-else-if="$store.getters.currentLang == 2">{{ region.name_uz }}</template>
                      <template v-else-if="$store.getters.currentLang == 3">{{ region.name_kr }}</template>
                    </span>
                    <div class="icon">
                      <img src="../assets/images/drop-right.svg" alt />
                      <img src="../assets/images/drop-right-hover.svg" alt />
                    </div>
                  </div>
                </div>
                <div class="region-city" v-if="region_id && isOpen === true">
                  <div class="region-city_title">
                    <span @click="updateChooseRegion(getChildRegions[0].parent_id)">По всему региону</span>
                    <div class="icon">
                      <img src="../assets/images/drop-right-dark.svg" alt />
                    </div>
                  </div>
                  <div
                    class="region-city_tash"
                    v-for="(reg, index) in getChildRegions"
                    :key="index"
                    @click="updateChooseRegion(reg.id)"
                  >
                    <span>
                      <template v-if="$store.getters.currentLang == 1">{{ reg.name_ru }}</template>
                      <template v-else-if="$store.getters.currentLang == 2">{{ reg.name_uz }}</template>
                      <template v-else-if="$store.getters.currentLang == 3">{{ reg.name_kr }}</template>
                    </span>
                    <div class="icon">
                      <img src="../assets/images/drop-right.svg" alt />
                      <img src="../assets/images/drop-right-hover.svg" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="empty"></div>
        </div>

        <div class="wrap">
          <div class="enter-registration_body_contacts">
            <div class="title">{{ $store.getters.strings[$store.getters.currentLang].contactInfo }}</div>
            <div class="content">
              <div class="block">
                <label>{{ $store.getters.strings[$store.getters.currentLang].email }}:</label>
                <input class="mail" type="email" required="required" name="email" />
              </div>
              <div class="block">
                <label>{{ $store.getters.strings[$store.getters.currentLang].phone }}:</label>
                <input
                  class="mail"
                  name="phone"
                  type="tel"
                  :value="phone"
                  required="required"
                  disabled="disabled"
                />
              </div>
              <div class="block"></div>
            </div>
            <div class="save">
              <div class="empty2"></div>
              <button>{{ $store.getters.strings[$store.getters.currentLang].save }}</button>
            </div>
          </div>
        </div>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import SelectGender from "@/components/SelectGender";
import Loading from "./Loading.vue";

export default {
  components: { SelectGender, Loading },
  data() {
    return {
      select: false,
      isOpen: false,
      loading: false,
      regionId: 0,
      gender: [
        {
          id: 1,
          name_ru: "Мужчина",
          name_uz: "Erkak",
          name_kr: "Эркак",
        },
        {
          id: 2,
          name_ru: "Женщина",
          name_uz: "Ayol",
          name_kr: "Аёл",
        },
      ],
    };
  },
  methods: {
    submit: function (submitEvent) {
      const vm = this;
      this.loading = true;
      const inputs = submitEvent.target.elements;
      const { email, birthday, name, is_business, sex, country } = inputs;
      const body = {
        email: email.value,
        birthday: birthday.value,
        name: name.value,
        is_business: 0,
        sex: sex.value,
        country: country.value,
      };
      this.updateAccount(body)
        .then((value) => {
          if (value) {
            this.$router.push("/profile");
          }
          this.loading = false;
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    updateChooseParent: function (region) {
      this.isOpen = true;
      this.select = true;
      this.handleChooseParent(region);
    },
    updateChooseRegion: function (id) {
      this.isOpen = false;
      this.select = false;
      this.handleChooseRegion(id);
      this.regionId = id;
    },
    handleSelect: function () {
      this.select = !this.select;
    },
    ...mapActions([
      "regionHandler",
      "handleChooseRegion",
      "handleChooseParent",
      "updateAccount",
    ]),
  },
  computed: {
    ...mapGetters([
      "regions",
      "currentRegion",
      "region_id",
      "parent_id",
      "getMainRegions",
      "getMainParent",
      "getChildRegions",
      "phone",
      "business",
    ]),
  },
  mounted() {},
};
</script>
