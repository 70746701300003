<template>
  <div class="mobile">
    <router-link
      :to="'/prewiew/' + product.id"
      class="product-full"
      v-for="(product, index) in products"
      :key="index"
    >
      <div
        class="product-left"
        :style="
          product.image.length
            ? `background-image: url(${product.image[0]});`
            : ''
        "
      >
        <img src="../assets/images/top.svg" v-if="product.is_premium" />
        <div class="img-right">
          <img src="../assets/images/top-right.svg" />
        </div>
        <div class="img-bottom">
          <img src="../assets/images/orange.svg" alt />
        </div>
      </div>
      <div
        class="product-full_right"
        :class="{ premium: product.is_premium === true }"
      >
        <div class="title">
          <span>{{ product.name }}</span>
          <span>{{ product.price }} UZS</span>
        </div>
        <div class="adress">
          <span>
            {{ product.breadcrumb.map((bread) => bread.name_ru).join(" / ") }}
          </span>
          <span>Опубликовал: {{ product.user.name }}</span>
        </div>
        <div class="city">
          <span v-if="$store.getters.currentLang == 1">{{
            product.region.name_ru
          }}</span>
          <span v-else-if="$store.getters.currentLang == 2">{{
            product.region.name_uz
          }}</span>
          <span v-else-if="$store.getters.currentLang == 3">{{
            product.region.name_kr
          }}</span>
          <span>Добавлено в {{ product.created_at.slice(0, 10) }}</span>
        </div>
      </div>
    </router-link>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  props: {
    products: {
      type: Array,
      required: true,
    },
  },
  computed: mapGetters(["currentLang"]),
};
</script>
