<template>
  <div class="form-region">
    <div class="blog" @click="show ? (show = false) : handelSelect(-1)">
      <div class="wrapp d-flex">
        <img src="../assets/images/new.svg" alt />
        <span>{{ getSelected }}</span>
      </div>
      <div class="icon d-flex" :class="{ active: show === true }">
        <img src="../assets/images/dropdown2.svg" alt />
      </div>
    </div>
    <div class="regions" v-if="show">
      <div
        :class="'reg ' + (selected === dat.id ? 'selected' : '')"
        v-for="(dat, index) in data"
        :key="index"
        @click="handelSelect(dat.id)"
      >
        <span v-if="$store.getters.currentLang == 1">{{ dat.name_ru }}</span>
        <span v-else-if="$store.getters.currentLang == 2">
          {{
          dat.name_uz
          }}
        </span>
        <span v-else-if="$store.getters.currentLang == 3">
          {{
          dat.name_kr
          }}
        </span>
      </div>
    </div>
    <input
      v-if="selected > 0"
      type="hidden"
      name="order_by"
      class="order_by"
      :value="selected === 1 || selected === 3 ? 'DESC' : 'ASC'"
    />
    <input
      v-if="selected > 0"
      type="hidden"
      name="sort_by"
      class="sort_by"
      :value="selected === 1 || selected === 2 ? 'created_at' : 'price'"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      show: false,
      selected: 0,
    };
  },
  methods: {
    handelSelect: function (id) {
      if (!this.show) {
        this.show = true;
      }
      if (id >= 0) {
        if (id != this.selected) {
          this.selected = id;
        }
        this.show = false;
      }
    },
  },
  computed: {
    getSelected: function () {
      if (this.selected == 0) {
        return this.$store.getters.strings[this.$store.getters.currentLang]
          .chooseList;
      }
      const result = this.data.filter((data) => data.id == this.selected)[0];
      if (this.$store.getters.currentLang == 1) return result.name_ru;
      if (this.$store.getters.currentLang == 2) return result.name_uz;
      if (this.$store.getters.currentLang == 3) return result.name_kr;
    },
    ...mapGetters(["strings"]),
  },
};
</script>
