<template>
  <div class="search-page">
    <div
      :class="`extended-filter ${extended ? 'show-filter' : 'hide-filter'}`"
      v-if="user"
    >
      <div class="extended-filter_header">
        <div class="title">
          {{ $store.getters.strings[$store.getters.currentLang].filter }}
        </div>
        <div class="close" @click="hideModal">
          <img src="../assets/images/close.svg" alt />
        </div>
      </div>
      <div class="extended-filter_body">
        <template v-for="type in childTypes">
          <div :key="type.id">
            <div class="input-wrap">
              <SelectMenu
                v-if="type.children.length >= 3"
                :data="type.children"
                :type="type"
              />
              <RadioChoice
                v-else-if="
                  type.children.length < 3 && type.children.length >= 1
                "
                :data="type.children"
                :type="type"
              />
              <TextChoice v-else :data="type" />
            </div>
            <template v-for="inner in typesChildren">
              <div
                class="input-wrap"
                v-if="
                  inner.children.length >= 1 &&
                  checkForRelevance(inner) &&
                  parseInt(type.changeable) === 2
                "
                :key="inner.id + 'child'"
              >
                <SelectMenu :data="inner.children" :type="inner" />
              </div>
            </template>
          </div>
        </template>

        <div class="buttons">
          <!-- <button>Очистить</button> -->
          <button @click="apply">
            {{ $store.getters.strings[$store.getters.currentLang].apply }}
          </button>
        </div>
      </div>
    </div>
    <div class="home-section container" v-if="user">
      <div class="home-section_reclam">
        <div>
          <div class="user">
            <div class="user-header">
              <div class="user-header_title">
                {{
                  $store.getters.strings[$store.getters.currentLang].contactFace
                }}
              </div>
            </div>
            <div class="user-body">
              <div class="person">
                <div class="person-img">
                  <img
                    :src="
                      user.image
                        ? user.image
                        : require('../assets/images/person.svg')
                    "
                    alt
                  />
                </div>
                <div class="person-name">{{ user.name }}</div>
              </div>
              <div class="info">
                <div class="block">
                  <div class="title">
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .website
                    }}:
                  </div>
                  <span>{{ user.website }}</span>
                </div>
                <div class="block">
                  <div class="title">
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .inSystem
                    }}:
                  </div>
                  <span>{{ user.created_at.slice(0, 10) }}</span>
                </div>
                <div class="block">
                  <div class="title">
                    {{
                      $store.getters.strings[$store.getters.currentLang].sphere
                    }}:
                  </div>
                  <span>{{ getChosenCategory }}</span>
                </div>
                <div class="block">
                  <div class="title">
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .accType
                    }}:
                  </div>
                  <span style="color: #ff7800">
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .bussinessAcc
                    }}
                  </span>
                </div>
                <div class="block">
                  <div class="title">
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .address
                    }}:
                  </div>
                  <span>{{ user.address }}</span>
                </div>
              </div>
            </div>
          </div>
          <div class="user-descr">
            <div class="user-descr_title">
              {{ $store.getters.strings[$store.getters.currentLang].addDescr }}
            </div>
            <span>{{ user.description }}</span>
          </div>
        </div>
        <div class="user-reclam-img">
          <div class="reclam-img"></div>
        </div>
      </div>
      <div class="right">
        <div class="big-img">
          <img :src="user.banner" alt />
        </div>
        <form
          autocomplete="off"
          class="home-section_form"
          @submit.prevent="sendSearch"
        >
          <div class="form">
            <input
              type="text"
              name="keyword"
              class="keyword"
              @input="setKeyword($event)"
              :value="keyword"
              :placeholder="
                $store.getters.strings[$store.getters.currentLang].keyword
              "
            />
            <div class="price">
              <input
                type="number"
                name="from"
                :value="getFrom"
                @input="setFrom($event)"
                class="from"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang].priceFrom
                "
              />
              <span>-</span>
              <input
                type="number"
                name="to"
                class="to"
                :value="getTo"
                @input="setTo($event)"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang].priceTo
                "
              />
            </div>
          </div>
          <div class="form">
            <div class="form-region" @click="handleSelect">
              <div class="left">
                <img src="../assets/images/pin-dark.svg" alt />
                <div class="title">
                  {{
                    !this.currentRegion
                      ? $store.getters.strings[$store.getters.currentLang]
                          .uzbekistan
                      : this.currentRegion
                  }}
                </div>
              </div>
              <div class="icon d-flex" :class="{ active: select === true }">
                <img src="../assets/images/dropdown2.svg" alt />
              </div>
            </div>
            <div class="regions" v-if="select">
              <div class="regions-title" @click="updateChooseRegion()">
                <span>
                  {{ $store.getters.strings[$store.getters.currentLang].every }}
                </span>
                <div class="icon">
                  <img src="../assets/images/drop-right-dark.svg" alt />
                </div>
              </div>
              <div
                class="reg"
                v-for="region in getMainRegions"
                :key="region.id"
                @click="updateChooseParent(region)"
              >
                <span v-if="$store.getters.currentLang == 1">
                  {{ region.name_ru }}
                </span>
                <span v-else-if="$store.getters.currentLang == 2">
                  {{ region.name_uz }}
                </span>
                <span v-else-if="$store.getters.currentLang == 3">
                  {{ region.name_kr }}
                </span>
                <div class="icon">
                  <img src="../assets/images/drop-right.svg" alt />
                  <img src="../assets/images/drop-right-hover.svg" alt />
                </div>
              </div>
            </div>
            <div class="region-city" v-if="region_id && isOpen === true">
              <div class="region-city_title">
                <span @click="updateChooseRegion(getChildRegions[0].parent_id)">
                  {{
                    $store.getters.strings[$store.getters.currentLang]
                      .everyChild
                  }}
                </span>
                <div class="icon">
                  <img src="../assets/images/drop-right-dark.svg" alt />
                </div>
              </div>
              <div
                class="region-city_tash"
                v-for="(reg, index) in getChildRegions"
                :key="index"
                @click="updateChooseRegion(reg.id)"
              >
                <span v-if="$store.getters.currentLang == 1">
                  {{ reg.name_ru }}
                </span>
                <span v-else-if="$store.getters.currentLang == 2">
                  {{ reg.name_uz }}
                </span>
                <span v-else-if="$store.getters.currentLang == 3">
                  {{ reg.name_kr }}
                </span>
                <div class="icon">
                  <img src="../assets/images/drop-right.svg" alt />
                  <img src="../assets/images/drop-right-hover.svg" alt />
                </div>
              </div>
            </div>
            <!-- ============================================================================================= -->
            <OrderSelect :data="sorts" />

            <button type="button" v-if="childTypes.length" @click="showModal">
              <img src="../assets/images/filter-button1.svg" alt />
              <span>
                {{ $store.getters.strings[$store.getters.currentLang].filter }}
              </span>
              <img src="../assets/images/filter-button2.svg" alt />
            </button>
          </div>
          <div class="form">
            <!-- =================================================================================================== -->
            <SearchMulti
              :parent="categories"
              :children="childCategories"
              :setter="setGlobalCategory"
              :chosen="category_id.toString()"
              v-if="categories.length > 0"
            />
            <div
              class="form-region"
              style="overflow: hidden; justify-content: center"
              v-else
            >
              <ActivityLoader />
            </div>

            <div class="icons-control">
              <div class="title">
                {{ $store.getters.strings[$store.getters.currentLang].adview }}
              </div>
              <div class="grid-theme">
                <div
                  class="grid"
                  :class="{ active: grid }"
                  @click="grid = true"
                >
                  <img src="../assets/images/grid.svg" alt />
                </div>
                <div
                  class="list"
                  :class="{ active: !grid }"
                  @click="grid = false"
                >
                  <img src="../assets/images/list.svg" alt />
                </div>
              </div>
            </div>
            <button type="submit" class="button-filter">
              <img src="../assets/images/filter-button3.svg" alt />
              <span>
                {{
                  $store.getters.strings[$store.getters.currentLang].applyFilter
                }}
              </span>
            </button>
          </div>
          <div class="form-mobile">
            <input
              type="text"
              name="keyword"
              class="keyword"
              @input="setKeyword($event)"
              :value="keyword"
              :placeholder="
                $store.getters.strings[$store.getters.currentLang].keyword
              "
            />
            <div class="price">
              <input
                type="number"
                name="from"
                :value="getFrom"
                @input="setFrom($event)"
                class="from"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang].priceFrom
                "
              />
              <span>-</span>
              <input
                type="number"
                name="to"
                class="to"
                :value="getTo"
                @input="setTo($event)"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang].priceTo
                "
              />
            </div>
            <div class="form-region" @click="select = !select">
              <div class="left">
                <img src="../assets/images/pin-dark.svg" alt />
                <div class="title">
                  {{
                    !this.currentRegion
                      ? $store.getters.strings[$store.getters.currentLang]
                          .uzbekistan
                      : this.currentRegion
                  }}
                </div>
              </div>
              <div class="icon" :class="{ active: select === true }">
                <img src="../assets/images/dropdown2.svg" alt />
              </div>
            </div>
            <div class="multi-wrap">
              <div class="regions" v-if="select">
                <div class="regions-title" @click="updateChooseRegion()">
                  <span>
                    {{
                      $store.getters.strings[$store.getters.currentLang].every
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right-dark.svg" alt />
                  </div>
                </div>
                <div
                  class="reg"
                  v-for="region in getMainRegions"
                  :key="region.id"
                  @click="updateChooseParent(region)"
                >
                  <span v-if="$store.getters.currentLang == 1">
                    {{ region.name_ru }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 2">
                    {{ region.name_uz }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 3">
                    {{ region.name_kr }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right.svg" alt />
                    <img src="../assets/images/drop-right-hover.svg" alt />
                  </div>
                </div>
              </div>
              <div class="region-city" v-if="region_id && isOpen === true">
                <div class="region-city_title">
                  <span
                    @click="updateChooseRegion(getChildRegions[0].parent_id)"
                  >
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .everyChild
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right-dark.svg" alt />
                  </div>
                </div>
                <div
                  class="region-city_tash"
                  v-for="(reg, index) in getChildRegions"
                  :key="index"
                  @click="updateChooseRegion(reg.id)"
                >
                  <span v-if="$store.getters.currentLang == 1">
                    {{ reg.name_ru }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 2">
                    {{ reg.name_uz }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 3">
                    {{ reg.name_kr }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right.svg" alt />
                    <img src="../assets/images/drop-right-hover.svg" alt />
                  </div>
                </div>
              </div>
            </div>

            <SearchSelect :data="sorts" />
            <SearchMulti
              :parent="categories"
              :children="childCategories"
              :setter="setGlobalCategory"
              :chosen="this.$route.params.id"
              v-if="categories.length > 0"
            />
            <div
              class="form-region"
              style="overflow: hidden; justify-content: center"
              v-else
            >
              <ActivityLoader />
            </div>
            <button type="button" @click="showModal">
              <img src="../assets/images/filter-button1.svg" alt />
              <span>
                {{ $store.getters.strings[$store.getters.currentLang].filter }}
              </span>
              <img src="../assets/images/filter-button2.svg" alt />
            </button>
            <button type="submit" class="button-filter">
              <img src="../assets/images/filter-button3.svg" alt />
              <span>
                {{
                  $store.getters.strings[$store.getters.currentLang].applyFilter
                }}
              </span>
            </button>
          </div>
        </form>
        <div class="filtre" v-if="categories.length">
          <div class="filtre-header" @click="handleFilter">
            <div class="links">
              <div class="item" @click="setGlobalCategory(0)">
                {{ $store.getters.strings[$store.getters.currentLang].allAds }}
              </div>
              <template v-for="(item, key) in getBreadCrumbItems">
                <div :key="item.id" style="display: flex; align-items: center">
                  <span>/</span>
                  <div
                    class="item"
                    v-if="key !== getBreadCrumbItems.length - 1"
                    @click="setGlobalCategory(item.id)"
                  >
                    <template v-if="$store.getters.currentLang == 1">{{
                      item.name_ru
                    }}</template>
                    <template v-else-if="$store.getters.currentLang == 2">{{
                      item.name_uz
                    }}</template>
                    <template v-else-if="$store.getters.currentLang == 3">{{
                      item.name_kr
                    }}</template>
                  </div>
                  <div class="item inactive" v-else>
                    <template v-if="$store.getters.currentLang == 1">{{
                      item.name_ru
                    }}</template>
                    <template v-else-if="$store.getters.currentLang == 2">{{
                      item.name_uz
                    }}</template>
                    <template v-else-if="$store.getters.currentLang == 3">{{
                      item.name_kr
                    }}</template>
                  </div>
                </div>
              </template>
            </div>
            <div class="icon" :class="{ rotate: !filter }">
              <img src="../assets/images/dropdown.svg" alt />
            </div>
          </div>
          <div class="filtre-body" v-if="!filter && childCategories.length">
            <div class="block">
              <template v-if="category_id">
                <div class="title" v-if="$store.getters.currentLang == 1">
                  {{
                    getBreadCrumbItems[getBreadCrumbItems.length - 1].name_ru
                  }}
                </div>
                <div class="title" v-else-if="$store.getters.currentLang == 2">
                  {{
                    getBreadCrumbItems[getBreadCrumbItems.length - 1].name_uz
                  }}
                </div>
                <div class="title" v-else-if="$store.getters.currentLang == 3">
                  {{
                    getBreadCrumbItems[getBreadCrumbItems.length - 1].name_kr
                  }}
                </div>
                <span
                  v-for="(bread, key) in getChosenChildren"
                  :key="key"
                  @click="setGlobalCategory(bread.id)"
                >
                  <template v-if="$store.getters.currentLang == 1">{{
                    bread.name_ru
                  }}</template>
                  <template v-else-if="$store.getters.currentLang == 2">{{
                    bread.name_uz
                  }}</template>
                  <template v-else-if="$store.getters.currentLang == 3">{{
                    bread.name_kr
                  }}</template>
                </span>
              </template>
              <template v-else>
                <div class="title">
                  {{
                    $store.getters.strings[$store.getters.currentLang].allAds
                  }}
                </div>
                <span
                  v-for="(bread, key) in getChosenChildren"
                  :key="key"
                  @click="setGlobalCategory(bread.id)"
                >
                  <template v-if="$store.getters.currentLang == 1">{{
                    bread.name_ru
                  }}</template>
                  <template v-else-if="$store.getters.currentLang == 2">{{
                    bread.name_uz
                  }}</template>
                  <template v-else-if="$store.getters.currentLang == 3">{{
                    bread.name_kr
                  }}</template>
                </span>
              </template>
            </div>
          </div>
        </div>
        <div class="filtre" v-else>
          <div class="filtre-header">
            <ActivityLoader />
          </div>
        </div>
        <div class="home-section_products" :class="{ full: !grid }">
          <template v-if="!advertLoading">
            <Products
              :products="adverts"
              :class="{ full: !grid }"
              v-if="grid"
            />
            <ProductsFull
              :products="adverts"
              :class="{ full: !grid }"
              v-if="!grid"
            />
            <AdvertNotFound v-if="!adverts.length" />
          </template>
          <AdvertLoading v-else />
        </div>
        <div class="container" v-if="adverts.length > 20">
          <pagination :setter="setCurrentPage" :lastPage="lastPage" />
        </div>
      </div>
    </div>
    <div class="home-section container" v-else>
      <div style="height: 600px"></div>
      <Loading />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import pagination from "@/components/Pagination";
import Products from "@/components/Products.vue";
import ProductsFull from "@/components/ProductsFull.vue";
import SelectMenu from "@/components/SelectMenu";
import SearchSelect from "@/components/SearchSelect";
import SearchMulti from "@/components/SearchMulti";
import RadioChoice from "@/components/RadioChoice";
import TextChoice from "@/components/TextChoice";
import OrderSelect from "@/components/OrderSelect";
import ActivityLoader from "@/components/Preloaders/ActivityLoader.vue";
import AdvertLoading from "./AdvertLoading.vue";
import AdvertNotFound from "./AdvertNotFound.vue";
import Loading from "./Loading.vue";
let vm;
export default {
  components: {
    Products,
    ProductsFull,
    SearchMulti,
    pagination,
    SelectMenu,
    SearchSelect,
    ActivityLoader,
    TextChoice,
    RadioChoice,
    OrderSelect,
    AdvertLoading,
    AdvertNotFound,
    Loading,
  },
  data() {
    return {
      user: null,
      grid: true,
      select: false,
      isOpen: false,
      filter: false,
      currentPage: 1,
      extended: false,
      category_id: 0,
      sorts: [
        {
          name_ru: "Сначала новые",
          name_uz: "Eng yangi",
          name_kr: "Энг янги",
          id: 1,
        },
        {
          name_ru: "Сначала старые",
          name_uz: "Eng eski",
          name_kr: "Энг эски",
          id: 2,
        },
        {
          name_ru: "Сначала дорогие",
          name_uz: "Eng qimmat",
          name_kr: "Энг қиммат",
          id: 3,
        },
        {
          name_ru: "Сначала дешёвые",
          name_uz: "Eng arzon",
          name_kr: "Энг арзон",
          id: 4,
        },
      ],
    };
  },
  mounted: function () {
    const userId = this.$route.params.id;
    this.getUserById(userId);
    vm = this;
    document.addEventListener("readystatechange", function (event) {
      document.querySelector(".myModal").addEventListener("click", function () {
        let checker = this.classList.contains("showModal");
        if (checker) {
          vm.hideModal();
        }
      });
    });
  },
  methods: {
    getUserById: function (id) {
      fetch("https://admin.topin.uz/api/user/by/" + id)
        .then((res) => res.json())
        .then((value) => {
          this.user = value.user;
        })
        .then((val) => {
          this.sendSearch();
        });
    },
    showModal: function () {
      this.extended = true;
      document.body.style.overflow = "hidden";
      document.querySelector(".myModal").className = "myModal showModal";
    },
    setCurrentPage: function (value) {
      this.currentPage = value;
      this.sendSearch();
    },
    apply: function () {
      this.sendSearch();
      this.hideModal();
    },
    setKeyword: function ($event) {
      this.$store.commit("setKeyword", $event.target.value);
    },
    sendSearch: function () {
      const category_id = this.category_id;
      const infinityNumber = 9999999999;
      const getTypeValue = document.querySelectorAll(".type_value");
      const sortBy = document.querySelector(".sort_by");
      const orderBy = document.querySelector(".order_by");
      const price_range = `${
        this.getFrom.length ? parseInt(this.getFrom) : 0
      },${this.getTo.length ? parseInt(this.getTo) : infinityNumber}`;
      let type_value = [];
      if (getTypeValue.length)
        getTypeValue.forEach((element) => {
          type_value.push(JSON.parse(element.value));
        });
      const body = {
        category_id: category_id,
        region_id: this.region_id,
        user_id: this.user.id,
        ...(this.keyword.length && { keyword: this.keyword }),
        price_range: price_range,
        ...(sortBy && { sort_by: sortBy.value }),
        ...(orderBy && { order_by: orderBy.value }),
        ...(type_value.length && { type_value: JSON.stringify(type_value) }),
        page: this.currentPage,
      };
      this.searchAdvert(body);
    },
    hideModal: function () {
      this.extended = false;
      document.body.style.overflow = "auto";
      document.querySelector(".myModal").className = "myModal";
    },
    handleFilter: function (event) {
      const getClass = event.target.classList;
      if (!(getClass.contains("item") || getClass.contains("links")))
        this.filter = !this.filter;
    },
    ...mapActions([
      "fetchData",
      "regionHandler",
      "handleChooseRegion",
      "handleChooseParent",
      "searchTypeByCategory",
      "searchAdvert",
      "setGlobalItem",
    ]),
    checkForRelevance: function (inner) {
      return this.chosenTypes.filter(
        (choice) => choice.value === parseInt(inner.parent_id)
      ).length;
    },
    updateChooseParent: function (region) {
      this.isOpen = true;
      this.select = true;
      this.handleChooseParent(region);
    },
    updateChooseRegion: function (id) {
      this.isOpen = false;
      this.select = false;
      if (parseInt(id)) this.handleChooseRegion(id);
      else this.setGlobalItem();
    },
    handleSelect: function () {
      this.select = !this.select;
    },
    setGlobalCategory: function (id) {
      if (parseInt(id) !== this.category_id) {
        this.category_id = parseInt(id);
        this.sendSearch();
        if (id != 0) this.searchTypeByCategory(id);
        else this.$store.commit("setChildTypes", []);
      }
    },
    getChildren: function (parent_id) {
      return this.childCategories.filter(
        (child) => child.parent_id === parent_id
      );
    },
    setTo: function ($event) {
      this.$store.commit("handleTo", $event.target.value);
    },
    setFrom: function ($event) {
      this.$store.commit("handleFrom", $event.target.value);
    },
  },
  computed: {
    ...mapGetters([
      "regions",
      "currentRegion",
      "region_id",
      "parent_id",
      "getMainRegions",
      "getMainParent",
      "getChildRegions",
      "childCategories",
      "categories",
      "childTypes",
      "adverts",
      "advertLoading",
      "keyword",
      "getFrom",
      "getTo",
      "typesChildren",
      "chosenTypes",
      "lastPage",
      "token",
    ]),
    getChosenCategory: function () {
      const categoryId = this.user ? parseInt(this.user.category_id) : null;
      if (categoryId) {
        const findCat =
          [...this.childCategories, ...this.categories].find(
            (cat) => cat.id == categoryId
          ) || null;
        if (findCat) {
          if (this.$store.getters.currentLang == 1) return findCat.name_ru;
          if (this.$store.getters.currentLang == 2) return findCat.name_uz;
          if (this.$store.getters.currentLang == 3) return findCat.name_kr;
        }
      }
      return "";
    },
    getBreadCrumbItems: function () {
      const getId = this.category_id;
      let parents = [];
      if (getId) {
        const mergedCategory = [...this.childCategories, ...this.categories];
        let allParents = [];
        let parent = mergedCategory.find((child) => child.id == getId);
        let count = 0;
        while (parent) {
          count++;
          allParents.push(parent);
          parent = mergedCategory.find(
            (category) => parseInt(category.id) === parseInt(parent.parent_id)
          );
          parents = allParents;
        }
      }
      return parents.reverse();
    },
    getChosenChildren: function () {
      const breadcrumb = this.getBreadCrumbItems;
      const getChosen = breadcrumb[breadcrumb.length - 1];
      if (!getChosen) return this.categories;
      const getChosenChildren = this.childCategories.filter(
        (child) => parseInt(child.parent_id) === getChosen.id
      );
      return getChosenChildren;
    },
  },
};
</script>