<template>
  <div class="enter">
    <form class="enter-modal" @submit.prevent="submit">
      <div class="enter-modal_logo">
        <img src="../assets/images/logo1.svg" alt />
      </div>
      <div class="enter-modal_code" style="justify-content:center;">
        <TheMask
          type="text"
          :mask="'##-##'"
          required="required"
          v-model="verify_code"
          :id="'verify_code'"
          :placeholder="$store.getters.strings[$store.getters.currentLang].verify_code"
          :masked="false"
          :class="!valid ? 'fail' : ''"
        />
      </div>
      <div class="enter-modal_text">
        <span>СМС на +998 {{phone}}</span>
        <br />
        <!-- <router-link to="#">{{$store.getters.strings[$store.getters.currentLang].again}}</router-link> -->
      </div>
      <div class="enter-modal_button">
        <button>{{$store.getters.strings[$store.getters.currentLang].login}}</button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { TheMask } from "vue-the-mask";
import Loading from "./Loading.vue";
export default {
  components: { TheMask, Loading },
  data() {
    return {
      valid: false,
      verify_code: "",
      loading: false,
    };
  },
  watch: {
    verify_code(value) {
      this.valid = value.length === 4;
    },
  },
  methods: {
    submit: async function () {
      if (this.valid) {
        this.loading = true;
        this.authorize({
          phone: this.phone,
          verify_code: this.verify_code,
        })
          .then((value) => {
            if (value) {
              if (!parseInt(value.is_business)) {
                this.$router.push("/profile");
              } else {
                this.$router.push("/profile/business");
              }
            } else {
              this.$router.push("/choose/type");
            }
          })
          .catch((e) => {
            this.verify_code = "";
            this.loading = false;
          });
      } else {
        document.querySelector("#verify_code").focus();
      }
    },
    ...mapActions(["authorize"]),
  },
  computed: {
    ...mapGetters(["phone", "business", "user"]),
  },
};
</script>