export default {
  state: {
    childTypes: [],
    typesChildren: [],
    adverts: [],
    chosenTypes: [],
    advertLoading: false,
    keyword: "",
    from: "",
    to: "",
    lastPage: 1,
  },
  actions: {
    searchTypeByCategory({ commit }, value) {
      fetch(`https://admin.topin.uz/api/category/${value}/types`)
        .then((res) => res.json())
        .then((val) => {
          commit("setChildTypes", val.data);
        });
    },
    getMainAdverts({ commit }) {
      commit("setAdvertLoading", true);
      fetch(`https://admin.topin.uz/api/main`)
        .then((res) => res.json())
        .then((val) => {
          commit("setAdverts", val.data);
          commit("setAdvertLoading", false);
        })
        .catch((e) => commit("setAdverts", []));
    },
    searchAdvert({ commit }, value) {
      commit("setAdvertLoading", true);
      const body = value;
      fetch(`https://admin.topin.uz/api/search?` + new URLSearchParams(body))
        .then((res) => res.json())
        .then((val) => {
          commit("setAdverts", val.data);
          commit("setLastPage", parseInt(val.last_page));
        })
        .catch((e) => commit("setAdverts", []));
    },

    async onSelect({ commit, dispatch, getters }, data) {
      const { value, type } = data;
      if (value > 0) {
        dispatch("getChildTypes", value);
        let newData = [{ id: type.id, value: value }];
        const toRemove = await dispatch("getAllChildren", type);
        const merged = await dispatch("mergeDataById", [
          ...newData,
          ...getters.chosenTypes.filter((el) => !toRemove.includes(el.id)),
        ]);
        commit("setChosenTypes", merged);
      } else {
        const toRemove = await dispatch("getAllChildren", type);
        const merged = getters.chosenTypes
          .filter((choice) => choice.id !== type.id)
          .filter((el) => !toRemove.includes(el.id));
        commit("setChosenTypes", merged);
      }
    },
    async getChildTypes({ commit, dispatch, getters }, id) {
      const response = await fetch(
        `https://admin.topin.uz/api/types/children/${id}`
      );
      const value = await response.json();
      const merged = await dispatch("mergeDataById", [
        ...getters.typesChildren,
        ...value.data,
      ]);
      return commit("setTypesChildren", merged);
    },
    getAllChildren({ getters }, data) {
      let childrenIds = [];
      let pusher = data.children.map((child) => child.id);
      while (pusher.length) {
        childrenIds.push(...pusher);
        const children = [];
        getters.typesChildren.forEach((child) => {
          pusher.forEach((item) => {
            if (parseInt(item) === parseInt(child.parent_id))
              children.push(child.id);
          });
        });
        pusher = children;
      }
      return childrenIds;
    },
    mergeDataById: function ({ commit }, data) {
      return data.filter(function (a) {
        if (!this[a.id]) {
          this[a.id] = true;
          return true;
        }
      }, Object.create(null));
    },
  },
  mutations: {
    setChildTypes(state, data) {
      state.childTypes = data;
    },
    setLastPage(state, data) {
      state.lastPage = data;
    },
    setTypesChildren(state, data) {
      state.typesChildren = data;
    },
    setChosenTypes(state, data) {
      state.chosenTypes = data;
    },
    setAdverts(state, data) {
      state.adverts = data;
      state.advertLoading = false;
    },
    setAdvertLoading(state, data) {
      state.advertLoading = data;
    },
    setKeyword(state, data) {
      state.keyword = data;
    },
    handleFrom(state, data) {
      state.from = data;
    },
    handleTo(state, data) {
      state.to = data;
    },
  },
  getters: {
    childTypes(state) {
      return state.childTypes;
    },
    adverts(state) {
      return state.adverts;
    },
    advertLoading(state) {
      return state.advertLoading;
    },
    keyword(state) {
      return state.keyword;
    },
    getFrom(state) {
      return state.from;
    },
    getTo(state) {
      return state.to;
    },
    typesChildren(state) {
      return state.typesChildren;
    },
    chosenTypes(state) {
      return state.chosenTypes;
    },
    lastPage(state) {
      return state.lastPage;
    },
  },
};
