export default {
  state: {
    advert: {},
    advertLoad: true,
    advertUpload: false,
  },
  actions: {
    getAdvert({ commit, getters }, value) {
      fetch("https://admin.topin.uz/api/advert/" + value, {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
      })
        .then((res) => res.json())
        .then((val) => {
          commit("setAdvert", val);
        })
        .catch((e) => commit("setAdvert", {}));
    },
    async promoteAdvert({ dispatch, getters }, value) {
      const { promo_id, advert_id } = value;
      const response = await fetch(
        "https://admin.topin.uz/api/promote/advert/" + parseInt(advert_id),
        {
          method: "PUT",
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getters.token}`,
          },
          body: JSON.stringify({ promo_id: promo_id }),
        }
      );
      return response.status === 200;
    },
    getOwnAdvert({ commit, getters }, value) {
      return fetch("https://admin.topin.uz/api/my/adverts/" + value, {
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
      })
        .then((res) => res.json())
        .then((val) => {
          commit("setAdvert", val.data);
          commit("setLastPage", parseInt(val.last_page));
          return val.data;
        })
        .catch((e) => commit("setAdvert", {}));
    },
    async removeAdvertImage({ getters }, value) {
      const { id, file_name } = value;
      const res = await fetch(
        "https://admin.topin.uz/api/remove/image/advert/" +
          id +
          "?" +
          new URLSearchParams({ file_name: file_name }),
        {
          headers: {
            Authorization: `Bearer ${getters.token}`,
          },
        }
      );
      return res.status === 200;
    },

    async addAdvert({ commit, getters }, body) {
      commit("setAdvertUpload", true);
      const {
        advert_type,
        type_id,
        category_id,
        region_id,
        name,
        description,
        price,
        phone,
        files,
        lat,
        long,
        address_info,
      } = body;
      const data = new FormData();
      data.append("category_id", category_id);
      data.append("region_id", region_id);
      data.append("name", name);
      data.append("description", description);
      data.append("price", price);
      data.append("phone", phone);
      data.append("lat", lat);
      data.append("long", long);
      data.append("address_info", address_info);

      if (files.length) {
        Array.from(files).forEach((f) => {
          data.append("images[]", f);
        });
      }
      type_id.forEach((item) => data.append("type_id[]", item));
      advert_type.forEach((item) => data.append("advert_type[]", item));
      const res = await fetch("https://admin.topin.uz/api/add/advert", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
        body: data,
      });
      commit("setAdvertUpload", false);
      return res.status === 200;
    },
    async updateAdvert({ commit, getters }, body) {
      commit("setAdvertUpload", true);
      const {
        advert_type,
        type_id,
        category_id,
        region_id,
        name,
        description,
        price,
        files,
        lat,
        long,
        address_info,
        id,
      } = body;
      const data = new FormData();
      data.append("category_id", category_id);
      data.append("region_id", region_id);
      data.append("name", name);
      data.append("description", description);
      data.append("price", price);
      data.append("lat", lat);
      data.append("long", long);
      data.append("address_info", address_info);
      data.append("_method", "PUT");
      Array.from(files).forEach((f) => {
        data.append("images[]", f);
      });
      type_id.forEach((item) => data.append("type_id[]", item));
      advert_type.forEach((item) => data.append("advert_type[]", item));
      const res = await fetch(
        "https://admin.topin.uz/api/update/advert/" + id,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${getters.token}`,
          },
          body: data,
        }
      );
      commit("setAdvertUpload", false);
      return res.status === 200;
    },
  },
  mutations: {
    setAdvert(state, data) {
      state.advert = data;
      state.advertLoad = false;
    },
    setAdvertLoad(state, data) {
      state.advertLoad = data;
    },
    setAdvertUpload(state, data) {
      state.advertUpload = data;
    },
  },
  getters: {
    advert(state) {
      return state.advert;
    },
    advertLoad(state) {
      return state.advertLoad;
    },
    advertUpload(state) {
      return state.advertUpload;
    },
  },
};
