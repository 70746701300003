<template>
  <div class="profile">
    <div class="container">
      <div class="profile-wrap">
        <div class="profile-left">
          <UserSideBar />
        </div>
        <div class="profile-right_block">
          <div class="profile-right_block_header">
            <div class="title">{{ $store.getters.strings[$store.getters.currentLang].messages }}</div>
          </div>
          <div class="profile-right_block_body">
            <div class="sort">
              <div class="sort-date" v-show="false">
                <div class="sort-date_wrap">
                  <span class="sp-sort">
                    {{
                    $store.getters.strings[$store.getters.currentLang].sort
                    }}:
                  </span>
                  <button>
                    <!-- @click="date = !date" -->
                    <span>По дате</span>
                    <div class="icon" :class="{ active: date === true }">
                      <img src="../assets/images/dropdown2.svg" alt />
                    </div>
                  </button>
                  <div class="regions" v-if="date">
                    <div
                      class="reg"
                      v-for="(sort, index) in sorts"
                      :key="index"
                      @click="date = false"
                    >
                      <span>{{ sort.name }}</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="wrap">
              <messBlock :messages="messages" />
              <div class="centered" v-if="!messages.length">
                <template v-if="!profileLoading">
                  <img src="../assets/images/no-messages.svg" alt />
                  <p>{{ $store.getters.strings[$store.getters.currentLang].notExist }}</p>
                </template>
                <img src="../assets/images/categoryLoader.gif" width="200" v-else alt />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSideBar from "@/components/UserSideBar";
import messBlock from "@/components/MessBlock";
import { mapGetters, mapActions } from "vuex";
export default {
  components: { UserSideBar, messBlock },
  data() {
    return {
      date: false,
      sorts: [
        {
          name: "Активные",
        },
        {
          name: "Активные",
        },
        {
          name: "Активные",
        },
      ],
    };
  },
  mounted() {
    this.getMessages();
  },
  computed: {
    ...mapGetters(["messages","profileLoading"]),
  },
  methods: mapActions(["getMessages"]),
};
</script>
