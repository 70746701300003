import strings from "@/lang";
export default {
  state: {
    language: [
      { id: 1, name: "RU" },
      { id: 2, name: "UZ" },
      { id: 3, name: "УЗ" },
    ],
    currentLang: JSON.parse(localStorage.getItem("currentLang")) || 1,
    strings: strings,
  },
  actions: {
    changeLanguage({ commit }, id) {
      commit("setLanguage", id);
    },
  },
  mutations: {
    setLanguage(state, data) {
      state.currentLang = data;
      localStorage.setItem("currentLang", JSON.stringify(data));
    },
  },
  getters: {
    language(state) {
      return state.language;
    },
    currentLang(state) {
      return state.currentLang;
    },
    strings(state) {
      return state.strings;
    },
  },
};
