<template>
  <div>
    <router-link
      :to="'/messages/chat/'+mess.advert_id"
      class="mess-block"
      v-for="(mess, index) in messages"
      :key="index"
    >
      <div class="mess-block_left">
        <div class="mess-img"></div>
        <div class="content">
          <span class="id-title">{{ mess.advert.name }}</span>
          <span
            class="id-descr1"
          >{{ mess.advert.breadcrumb.map(bread => bread.name_ru).join(' / ') }}</span>
          <span class="id-descr2">{{ mess.body }}</span>
        </div>
      </div>
      <div class="mess-block_right">
        <span class="day">{{ mess.created_at.slice(0, 10) }}</span>
        <div class="notification" v-if="mess.notification">{{ mess.notification }}</div>
      </div>
    </router-link>
  </div>
</template>

<script>
export default {
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
};
</script>