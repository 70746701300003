export default {
  state: {
    regions: [],
    currentRegion: 0,
    region_id: 0,
    parent_id: 0,
  },
  actions: {
    regionHandler(ctx) {
      fetch("https://admin.topin.uz/api/regions")
        .then((res) => res.json())
        .then((val) => {
          ctx.commit("updateRegion", val.data);
        });
    },
    handleChooseRegion({ commit }, id) {
      commit("updateChooseRegion", id);
    },
    handleChooseParent({ commit }, region) {
      commit("updateChooseParent", region);
    },
    setGlobalItem({ commit }) {
      commit("setGlobal");
    },
  },
  mutations: {
    updateRegion(state, regions) {
      state.regions = regions;
    },
    updateChooseParent(state, region) {
      state.region_id = state.region_id === region.id ? 0 : region.id;
    },
    setGlobal(state) {
      state.region_id = 0;
      state.currentRegion = 0;
    },
    updateChooseRegion(state, id) {
      let findRegion = state.regions.find((reg) => reg.id === parseInt(id));
      if (findRegion) {
        state.currentRegion = findRegion.name_ru;
        state.region_id = findRegion.id;
      }
    },
  },
  getters: {
    regions(state) {
      return state.regions;
    },
    currentRegion(state) {
      return state.currentRegion;
    },
    region_id(state) {
      return state.region_id;
    },
    parent_id(state) {
      return state.parent_id;
    },
    getMainRegions(state) {
      return state.regions.filter((region) => region.parent_id == null);
    },
    getMainParent(state) {
      return state.regions.find((region) => {
        state.region_id == region.id;
      });
    },
    getChildRegions(state) {
      return state.regions.filter(
        (region) => state.region_id == region.parent_id
      );
    },
  },
};
