export default {
  state: {
    promos: [],
    promoLoading: true,
  },
  actions: {
    getPromos({ commit, getters }) {
      fetch("https://admin.topin.uz/api/promos", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
      })
        .then((res) => res.json())
        .then((val) => commit("setPromos", val.data))
        .catch((e) => commit("setPromos", []));
    },
  },
  mutations: {
    setPromos(state, data) {
      state.promos = data;
      state.promoLoading = false;
    },
  },
  getters: {
    promos(state) {
      return state.promos;
    },
    promoLoading(state) {
      return state.promoLoading;
    },
  },
};
