<template>
  <aside class="sidebar">
    <div class="sidebar-header">
      <div class="about">
        <div class="about-left">
          <div class="left-title">{{ $store.getters.strings[$store.getters.currentLang].balance }}:</div>
          <div
            class="left-price"
          >{{$store.getters.user.balance ? $store.getters.user.balance.amount : 0}} UZS</div>
        </div>
        <div class="about-right">
          <router-link to="/replenishment">
            {{
            $store.getters.strings[$store.getters.currentLang].addMoney
            }}
          </router-link>
        </div>
      </div>
      <div class="setting">
        <router-link
          to="/settings"
          :class="{
            'router-link-active':
              $route.fullPath === '/' || $route.fullPath === '/a',
          }"
        >
          <span>
            {{
            $store.getters.strings[$store.getters.currentLang].settings
            }}
          </span>
        </router-link>
      </div>
    </div>
    <div class="sidebar-body">
      <div class="menu">
        <router-link
          :to="!parseInt($store.getters.user.is_business) ?'/profile' : '/profile/business'"
          class="menu-button"
          :class="{
            'router-link-active':
              $route.fullPath === '/' || $route.fullPath === '/a',
          }"
        >
          <div class="menu-button-wrap d-flex">
            <img src="../assets/images/profile.svg" width="24" height="24" alt />
            <span>
              {{
              $store.getters.strings[$store.getters.currentLang].profile
              }}
            </span>
          </div>
        </router-link>
        <router-link to="/ads" class="menu-button">
          <div class="menu-button-wrap d-flex">
            <img src="../assets/images/advert.svg" alt />
            <span>
              {{
              $store.getters.strings[$store.getters.currentLang].adverts
              }}
            </span>
          </div>
        </router-link>
        <router-link to="/operations" class="menu-button">
          <div class="menu-button-wrap d-flex">
            <img src="../assets/images/operation.svg" alt />
            <span>
              {{
              $store.getters.strings[$store.getters.currentLang].operations
              }}
            </span>
          </div>
        </router-link>
        <router-link to="/messages" class="menu-button">
          <div class="menu-button-wrap d-flex">
            <img src="../assets/images/messages.svg" alt />
            <span>
              {{
              $store.getters.strings[$store.getters.currentLang].messages
              }}
            </span>
          </div>
          <!-- <div class="notif">
            <div class="notific">2</div>
          </div>-->
        </router-link>
        <router-link to="/favorite" class="menu-button">
          <div class="menu-button-wrap d-flex">
            <img src="../assets/images/favorite.svg" alt />
            <span>
              {{
              $store.getters.strings[$store.getters.currentLang].favorites
              }}
            </span>
          </div>
        </router-link>
      </div>
    </div>
  </aside>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["currentLang"]),
  },
};
</script>
