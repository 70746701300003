import Vue from "vue";
import Vuex from "vuex";
import category from "./modules/category";
import search from "./modules/search";
import region from "./modules/region";
import slider from "./modules/slider";
import login from "./modules/login";
import advert from "./modules/advert";
import profile from "./modules/profile";
import language from "./modules/language";
import promo from "./modules/promo";
import payment from "./modules/payment";

Vue.use(Vuex);
export default new Vuex.Store({
  modules: {
    category,
    region,
    slider,
    login,
    search,
    advert,
    profile,
    language,
    payment,
    promo,
  },
});
