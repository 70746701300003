<template>
  <div class="pagination container mob">
    <div class="pagination-buttons">
      <button class="arrows" v-if="currentPage != 1" @click="setPage(currentPage-1)">
        <img src="../assets/images/btn-prev.svg" alt />
        <span>Пред.</span>
      </button>
      <template v-for="(pageNumber, index) in lastPage">
        <!-- <span
          class="dots"
          :key="index+'dot'"
          v-if="lastPage - 1 == index && currentPage -1 != index"
        >...</span>-->
        <button
          :key="index"
          v-if="
            Math.abs(pageNumber - currentPage) < 4 ||
              pageNumber === lastPage ||
              pageNumber == 1
          "
          :class="`numbers${currentPage == pageNumber ? ' active' : ''}`"
          @click="setPage(pageNumber)"
        >{{ pageNumber }}</button>
        <!-- <span class="dots" :key="index+'dots'" v-if="index == 0">...</span> -->
      </template>
      <button class="arrows" v-if="currentPage != lastPage" @click="setPage(currentPage+1)">
        <span>След.</span>
        <img src="../assets/images/btn-next.svg" alt />
      </button>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    setter: {
      type: Function,
      required: true,
    },
    lastPage: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      currentPage: 1,
    };
  },
  methods: {
    setPage: function (pageNumber) {
      if (this.currentPage != pageNumber) {
        this.currentPage = pageNumber;
        this.setter(parseInt(pageNumber));
      }
    },
  },
};
</script>
