<template>
  <div class="blog-wrapp">
    <label>{{data.name_ru}}</label>
    <div class="blog-full">
      <input
        required="required"
        name="advert_type[]"
        type="number"
        class="advert_type"
        :id="'advert_'+data.id"
        placeholder="Введите данные"
      />
    </div>
    <input type="hidden" name="type_id[]" class="type_id" :value="data.id" />
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
};
</script>