<template>
  <nav class="nav">
    <div class="container">
      <div class="nav-header">
        <div class="nav-header_left">
          <div class="nav-header_left_logo">
            <router-link to="/">
              <img src="../assets/images/logo.svg" alt />
            </router-link>
          </div>
          <form
            autocomplete="off"
            class="nav-header_left_search"
            v-if="$route.name !== 'addProduct'"
          >
            <div class="search">
              <input
                id="search"
                type="text"
                @input="setKeyword($event)"
                :value="keyword"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang].keyword
                "
              />
            </div>
            <div class="region">
              <div class="region-select" @click="handleSelect">
                <div class="wrap d-flex">
                  <img src="../assets/images/pin-select.svg" alt />
                  <span>
                    {{
                    !this.currentRegion
                    ? $store.getters.strings[$store.getters.currentLang]
                    .uzbekistan
                    : this.currentRegion
                    }}
                  </span>
                </div>
                <div class="icon d-flex" :class="{ active: select === true }">
                  <img src="../assets/images/dropdown2.svg" alt />
                </div>
              </div>
              <div class="regions" v-if="select">
                <div class="regions-title" @click="updateChooseRegion(0)">
                  <span>
                    {{
                    $store.getters.strings[$store.getters.currentLang].every
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right-dark.svg" alt />
                  </div>
                </div>
                <div
                  class="reg"
                  v-for="region in getMainRegions"
                  :key="region.id"
                  @click="updateChooseParent(region)"
                >
                  <span v-if="$store.getters.currentLang == 1">
                    {{
                    region.name_ru
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 2">
                    {{
                    region.name_uz
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 3">
                    {{
                    region.name_kr
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right.svg" alt />
                    <img src="../assets/images/drop-right-hover.svg" alt />
                  </div>
                </div>
              </div>
              <div class="region-city" v-if="region_id && isOpen === true && select">
                <div class="region-city_title">
                  <span @click="updateChooseRegion(getChildRegions[0].parent_id)">
                    {{
                    $store.getters.strings[$store.getters.currentLang]
                    .everyChild
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right-dark.svg" alt />
                  </div>
                </div>
                <div
                  class="region-city_tash"
                  v-for="(reg, index) in getChildRegions"
                  :key="index"
                  @click="updateChooseRegion(reg.id)"
                >
                  <span v-if="$store.getters.currentLang == 1">
                    {{
                    reg.name_ru
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 2">
                    {{
                    reg.name_uz
                    }}
                  </span>
                  <span v-else-if="$store.getters.currentLang == 3">
                    {{
                    reg.name_kr
                    }}
                  </span>
                  <div class="icon">
                    <img src="../assets/images/drop-right.svg" alt />
                    <img src="../assets/images/drop-right-hover.svg" alt />
                  </div>
                </div>
              </div>
            </div>
            <router-link to="/search/0">
              <button>
                <img src="../assets/images/search.svg" alt />
              </button>
            </router-link>
          </form>
        </div>
        <div class="nav-header_right">
          <div class="small-search_button">
            <router-link to="/search/0">
              <button>
                <img src="../assets/images/search.svg" alt />
              </button>
            </router-link>
          </div>
          <div
            class="nav-header_right_add"
            v-show="
              $route.name !== 'entering1' &&
                $route.name !== 'entering2' &&
                $route.name !== 'entering3' &&
                $route.name !== 'registration' &&
                $route.name !== 'Cart'
            "
          >
            <router-link v-bind:to="registered ? '/addProduct' : '/login'">
              <button>
                <img src="../assets/images/plus.svg" alt />
                <span>
                  {{
                  $store.getters.strings[$store.getters.currentLang].add
                  }}
                </span>
              </button>
            </router-link>
          </div>
          <div
            :class="
              `nav-header_right_language ` + { active: showLanguage === true }
            "
          >
            <div class="lang" @click="handleLanguage(-1)">{{ getCurrentLanguage }}</div>
            <div class="language" v-if="showLanguage">
              <div
                v-for="(lang, key) in language"
                :class="
                  'lang-inner ' +
                    ($store.getters.currentLang === lang.id ? 'active' : '')
                "
                @click="handleLanguage(lang.id)"
                :key="key"
              >{{ lang.name }}</div>
            </div>
          </div>
          <div class="nav-header_right_profile">
            <router-link
              v-bind:to="registered ? (!parseInt($store.getters.user.is_business) ?'/profile' : '/profile/business') : '/login'"
            >
              <button>
                <img src="../assets/images/profile.svg" alt />
                <span>
                  {{
                  $store.getters.strings[$store.getters.currentLang].profile
                  }}
                </span>
              </button>
            </router-link>
          </div>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      showLanguage: false,
      select: false,
      isOpen: false,
    };
  },
  methods: {
    handleLanguage: function (id) {
      if (!this.showLanguage) {
        this.showLanguage = true;
      }
      if (id >= 0) {
        if (id !== this.$store.getters.currentLang) {
          this.changeLanguage(id);
        }
        this.showLanguage = false;
      }
    },
    setKeyword: function ($event) {
      this.$store.commit("setKeyword", $event.target.value);
    },
    ...mapActions([
      "regionHandler",
      "handleChooseRegion",
      "handleChooseParent",
      "setGlobalItem",
      "changeLanguage",
    ]),

    updateChooseParent: function (region) {
      this.isOpen = true;
      this.select = true;
      this.handleChooseParent(region);
    },
    updateChooseRegion: function (id) {
      this.isOpen = false;
      this.select = false;
      if (parseInt(id)) this.handleChooseRegion(id);
      else this.setGlobalItem();
    },
    handleSelect: function () {
      this.select = !this.select;
    },
  },

  computed: {
    ...mapGetters([
      "regions",
      "currentRegion",
      "region_id",
      "parent_id",
      "getMainRegions",
      "getMainParent",
      "getChildRegions",
      "registered",
      "keyword",
      "language",
    ]),
    getCurrentLanguage: function () {
      return this.language.filter(
        (lang) => lang.id == this.$store.getters.currentLang
      )[0].name;
    },
  },
  mounted() {
    this.regionHandler();
  },
};
</script>
