<template>
  <div class="profile">
    <div class="container">
      <div class="profile-wrap">
        <div class="profile-left">
          <UserSideBar />
        </div>
        <div class="profile-right_block">
          <div class="enter-registration_header">
            <span>
              {{
              $store.getters.strings[$store.getters.currentLang].profile
              }}
            </span>
            <span>
              {{
              $store.getters.strings[$store.getters.currentLang].simple
              }}
            </span>
          </div>
          <div class="enter-registration_body">
            <div class="enter-registration_body_data">
              <div class="title">
                {{
                $store.getters.strings[$store.getters.currentLang].personal
                }}
              </div>
              <div class="content">
                <div class="big">
                  <div class="block">
                    <label>
                      {{
                      $store.getters.strings[$store.getters.currentLang].fio
                      }}:
                    </label>
                    <input
                      type="text"
                      :value="user.name"
                      required="required"
                      name="name"
                      class="name"
                      disabled="disabled"
                    />
                  </div>
                  <div class="block">
                    <label>
                      {{
                      $store.getters.strings[$store.getters.currentLang]
                      .birthday
                      }}:
                    </label>
                    <input
                      type="date"
                      v-bind:value="user.birthday"
                      disabled="disabled"
                      name="birthday"
                      class="birthdate"
                      required="required"
                    />
                  </div>
                  <div class="block-2">
                    <div class="column">
                      <div class="blog-wrapp">
                        <label>
                          {{
                          $store.getters.strings[$store.getters.currentLang]
                          .sex
                          }}:
                        </label>
                        <div class="blog">
                          <span>
                            {{
                            user.sex === "male"
                            ? $store.getters.strings[
                            $store.getters.currentLang
                            ].male
                            : $store.getters.strings[
                            $store.getters.currentLang
                            ].female
                            }}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="wrap">
              <div class="enter-registration_body_location">
                <div class="title">
                  {{
                  $store.getters.strings[$store.getters.currentLang].location
                  }}
                </div>
                <div class="content">
                  <div class="country">
                    <label>
                      {{
                      $store.getters.strings[$store.getters.currentLang]
                      .region
                      }}:
                    </label>
                    <div class="uzb">
                      <span>{{ user.country }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="empty"></div>
            </div>

            <div class="wrap">
              <div class="enter-registration_body_contacts">
                <div class="title">
                  {{
                  $store.getters.strings[$store.getters.currentLang]
                  .contactInfo
                  }}
                </div>
                <div class="content">
                  <div class="block">
                    <label>
                      {{
                      $store.getters.strings[$store.getters.currentLang]
                      .email
                      }}:
                    </label>
                    <input class="mail" disabled="disabled" :value="user.email" />
                  </div>
                  <div class="block">
                    <label>
                      {{
                      $store.getters.strings[$store.getters.currentLang]
                      .phone
                      }}:
                    </label>
                    <input class="mail" disabled="disabled" :value="user.phone" />
                  </div>
                  <div class="block"></div>
                </div>
                <div class="save">
                  <div class="empty2"></div>
                  <!-- <button>{{ $store.getters.strings[$store.getters.currentLang].save }}</button> -->
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSideBar from "@/components/UserSideBar";
import SelectMenu from "@/components/SelectMenu";
import { mapGetters } from "vuex";

export default {
  components: { UserSideBar, SelectMenu },
  computed: {
    ...mapGetters(["user", "phone", "currentLang"]),
  },
  mounted() {},
};
</script>
