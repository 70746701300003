<template>
  <div class="profile">
    <div class="container">
      <div class="profile-wrap">
        <div class="profile-left">
          <UserSideBar />
        </div>
        <div class="profile-right_block">
          <div class="profile-right_block_header">
            <div class="title">
              {{ $store.getters.strings[$store.getters.currentLang].adverts }}
            </div>
          </div>
          <div class="profile-right_block_body">
            <div class="sort">
              <div class="sort-button">
                <button @click="sort = !sort">
                  <span v-if="$store.getters.currentLang == 1">{{
                    getSelected.name_ru
                  }}</span>
                  <span v-else-if="$store.getters.currentLang == 2">{{
                    getSelected.name_uz
                  }}</span>
                  <span v-else-if="$store.getters.currentLang == 3">{{
                    getSelected.name_kr
                  }}</span>
                  <div class="icon" :class="{ active: sort === true }">
                    <img src="../assets/images/dropdown2-white.svg" alt />
                  </div>
                </button>
                <div class="regions" v-if="sort">
                  <div
                    class="reg"
                    v-for="(sort, index) in sorts"
                    :key="index"
                    @click="handleSelect(sort.id)"
                  >
                    <span v-if="$store.getters.currentLang == 1">{{
                      sort.name_ru
                    }}</span>
                    <span v-else-if="$store.getters.currentLang == 2">{{
                      sort.name_uz
                    }}</span>
                    <span v-else-if="$store.getters.currentLang == 3">{{
                      sort.name_kr
                    }}</span>
                  </div>
                </div>
              </div>
              <div class="sort-button-drop"></div>
            </div>
            <div class="wrap">
              <template v-if="myAdverts.length > 0 && !profileLoading">
                <ProfProduct :selected="selected" :data="myAdverts" />
              </template>
              <div class="centered">
                <img
                  src="../assets/images/categoryLoader.gif"
                  width="200"
                  v-if="profileLoading"
                  alt
                />
                <template v-if="!myAdverts.length && !profileLoading">
                  <img src="../assets/images/no-advert.svg" alt />
                  <p>
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .notExist
                    }}
                  </p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSideBar from "@/components/UserSideBar";
import ProfProduct from "@/components/ProfProduct";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { UserSideBar, ProfProduct },
  data() {
    return {
      sort: false,
      selected: 1,
      sorts: [
        {
          id: 1,
          name_ru: "Активные",
          name_uz: "Aktiv",
          name_kr: "Актив",
        },
        {
          id: 2,
          name_ru: "В модерации",
          name_uz: "Moderatsiyada",
          name_kr: "Модерацияда",
        },
        {
          id: 3,
          name_ru: "Не активные",
          name_uz: "Aktiv emas",
          name_kr: "Актив эмас",
        },
      ],
    };
  },
  mounted() {
    this.getMyAdverts(this.selected);
  },
  computed: {
    ...mapGetters(["myAdverts", "currentLang", "profileLoading"]),
    getSelected: function () {
      return this.sorts.find((sort) => this.selected == sort.id);
    },
  },
  methods: {
    handleSelect: function (id) {
      this.getMyAdverts(id);
      this.selected = parseInt(id);
      this.sort = false;
    },
    ...mapActions(["getMyAdverts"]),
  },
};
</script>
