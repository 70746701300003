<template>
  <div class="advert-loader">
    <div>
      <img src="../assets/images/not-found.gif" style="max-width:300px;" alt />
      <h3 style="text-align:center;">{{$store.getters.strings[$store.getters.currentLang].notFound}}</h3>
      <br />
      <br />
      <br />
      <br />
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
export default {
  computed: {
    ...mapGetters(["strings", "currentLang"]),
  },
};
</script>