<template>
  <div>
    <label v-if="$store.getters.currentLang == 1">{{ type.name_ru }}</label>
    <label v-else-if="$store.getters.currentLang == 2">{{
      type.name_uz
    }}</label>
    <label v-else-if="$store.getters.currentLang == 3">{{
      type.name_kr
    }}</label>
    <div class="choices">
      <div
        class="choice"
        :class="{ active: chosen === 0 }"
        @click="setChosen(0)"
      >
        <span>{{
          $store.getters.strings[$store.getters.currentLang].all
        }}</span>
      </div>
      <div
        class="choice"
        v-for="choice in data"
        :key="choice.id"
        :class="{ active: chosen === choice.id }"
        @click="setChosen(choice.id)"
      >
        <span v-if="$store.getters.currentLang == 1">{{ choice.name_ru }}</span>
        <span v-else-if="$store.getters.currentLang == 2">{{
          choice.name_uz
        }}</span>
        <span v-else-if="$store.getters.currentLang == 3">{{
          choice.name_kr
        }}</span>
      </div>
    </div>
    <input
      type="hidden"
      class="type_value"
      v-if="chosen > 0"
      :value="getValue"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    type: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      chosen: 0,
    };
  },
  methods: {
    setChosen: function(id) {
      if (this.chosen != id) {
        this.chosen = id;
        this.onSelect({ value: parseInt(id), type: this.type });
      }
    },
    ...mapActions(["onSelect"]),
  },
  computed: {
    getValue: function() {
      return JSON.stringify([this.data[0].parent_id * 1, [this.chosen * 1]]);
    },
    ...mapGetters(["strings"]),
  },
};
</script>
