<template>
  <div class="prewiew container" v-if="!advertLoad">
    <div class="prewiew-left">
      <div class="title-1">
        <span>{{ advert.name }}</span>
        <div class="price">
          <span>{{ advert.price }} UZS</span>
        </div>
      </div>
      <div class="price-mobile">
        <span
          >{{ $store.getters.strings[$store.getters.currentLang].price }}:</span
        >
        <span>{{ advert.price }} UZS</span>
      </div>
      <div class="content">
        <div class="left">
          <div class="big-image">
            <VueSlickCarousel :arrows="true" :dots="true">
              <a
                v-for="(image, key) in advert.image"
                data-fancybox="gallery"
                :href="image"
                :key="key"
              >
                <img :src="image" alt="image" />
              </a>
              <template #customPaging="page">
                <div class="custom-dot">
                  <img :src="advert.image[page]" alt="image" />
                </div>
              </template>
            </VueSlickCarousel>
          </div>
        </div>
        <div class="right">
          <div class="right-about">
            <div class="right-about_header">
              <div class="zag">
                {{
                  $store.getters.strings[$store.getters.currentLang].addDetails
                }}
              </div>
              <div class="favorite" v-if="registered">
                <button
                  :class="Boolean(advert.is_favorite) ? 'active' : ''"
                  @click="switchFavorite"
                >
                  <span>
                    {{
                      $store.getters.strings[$store.getters.currentLang]
                        .addFavorite
                    }}
                  </span>
                  <img src="../assets/images/favorite-item.svg" alt />
                </button>
              </div>
            </div>
            <div class="right-about_body">
              <div class="block">
                <div class="name">
                  {{ $store.getters.strings[$store.getters.currentLang].id }}:
                </div>
                <div class="line"></div>
                <span>№ {{ advert.id }}</span>
              </div>
              <div class="block">
                <div class="name">
                  {{
                    $store.getters.strings[$store.getters.currentLang]
                      .addedTime
                  }}:
                </div>
                <div class="line"></div>
                <span>{{ advert.created_at.slice(0, 10) }}</span>
              </div>
              <div
                class="block"
                v-for="info in advert.advert_type"
                :key="info.id"
              >
                <div class="name">
                  <template v-if="$store.getters.currentLang == 1"
                    >{{ info.name_ru }}:</template
                  >
                  <template v-if="$store.getters.currentLang == 2"
                    >{{ info.name_uz }}:</template
                  >
                  <template v-if="$store.getters.currentLang == 3"
                    >{{ info.name_kr }}:</template
                  >
                </div>
                <div class="line"></div>
                <span>{{ info.pivot.value }}</span>
              </div>
              <div class="text">
                <span>{{ advert.description }}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="aboutt">
        <div class="aboutt-header">
          <div class="left">
            <span>
              {{ $store.getters.strings[$store.getters.currentLang].onMap }}
            </span>
            <!-- <span>Подробная иформация</span> -->
          </div>
          <div class="right-content"></div>
        </div>
        <div class="aboutt-body" v-if="advert.lat">
          <!-- <strong style="margin-top: 10px">{{ advert.address_info }}</strong> -->
          <GmapMap
            :center="{ lat: Number(advert.lat), lng: Number(advert.long) }"
            :zoom="15"
            :options="{
              zoomControl: false,
              mapTypeControl: false,
              scaleControl: false,
              streetViewControl: false,
              rotateControl: false,
              fullscreenControl: false,
              disableDefaultUI: false,
              draggable: false,
              clickable: false,
            }"
            style="
              width: 100%;
              height: 400px;
              margin-top: 10px;
              border-radius: 20px;
              overflow: hidden;
            "
          >
            <GmapMarker
              :position="{ lat: Number(advert.lat), lng: Number(advert.long) }"
              :clickable="false"
              :draggable="false"
              :icon="markerOptions"
            />
          </GmapMap>
        </div>
        <!-- <div class="aboutt-body">
          <div class="fasad">
            <div class="fasad-header" @click="fasad = !fasad">
              <span>Фасад</span>
              <div class="icon" :class="{ active: fasad === true }">
                <img src="../assets/images/dropdown2.svg" alt />
              </div>
            </div>
            <div class="fasad-body" v-if="fasad">
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fasad">
            <div class="fasad-header" @click="fasad2 = !fasad2">
              <span>Фасад</span>
              <div class="icon" :class="{ active: fasad2 === true }">
                <img src="../assets/images/dropdown2.svg" alt />
              </div>
            </div>
            <div class="fasad-body" v-if="fasad2">
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fasad">
            <div class="fasad-header" @click="fasad3 = !fasad3">
              <span>Фасад</span>
              <div class="icon" :class="{ active: fasad3 === true }">
                <img src="../assets/images/dropdown2.svg" alt />
              </div>
            </div>
            <div class="fasad-body" v-if="fasad3">
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="fasad">
            <div class="fasad-header" @click="fasad4 = !fasad4">
              <span>Фасад</span>
              <div class="icon" :class="{ active: fasad4 === true }">
                <img src="../assets/images/dropdown2.svg" alt />
              </div>
            </div>
            <div class="fasad-body" v-if="fasad4">
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
              <div class="column">
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
                <div class="block">
                  <div class="text">
                    <div class="title1">АДСЛ</div>
                    <div class="subtitle">Сигнализация (Пожар)</div>
                  </div>
                  <div class="ok">
                    <img src="../assets/images/ok.svg" alt />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>-->
      </div>
      <div class="tablet">
        <div class="user-tablet">
          <div class="user-tablet_header">
            <div class="user-tablet_header_title">
              {{
                $store.getters.strings[$store.getters.currentLang].contactFace
              }}
            </div>
          </div>
          <div class="user-tablet_body">
            <div class="person">
              <div class="person-img">
                <img src="../assets/images/person.svg" alt />
              </div>
              <div class="person-name">{{ advert.user.name }}</div>
            </div>
            <div class="info">
              <div class="block">
                <div class="title">
                  {{
                    $store.getters.strings[$store.getters.currentLang].inSystem
                  }}:
                </div>
                <span>{{ advert.user.created_at.slice(0, 10) }}</span>
              </div>
              <div class="block">
                <div class="title">
                  {{
                    $store.getters.strings[$store.getters.currentLang]
                      .advertsCount
                  }}:
                </div>
                <span>{{ advert.user.advert_count }}</span>
              </div>
              <div class="block">
                <div class="title">
                  {{
                    $store.getters.strings[$store.getters.currentLang].accType
                  }}:
                </div>
                <span
                  :style="
                    parseInt(advert.user.is_business) ? 'color:#ff7800' : ''
                  "
                >
                  {{
                    !parseInt(advert.user.is_business)
                      ? $store.getters.strings[$store.getters.currentLang]
                          .simple
                      : $store.getters.strings[$store.getters.currentLang]
                          .bussinessAcc
                  }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="buttons">
          <a v-bind:href="'tel:' + advert.user.phone">
            <button class="call">
              <img src="../assets/images/call.svg" alt />
              <span>
                {{ $store.getters.strings[$store.getters.currentLang].call }}
              </span>
            </button>
          </a>
          <router-link
            v-if="advert.user_id != (!user ? 0 : user.id)"
            :to="'/messages/chat/' + advert.id"
          >
            <button class="write">
              <img src="../assets/images/message.svg" alt />
              <span>
                {{
                  $store.getters.strings[$store.getters.currentLang].writeMess
                }}
              </span>
            </button>
          </router-link>
          <button @click="showUser">
            <span>
              {{
                $store.getters.strings[$store.getters.currentLang].showProfile
              }}
            </span>
          </button>
        </div>
      </div>

      <div class="copy">
        <span>
          {{ $store.getters.strings[$store.getters.currentLang].sameAds }}
        </span>
      </div>
      <div class="copy-products">
        <div class="home-section_products like-products">
          <!-- <products :products="products" /> -->
        </div>
      </div>
    </div>
    <div class="prewiew-right">
      <div class="user">
        <div class="user-header">
          <div class="user-header_title">
            {{ $store.getters.strings[$store.getters.currentLang].contactFace }}
          </div>
        </div>
        <div class="user-body">
          <div class="person">
            <div class="person-img">
              <img src="../assets/images/person.svg" alt />
            </div>
            <div class="person-name">{{ advert.user.name }}</div>
          </div>
          <div class="info">
            <div class="block">
              <div class="title">
                {{
                  $store.getters.strings[$store.getters.currentLang].inSystem
                }}:
              </div>
              <span>{{ advert.user.created_at.slice(0, 10) }}</span>
            </div>
            <div class="block">
              <div class="title">
                {{
                  $store.getters.strings[$store.getters.currentLang]
                    .advertsCount
                }}:
              </div>
              <span>{{ advert.user.advert_count }}</span>
            </div>
            <div class="block">
              <div class="title">
                {{
                  $store.getters.strings[$store.getters.currentLang].accType
                }}:
              </div>
              <span
                :style="
                  parseInt(advert.user.is_business) ? 'color:#ff7800' : ''
                "
              >
                {{
                  !parseInt(advert.user.is_business)
                    ? $store.getters.strings[$store.getters.currentLang].simple
                    : $store.getters.strings[$store.getters.currentLang]
                        .bussinessAcc
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="button">
        <a v-bind:href="'tel:' + advert.user.phone">
          <button class="call">
            <img src="../assets/images/call.svg" alt />
            <span>
              {{ $store.getters.strings[$store.getters.currentLang].call }}
            </span>
          </button>
        </a>
        <router-link
          v-if="advert.user_id != (!user ? 0 : user.id)"
          :to="'/messages/chat/' + advert.id"
        >
          <button class="write">
            <img src="../assets/images/message.svg" alt />
            <span>
              {{ $store.getters.strings[$store.getters.currentLang].writeMess }}
            </span>
          </button>
        </router-link>
        <button @click="showUser">
          <span>
            {{ $store.getters.strings[$store.getters.currentLang].showProfile }}
          </span>
        </button>
      </div>
      <div class="reclam-image">
        <router-link to="#">
          <img src="../assets/images/001.png" alt />
        </router-link>
        <router-link to="#">
          <img src="../assets/images/002.png" alt id="img" />
        </router-link>
      </div>
    </div>
  </div>
  <div style="height: 80vh" v-else>
    <loading />
  </div>
</template>

<script>
import Vue from "vue";
import * as VueGoogleMaps from "vue2-google-maps";
import VueSlickCarousel from "vue-slick-carousel";
import { mapGetters, mapActions } from "vuex";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import Loading from "./Loading.vue";
import products from "@/components/Products.vue";

Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyDyu7WJw2mR_TJ3xK-wZDv_k9Luk9HPq6c",
    language: "ru",
  },
});
const mapMarker = require("@/assets/images/map-marker.svg");
export default {
  components: { products, VueSlickCarousel, Loading },
  created: function () {
    const routeId = parseInt(this.$route.params.id);
    this.getAdvert(routeId);
  },
  data() {
    return {
      markerOptions: {
        url: mapMarker,
        size: { width: 170, height: 50, f: "px", b: "px" },
        scaledSize: { width: 170, height: 50, f: "px", b: "px" },
        labelColor: "white",
      },
      center: { lat: 41.311081, lng: 69.240562 },
    };
  },
  methods: {
    ...mapActions(["getAdvert"]),
    showUser: function () {
      if (
        parseInt(this.advert.user.is_business) == 1 &&
        parseInt(this.advert.user.is_paid) == 1
      ) {
        this.$router.push({
          path: `/user/business/profile/${this.advert.user.name}/${this.advert.user.id}`,
        });
      } else {
        this.$router.push({
          path: `/user/${this.advert.user.id}`,
        });
      }
    },
    switchFavorite: function () {
      const body = {
        advert_id: this.advert.id,
      };
      if (Boolean(this.advert.is_favorite)) {
        this.patchEvent("/favorite/remove", this.token, body).then((value) => {
          if (value.statusCode == 200) {
            this.advert.is_favorite = false;
          }
        });
      } else {
        this.patchEvent("/favorite/set", this.token, body).then((value) => {
          if (value.statusCode == 200) {
            this.advert.is_favorite = true;
          }
        });
      }
    },
    patchEvent: async function (url, token = null, body) {
      let headers = {};
      if (token) {
        headers = {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        };
      }
      const res = await fetch("https://admin.topin.uz/api" + url, {
        method: "PATCH",
        headers: headers,
        body: JSON.stringify(body),
      });
      return res.json();
    },
  },
  computed: {
    ...mapGetters(["advert", "advertLoad", "user", "token", "registered"]),
  },
};
window.$ = window.jQuery = require("jquery");
const fancybox = require("@fancyapps/fancybox");
const fancyboxCSS = require("@fancyapps/fancybox/dist/jquery.fancybox.css");
$(document).ready(function () {
  $('[data-fancybox="gallery"]').fancybox({
    // Options will go here
  });
});
</script>
