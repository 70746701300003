<template>
  <div class="blog-wrapp">
    <label>{{ type.name_ru }}</label>
    <div class="multi-wrap">
      <div class="blog" @click="show ? (show = false) : (show = true)">
        <span>{{ getSelected }}</span>
        <div class="icon" :class="{ active: show === true }">
          <img src="../assets/images/dropdown2.svg" alt />
        </div>
        <input type="hidden" name="type_id[]" class="type_id" :value="type.id" />
        <input type="hidden" name="advert_type[]" class="advert_type" :value="chosen" />
      </div>
      <div class="regions" v-if="show">
        <div
          :class="'reg ' + (chosen === value.id ? 'active' : '')"
          v-for="(value, index) in data"
          :key="index"
          @click="handelSelect(value.id)"
        >
          <span>{{ value.name_ru }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    type: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      chosen: 0,
    };
  },
  methods: {
    handelSelect: function (id) {
      if (id != this.chosen) {
        const parser = parseInt(id);
        this.chosen = parser;
        this.onSelect({ value: parser, type: this.type });
      }
      this.show = false;
    },
    ...mapActions(["onSelect"]),
  },
  computed: {
    getSelected: function () {
      if (this.chosen == 0) {
        return this.$store.getters.strings[this.$store.getters.currentLang]
          .chooseList;
      }
      return this.data.find((data) => data.id == this.chosen).name_ru;
    },
    ...mapGetters(["strings"]),
  },
};
</script>
