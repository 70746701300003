<template>
  <div class="multi-wrap">
    <div class="form-region" @click="handleSelect">
      <div class="left">
        <img src="../assets/images/new.svg" alt />
        <div class="title">{{ getSelected }}</div>
      </div>
      <div class="icon d-flex" :class="{ active: select === true }">
        <img src="../assets/images/dropdown2.svg" alt />
      </div>
    </div>
    <div class="regions" v-if="select">
      <div
        v-for="parentItem in parent"
        :key="parentItem.id"
        v-bind:class="'reg ' + (parentItem.id === parent_id ? 'active' : '')"
        @click="updateChooseParent(parentItem)"
      >
        <span v-if="$store.getters.currentLang == 1">
          {{
          parentItem.name_ru
          }}
        </span>
        <span v-else-if="$store.getters.currentLang == 2">
          {{
          parentItem.name_uz
          }}
        </span>
        <span v-else-if="$store.getters.currentLang == 3">
          {{
          parentItem.name_kr
          }}
        </span>
        <div class="icon">
          <img src="../assets/images/drop-right.svg" alt />
          <img src="../assets/images/drop-right-hover.svg" alt />
        </div>
      </div>
    </div>
    <div class="region-city" v-if="parent_id && isOpen === true">
      <div class="region-city_title">
        <span @click="updateChooseItem(parent_id)">{{ getChosenParent }}</span>
        <div class="icon">
          <img src="../assets/images/drop-right-dark.svg" alt />
        </div>
      </div>
      <div
        class="region-city_tash"
        v-for="(reg, index) in getRelatedCategories"
        :key="index"
        @click="updateChooseItem(reg.id)"
      >
        <span v-if="$store.getters.currentLang == 1">{{ reg.name_ru }}</span>
        <span v-else-if="$store.getters.currentLang == 2">
          {{
          reg.name_uz
          }}
        </span>
        <span v-else-if="$store.getters.currentLang == 3">
          {{
          reg.name_kr
          }}
        </span>
        <div class="icon">
          <img src="../assets/images/drop-right.svg" alt />
          <img src="../assets/images/drop-right-hover.svg" alt />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    parent: {
      type: Array,
      required: true,
    },
    children: {
      type: Array,
      required: true,
    },
    setter: {
      type: Function,
      required: false,
    },
    chosen: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      selected: 0,
      select: false,
      chosen_id: 0,
      parent_id: 0,
    };
  },
  created() {
    if (this.chosen) this.chosen_id = parseInt(this.chosen);
  },
  watch: {
    chosen: function (newVal, oldVal) {
      this.chosen_id = newVal;
    },
  },
  methods: {
    handleSelect: function () {
      this.select = !this.select;
      this.isOpen = false;
    },
    updateChooseParent: function (parent) {
      this.isOpen = true;
      this.select = true;
      this.parent_id = this.parent_id === parent.id ? 0 : parent.id;
    },
    updateChooseItem: function (id) {
      this.isOpen = false;
      this.select = false;
      this.setter(id);
      let findChild = [...this.parent, ...this.children].find(
        (item) => item.id === parseInt(id)
      );
      if (findChild) {
        this.chosen_id = findChild.id;
      }
    },
  },
  computed: {
    getSelected: function () {
      if (this.chosen_id == 0) {
        return this.$store.getters.strings[this.$store.getters.currentLang]
          .chooseList;
      }
      const merged = [...this.parent, ...this.children].find(
        (data) => data.id == this.chosen_id
      );
      if (this.$store.getters.currentLang == 1) return merged.name_ru;
      if (this.$store.getters.currentLang == 2) return merged.name_uz;
      if (this.$store.getters.currentLang == 3) return merged.name_kr;
    },
    getChosenParent: function () {
      const merged = this.parent.find(
        (par) => par.id === parseInt(this.parent_id)
      );
      if (this.$store.getters.currentLang == 1) return merged.name_ru;
      if (this.$store.getters.currentLang == 2) return merged.name_uz;
      if (this.$store.getters.currentLang == 3) return merged.name_kr;
    },
    getRelatedCategories: function () {
      return this.children.filter(
        (child) => parseInt(child.parent_id) === parseInt(this.parent_id)
      );
    },
    ...mapGetters(["strings"]),
  },
};
</script>
