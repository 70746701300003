<template>
  <div class="blog-wrapp">
    <label v-if="$store.getters.currentLang == 1">{{ type.name_ru }}</label>
    <label v-else-if="$store.getters.currentLang == 2">
      {{
      type.name_uz
      }}
    </label>
    <label v-else-if="$store.getters.currentLang == 3">
      {{
      type.name_kr
      }}
    </label>
    <div class="blog" @click="show ? (show = false) : (show = true)">
      <span>{{ getSelected }}</span>
      <div class="icon" :class="{ active: show === true }">
        <img src="../assets/images/dropdown2.svg" alt />
      </div>
      <input
        type="hidden"
        class="type_value"
        v-if="chosen > 0"
        required="required"
        :value="getSelectedValue"
      />
    </div>
    <div class="regions" v-if="show">
      <div :class="'reg ' + (chosen === 0 ? 'active' : '')" @click="handelSelect(0)">
        <span>
          {{
          $store.getters.strings[$store.getters.currentLang].all
          }}
        </span>
      </div>
      <div
        :class="'reg ' + (chosen === value.id ? 'active' : '')"
        v-for="(value, index) in data"
        :key="index"
        @click="handelSelect(value.id)"
      >
        <span v-if="$store.getters.currentLang == 1">{{ value.name_ru }}</span>
        <span v-else-if="$store.getters.currentLang == 2">
          {{
          value.name_uz
          }}
        </span>
        <span v-else-if="$store.getters.currentLang == 3">
          {{
          value.name_kr
          }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    type: {
      type: Object,
      required: true,
    },
    name: {
      type: String,
      required: false,
    },
  },
  data() {
    return {
      show: false,
      chosen: 0,
    };
  },
  methods: {
    handelSelect: function (id) {
      if (id != this.chosen) {
        this.chosen = id;
        this.onSelect({ value: parseInt(id), type: this.type });
      }
      this.show = false;
    },
    ...mapActions(["onSelect"]),
  },
  computed: {
    getSelected: function () {
      if (this.chosen == 0) {
        return this.$store.getters.strings[this.$store.getters.currentLang]
          .chooseList;
      }
      const merged = this.data.filter((data) => data.id == this.chosen)[0];
      if (this.$store.getters.currentLang == 1) return merged.name_ru;
      if (this.$store.getters.currentLang == 2) return merged.name_uz;
      if (this.$store.getters.currentLang == 3) return merged.name_kr;
    },
    getSelectedValue: function () {
      return JSON.stringify([parseInt(this.data[0].parent_id), [this.chosen]]);
    },
  },
};
</script>
