<template>
  <div class="profile">
    <div class="container">
      <div class="profile-wrap">
        <div class="profile-left">
          <UserSideBar v-bind:setSettings="setSettings" v-bind:settings="settings" />
        </div>
        <div class="profile-right">
          <div class="profile-right_block" v-if="settings === 1">
            <div class="enter-registration_header">
              <span>Профиль</span>
              <span>Простой</span>
            </div>
            <div class="enter-registration_body">
              <div class="enter-registration_body_data">
                <div class="title">Личные данные</div>
                <div class="content">
                  <div class="photo">
                    <div class="photo-left">
                      <label>Фото:</label>
                      <div class="foto">
                        <img src="../assets/images/photo.png" alt />
                      </div>
                    </div>
                  </div>
                  <div class="big">
                    <div class="block">
                      <label>Ф.И.О:</label>
                      <div class="name">
                        <span>Лутфулла Зайниев</span>
                      </div>
                    </div>
                    <div class="block-2">
                      <div class="column">
                        <label>День рождение:</label>
                        <div class="date">
                          <span>09.09.1999</span>
                          <img src="../assets/images/date.svg" alt />
                        </div>
                      </div>
                      <div class="column">
                        <label>Пол:</label>
                        <div class="date">
                          <span>Мужчина</span>
                          <img src="../assets/images/dropdown2.svg" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="enter-registration_body_location">
                <div class="title">Местоположение</div>
                <div class="content">
                  <div class="country">
                    <label>Страна:</label>
                    <div class="uzb">
                      <span>Узбекистан, Ташкент</span>
                      <img src="../assets/images/dropdown2.svg" alt />
                    </div>
                  </div>
                </div>
              </div>

              <div class="enter-registration_body_contacts">
                <div class="title">Контактные данные</div>
                <div class="content">
                  <div class="block">
                    <label>Электронная почта:</label>
                    <div class="mail">
                      <span>by.ulugbek@gmail.com</span>
                    </div>
                  </div>
                  <div class="block">
                    <label>Номер телефона:</label>
                    <div class="mail">
                      <span>+998 99 888 88 88</span>
                    </div>
                  </div>
                </div>
                <div class="save">
                  <button>Сохранить</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSideBar from "@/components/UserSideBar";
export default {
  components: { UserSideBar },
  data() {
    return {
      settings: 1
    };
  },
  methods: {
    setSettings: function(value) {
      this.settings = value;
    }
  }
};
</script>