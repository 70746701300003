export default {
  state: {
    slides: [],
    sliderLoading: true,
  },
  actions: {
    fetchingSlides({ commit }) {
      fetch("https://admin.topin.uz/api/slider")
        .then((res) => res.json())
        .then((val) => commit("updateSlider", val.data));
    },
  },
  mutations: {
    updateSlider(state, data) {
      state.slides = data;
      state.sliderLoading = false;
    },
  },
  getters: {
    slides(state) {
      return state.slides;
    },
    sliderLoading(state) {
      return state.sliderLoading;
    },
  },
};
