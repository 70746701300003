<template>
  <div class="replenishment container">
    <div class="replenishment-header">
      <span class="repl">
        {{ $store.getters.strings[$store.getters.currentLang].amountAdder }}
      </span>
      <span>
        {{ $store.getters.strings[$store.getters.currentLang].yourBalance }}:
        {{ $store.getters.user.balance.amount }} UZS
      </span>
    </div>
    <div class="replenishment-body">
      <div class="replenishment-body_left">
        <div class="header">
          <span>
            {{ $store.getters.strings[$store.getters.currentLang].paymentType }}
          </span>
        </div>
        <div class="body">
          <div class="body-wrap">
            <div
              :class="`body-wrap-card centering ${
                method === 1 ? 'active' : ''
              } `"
              @click="setMethod(1)"
            >
              <img src="../assets/images/uzs-pay.png" alt />
            </div>
            <div
              :class="`body-wrap-card centering ${
                method === 2 ? 'active' : ''
              } `"
              @click="setMethod(2)"
            >
              <img src="../assets/images/usd-pay.png" alt />
            </div>
          </div>
        </div>
      </div>
      <div class="replenishment-body_left">
        <div class="header">
          <span>
            {{
              $store.getters.strings[$store.getters.currentLang].chooseAmount
            }}
          </span>
        </div>
        <div class="body" v-if="method > 0">
          <div class="body-wrap">
            <div
              class="body-wrap-card"
              v-for="(price, index) in prices"
              :key="index"
              @click="setChosen(index)"
              :class="{ active: chosen === index }"
            >
              <div class="img">
                <img src="../assets/images/coins1.svg" alt />
              </div>
              <div class="price">{{ price * (method === 2 ? 10 : 1) }} UZS</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="replenishment-buttons">
      <button @click="submit" v-if="chosen >= 0 && method > 0">Оплатить</button>
    </div>
    <loading v-if="loading" />
  </div>
</template>
<script>
import Loading from "./Loading.vue";

export default {
  components: { Loading },
  data() {
    return {
      prices: [5000, 10000, 20000, 30000, 40000, 50000, 60000, 70000],
      chosen: -1,
      method: -1,
      loading: false,
    };
  },
  methods: {
    setChosen: function (value) {
      this.chosen = this.chosen == value ? -1 : value;
    },
    setMethod: function (value) {
      this.method = this.method == value ? -1 : value;
      this.chosen = -1;
    },
    submit: function () {
      const body = {
        price: this.prices[this.chosen] * (this.method === 2 ? 10 : 1),
        type: this.method,
      };
      if (body.price > 0 && body.type > 0) {
        this.loading = true;
        this.$store
          .dispatch("getPaymentUrl", body)
          .then((value) => (window.location.href = value))
          .catch((e) => {
            this.loading = false;
            this.$toast.warning("Что-то пошло не так!", {
              timeout: 1000,
            });
          });
      }
    },
  },
};
</script>