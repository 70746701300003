<template>
  <div class="reclam container">
    <loading v-if="loading" />
    <div class="reclam-header">
      <span class="rec-title">
        {{
        $store.getters.strings[$store.getters.currentLang].promotion
        }}
      </span>
      <div class="title-wrapp">
        <span>
          {{
          $store.getters.strings[$store.getters.currentLang].yourBalance
          }}:
        </span>
        <span>{{$store.getters.user.balance.amount}} UZS</span>
      </div>
    </div>
    <div class="reclam-body">
      <div
        v-if="promoLoading"
        style="display:flex;align-items:center;justify-content:center; width: 100%; height: 500px"
      >
        <img src="../assets/images/categoryLoader.gif" width="200" alt />
      </div>
      <div
        class="reclam-body_block"
        v-for="(reclam, index) in promos"
        :key="index"
        @click="setChosen(reclam.id)"
        :class="{ active: chosen === reclam.id }"
      >
        <div class="reclam-body_block_img">
          <img src="../assets/images/big-orange.svg" id="grey" alt />
          <img src="../assets/images/big-orange2.svg" id="orange" alt />
        </div>
        <div class="reclam-body_block_title">
          <span v-if="$store.getters.currentLang == 1">{{ reclam.name_ru }}</span>
          <span v-else-if="$store.getters.currentLang == 2">{{ reclam.name_uz }}</span>
          <span v-else-if="$store.getters.currentLang == 3">{{ reclam.name_kr }}</span>
        </div>
        <div class="reclam-body_block_descr">
          <span v-if="$store.getters.currentLang == 1">{{ reclam.description_ru }}</span>
          <span v-else-if="$store.getters.currentLang == 2">{{ reclam.description_uz }}</span>
          <span v-else-if="$store.getters.currentLang == 3">{{ reclam.description_kr }}</span>

          <span>
            {{
            $store.getters.strings[$store.getters.currentLang].premiumCount
            }}
            <span
              class="premium"
            >{{ reclam.count }} {{$store.getters.strings[$store.getters.currentLang].count}}</span>
          </span>
        </div>
        <div class="reclam-body_block_price">
          <span>{{ reclam.price }} UZS</span>
        </div>
        <div class="reclam-body_block_imgBottom">
          <img src="../assets/images/img-bottom.svg" alt />
        </div>
      </div>
      <div
        class="mobile-block"
        v-for="(reclam, index) in promos"
        :key="'mobile' + index"
        @click="setChosen(index)"
        :class="{ active: chosen === index }"
      >
        <div class="mobile-block_content">
          <div class="mobile-block_content_img">
            <img src="../assets/images/big-orange.svg" id="grey" alt />
            <img src="../assets/images/big-orange2.svg" id="orange" alt />
          </div>
          <div class="mobile-block_content_text">
            <div class="mobile-block_content_text_title">
              <span v-if="$store.getters.currentLang == 1">{{ reclam.name_ru }}</span>
              <span v-else-if="$store.getters.currentLang == 2">{{ reclam.name_uz }}</span>
              <span v-else-if="$store.getters.currentLang == 3">{{ reclam.name_kr }}</span>
            </div>
            <div class="mobile-block_content_text_descr">
              <span v-if="$store.getters.currentLang == 1">{{ reclam.description_ru }}</span>
              <span v-else-if="$store.getters.currentLang == 2">{{ reclam.description_uz }}</span>
              <span v-else-if="$store.getters.currentLang == 3">{{ reclam.description_kr }}</span>
            </div>
          </div>
        </div>
        <div class="mobile-block_content_price">
          <span>{{ reclam.price }}</span>
        </div>
      </div>
    </div>
    <div class="reclam-buttons">
      <!-- <button>Пропустить</button> -->
      <button
        :disabled="chosen < 0"
        :class="{ chosen: chosen < 0 === false }"
        @click="sumbit"
      >{{ $store.getters.strings[$store.getters.currentLang].choose }}</button>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import Loading from "./Loading.vue";

export default {
  components: { Loading },
  data() {
    return {
      advert: JSON.parse(localStorage.getItem("showAdvert")),
      chosen: -1,
      loading: false,
    };
  },
  mounted() {
    const advert = this.$route.params.advert;
    if (advert) {
      localStorage.setItem("showAdvert", JSON.stringify(advert));
      this.advert = advert;
    }
    this.getPromos();
  },
  methods: {
    setChosen: function (value) {
      const setValue = this.chosen == value ? -1 : value;
      this.chosen = setValue;
    },
    sumbit: function () {
      if (this.chosen < 0) {
        this.$toast.warning("Выберите одно", {
          timeout: 1000,
        });
        return;
      }
      const getPrice = parseInt(
        this.promos.find((promo) => promo.id == this.chosen).price
      );
      if (getPrice > parseInt(this.$store.getters.user.balance.amount)) {
        this.$toast.warning("Не достаточно средств", {
          timeout: 2000,
        });
        return;
      }
      const body = {
        advert_id: this.advert.id,
        promo_id: this.chosen,
      };
      this.loading = true;
      this.promoteAdvert(body).then((value) => {
        if (value) {
          this.$toast.success("Успешно", {
            timeout: 2000,
          });
          this.$router.push({ path: "/ads" });
        } else {
          this.$toast.warning("Что-то пошло не так", {
            timeout: 2000,
          });
        }
        this.loading(false);
      });
    },
    ...mapActions(["getPromos", "promoteAdvert"]),
  },
  computed: {
    ...mapGetters(["promos", "promoLoading"]),
  },
};
</script>
