var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"profile"},[_c('div',{staticClass:"container"},[_c('div',{staticClass:"profile-wrap"},[_c('div',{staticClass:"profile-left"},[_c('UserSideBar')],1),_c('div',{staticClass:"profile-right_block"},[_c('div',{staticClass:"profile-right_block_header"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].settings)+" ")])]),(!_vm.loading)?_c('div',{staticClass:"profile-right_block_body"},[_c('div',{staticClass:"buttons"},[_c('button',{on:{"click":_vm.updateBusiness}},[(!Boolean(_vm.$store.getters.user.is_business))?[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang] .buyBussines)+" ")]:_vm._e(),(
                  Boolean(_vm.$store.getters.user.is_business) &&
                  !Boolean(_vm.$store.getters.user.is_paid)
                )?[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang] .payBussines)+" ")]:_vm._e(),(
                  Boolean(_vm.$store.getters.user.is_business) &&
                  Boolean(_vm.$store.getters.user.is_paid)
                )?[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang] .updateLimit)+" ")]:_vm._e()],2),_c('button',{on:{"click":_vm.logoutProfile}},[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].logout)+" ")]),_c('button',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}]},[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang] .deleteAccount)+" ")])])]):_c('div',{staticClass:"profile-right_block_body"},[_c('img',{staticStyle:{"margin":"0 auto","display":"block"},attrs:{"src":require("../assets/images/categoryLoader.gif"),"width":"200","alt":""}})])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }