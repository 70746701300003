var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"sidebar"},[_c('div',{staticClass:"sidebar-header"},[_c('div',{staticClass:"about"},[_c('div',{staticClass:"about-left"},[_c('div',{staticClass:"left-title"},[_vm._v(_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].balance)+":")]),_c('div',{staticClass:"left-price"},[_vm._v(_vm._s(_vm.$store.getters.user.balance ? _vm.$store.getters.user.balance.amount : 0)+" UZS")])]),_c('div',{staticClass:"about-right"},[_c('router-link',{attrs:{"to":"/replenishment"}},[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].addMoney)+" ")])],1)]),_c('div',{staticClass:"setting"},[_c('router-link',{class:{
          'router-link-active':
            _vm.$route.fullPath === '/' || _vm.$route.fullPath === '/a',
        },attrs:{"to":"/settings"}},[_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].settings)+" ")])])],1)]),_c('div',{staticClass:"sidebar-body"},[_c('div',{staticClass:"menu"},[_c('router-link',{staticClass:"menu-button",class:{
          'router-link-active':
            _vm.$route.fullPath === '/' || _vm.$route.fullPath === '/a',
        },attrs:{"to":!parseInt(_vm.$store.getters.user.is_business) ?'/profile' : '/profile/business'}},[_c('div',{staticClass:"menu-button-wrap d-flex"},[_c('img',{attrs:{"src":require("../assets/images/profile.svg"),"width":"24","height":"24","alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].profile)+" ")])])]),_c('router-link',{staticClass:"menu-button",attrs:{"to":"/ads"}},[_c('div',{staticClass:"menu-button-wrap d-flex"},[_c('img',{attrs:{"src":require("../assets/images/advert.svg"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].adverts)+" ")])])]),_c('router-link',{staticClass:"menu-button",attrs:{"to":"/operations"}},[_c('div',{staticClass:"menu-button-wrap d-flex"},[_c('img',{attrs:{"src":require("../assets/images/operation.svg"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].operations)+" ")])])]),_c('router-link',{staticClass:"menu-button",attrs:{"to":"/messages"}},[_c('div',{staticClass:"menu-button-wrap d-flex"},[_c('img',{attrs:{"src":require("../assets/images/messages.svg"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].messages)+" ")])])]),_c('router-link',{staticClass:"menu-button",attrs:{"to":"/favorite"}},[_c('div',{staticClass:"menu-button-wrap d-flex"},[_c('img',{attrs:{"src":require("../assets/images/favorite.svg"),"alt":""}}),_c('span',[_vm._v(" "+_vm._s(_vm.$store.getters.strings[_vm.$store.getters.currentLang].favorites)+" ")])])])],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }