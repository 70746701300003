<template>
  <div class="loader">
    <content-loader
      :height="230"
      :width="100"
      :speed="5"
      primaryColor="#5a5a5a"
      secondaryColor="#ff7800"
      preserveAspectRatio="none"
    >
      <rect x="0" y="0" rx="0" ry="0" height="230" width="100%" />
    </content-loader>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  components: {
    ContentLoader
  }
};
</script>
