<template>
  <div class="wrapper">
    <div class="myModal"></div>
    <Navigator />
    <router-view />
    <Footer
      v-show="
        $route.name !== 'entering1' &&
        $route.name !== 'entering2' &&
        $route.name !== 'entering3' &&
        $route.name !== 'registration' &&
        $route.name !== 'Cart'
      "
    />
  </div>
</template>

<script>
import Navigator from "@/components/Navigator.vue";
import Footer from "@/components/Footer.vue";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Navigator, Footer },
  methods: mapActions(["fetchData", "getUserData", "fetchingSlides"]),
  mounted() {
    this.fetchData();
    this.fetchingSlides();
    if (this.token !== null) {
      this.getUserData().then((value) => {
        if (value === false) {
          this.$router.go();
        }
      });
    }
  },
  computed: {
    ...mapGetters([
      "phone",
      "token",
      "user",
      "registered",
      "business",
      "categories",
      "strings",
      "currentLang",
    ]),
  },
};
</script>
