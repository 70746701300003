<template>
  <div class="home">
    <div class="home-reclamSlider">
      <MainSlider v-if="sliderLoading" />
      <VueSlickCarousel
        :arrows="true"
        v-if="slides.length && !sliderLoading"
        v-bind="settingsHome"
        :dots="false"
      >
        <img
          v-for="(slider, index) in slides"
          :key="index"
          :src="slider.image"
          :alt="slider.image + slider.id"
        />
      </VueSlickCarousel>
    </div>
    <div class="home-slider" style="padding-left: 12px">
      <VueSlickCarousel
        :arrows="false"
        v-if="categories.length && !categoriesLoading"
        :dots="false"
        v-bind="settings"
      >
        <div
          class="home-slider_category"
          v-for="(category, index) in categories"
          :key="index"
        >
          <router-link class="link" v-bind:to="'/search/' + category.id">
            <div class="home-slider_category_block">
              <div class="home-slider_category_block_left">
                <div class="title" v-if="$store.getters.currentLang == 1">
                  {{ category.name_ru }}
                </div>
                <div class="title" v-else-if="$store.getters.currentLang == 2">
                  {{ category.name_uz }}
                </div>
                <div class="title" v-else-if="$store.getters.currentLang == 3">
                  {{ category.name_kr }}
                </div>
                <div class="descr">{{ category.description_ru }}</div>
              </div>
              <div class="home-slider_category_block_right">
                <div class="image">
                  <img :src="category.image" alt="image" />
                </div>
              </div>
            </div>
            <div class="home-slider_category_background">
              <svg
                width="263"
                height="59"
                viewBox="0 0 263 59"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0 59.0001V0.120117C0 0.120117 54.7407 10.2401 132.723 10.2401C210.706 10.2401 263 0.120117 263 0.120117V59.0001H0Z"
                  :fill="'#' + category.color"
                />
              </svg>
            </div>
            <div class="home-slider_category_bottom">
              <!-- <div class="number">
                {{ category.adverts_count }}
                {{ $store.getters.strings[$store.getters.currentLang].adcount }}
              </div> -->
            </div>
          </router-link>
        </div>
      </VueSlickCarousel>
      <div class="preloader-wrap" v-if="categoriesLoading">
        <Category />
      </div>
    </div>
    <div class="home-slider_mobile">
      <VueSlickCarousel
        :arrows="false"
        v-if="categories.length && !categoriesLoading"
        :dots="false"
        v-bind="settings"
      >
        <div
          class="slider-block"
          v-for="category in categories"
          :key="category.id"
        >
          <router-link v-bind:to="'/search/' + category.id" class="link">
            <div class="wrap" v-bind:style="'background:#' + category.color">
              <img :src="category.image" alt="image" width="57" height="80" />
            </div>
            <span v-if="$store.getters.currentLang == 1">
              {{ category.name_ru }}
            </span>
            <span v-else-if="$store.getters.currentLang == 2">
              {{ category.name_uz }}
            </span>
            <span v-else-if="$store.getters.currentLang == 3">
              {{ category.name_kr }}
            </span>
          </router-link>
        </div>
      </VueSlickCarousel>
      <div class="preloader-wrap" v-if="categoriesLoading">
        <CategoryMobile />
      </div>
    </div>
    <div class="home-section container">
      <div class="home-section_reclam">
        <router-link to="/cart/0" style="width: 100%">
          <div class="cart">
            <div class="content">
              <div class="image">
                <img src="../assets/images/cart-img.svg" alt="photo" />
              </div>
              <div class="text">
                <p>
                  {{
                    $store.getters.strings[$store.getters.currentLang].showMap
                  }}
                </p>
              </div>
            </div>
          </div>
        </router-link>
        <div class="reclam-img">
          <router-link to="#" style="opacity: 0">
            <img src="../assets/images/001.png" alt="photo" />
          </router-link>
          <router-link to="#" style="opacity: 0">
            <img src="../assets/images/002.png" alt="photo" />
          </router-link>
          <router-link to="#" style="opacity: 0">
            <img id="img" src="../assets/images/001.png" alt="photo" />
          </router-link>
        </div>
      </div>
      <div class="home-section_products">
        <Products :products="adverts" v-if="!advertLoading" />
        <div class="centered" style="height: auto">
          <img
            src="../assets/images/categoryLoader.gif"
            width="200"
            v-if="advertLoading"
            alt
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";

import Products from "@/components/Products.vue";
import Category from "@/components/Preloaders/Category.vue";
import CategoryMobile from "@/components/Preloaders/CategoryMobile.vue";
import MainSlider from "@/components/Preloaders/MainSlider.vue";
import ActivityLoader from "@/components/Preloaders/ActivityLoader.vue";

import { mapGetters, mapActions } from "vuex";

export default {
  name: "Home",
  components: {
    VueSlickCarousel,
    Products,
    Category,
    CategoryMobile,
    MainSlider,
    ActivityLoader,
  },
  data() {
    return {
      settingsHome: {
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        draggable: true,
        swipe: true,
        touchMove: true,
        swipeToSlide: true,
      },
      settings: {
        variableWidth: true,
        swipeToSlide: true,
        swipe: true,
        // touchMove: true,
      },
    };
  },
  computed: mapGetters([
    "categories",
    "childCategories",
    "slides",
    "categoriesLoading",
    "sliderLoading",
    "adverts",
    "advertLoading",
  ]),
  methods: mapActions(["fetchData", "getMainAdverts", "fetchingSlides"]),
  mounted() {
    this.fetchData();
    this.fetchingSlides();
    this.getMainAdverts();
  },
};
</script>
