<template>
  <div class="profile">
    <div class="container">
      <div class="profile-wrap">
        <div class="profile-left">
          <UserSideBar />
        </div>
        <div class="profile-right_block">
          <div class="profile-right_block_header">
            <div class="title">
              {{ $store.getters.strings[$store.getters.currentLang].settings }}
            </div>
          </div>
          <div class="profile-right_block_body" v-if="!loading">
            <div class="buttons">
              <button @click="updateBusiness">
                <template v-if="!Boolean($store.getters.user.is_business)">
                  {{
                    $store.getters.strings[$store.getters.currentLang]
                      .buyBussines
                  }}
                </template>
                <template
                  v-if="
                    Boolean($store.getters.user.is_business) &&
                    !Boolean($store.getters.user.is_paid)
                  "
                >
                  {{
                    $store.getters.strings[$store.getters.currentLang]
                      .payBussines
                  }}
                </template>
                <template
                  v-if="
                    Boolean($store.getters.user.is_business) &&
                    Boolean($store.getters.user.is_paid)
                  "
                >
                  {{
                    $store.getters.strings[$store.getters.currentLang]
                      .updateLimit
                  }}
                </template>
              </button>
              <button @click="logoutProfile">
                {{ $store.getters.strings[$store.getters.currentLang].logout }}
              </button>
              <button v-show="false">
                {{
                  $store.getters.strings[$store.getters.currentLang]
                    .deleteAccount
                }}
              </button>
            </div>
          </div>
          <div class="profile-right_block_body" v-else>
            <img
              src="../assets/images/categoryLoader.gif"
              style="margin: 0 auto; display: block"
              width="200"
              alt
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import UserSideBar from "@/components/UserSideBar";
export default {
  components: { UserSideBar },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    ...mapActions(["logout"]),
    updateBusiness: function () {
      const currentBalance = parseInt(this.$store.getters.user.balance.amount);
      if (currentBalance >= 30000) {
        this.loading = true;
        this.$store
          .dispatch("changeToBusiness")
          .then((value) => {
            if (value) {
              this.$toast.success("Успешно", {
                timeout: 2000,
              });
              this.loading = false;
            }
          })
          .catch((e) => (this.loading = false));
      } else {
        this.$toast.warning("Не достаточно средств", {
          timeout: 2000,
        });
      }
    },
    logoutProfile: function () {
      this.logout().then((value) => {
        if (value) {
          this.$router.push({ path: "/login" });
        }
      });
    },
  },
  computed: {
    ...mapGetters(["currentLang"]),
  },
};
</script>
