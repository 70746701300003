import Vue from "vue";
import App from "./App.vue";

import "vue-slider-component/theme/default.css";
import "vue-toastification/dist/index.css";
import store from "./store";
import router from "./routes";
import "@/assets/scss/main.scss";
Vue.config.productionTip = false;
new Vue({
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
