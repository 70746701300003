const strings = [
  {},
  {
    /// ru
    add: "Добавить объявление",
    profile: "Профиль",
    uzbekistan: "Весь Узбекистан",
    keyword: "Что вы ищете?",
    every: "Все регионы",
    everyChild: "По всему региону",
    adcount: "объявлений",
    showMap: "ПОСМОТРЕТЬ НА КАРТЕ",
    filter: "Расширенный фильтр",
    priceFrom: "Цена от",
    priceTo: "Цена от",
    adview: "Вид объявлений",
    applyFilter: "Применить фильтр",
    apply: "Применить",
    allAds: "Все обьявления",
    chooseList: "Выберите из списка",
    all: "Все",
    from: "От",
    to: "До",
    price: "Цена",
    pricePlace: "Введите цену",
    addAdvert: "Добавление объявления",
    addRazdel: "Раздел публикации",
    addName: "Заголовок",
    addNamePlace: "Напишите краткое заглавление",
    addCategory: "Выберите категорию",
    addDescr: "Описание",
    addDescrPlace: "Напишите подробное описание",
    addDetails: "Детали Объявления",
    address: "Адрес",
    addressDescr: "Точный адрес поможет привлечь заинтересованных арендаторов",
    addressInfo: "Подробности адреса",
    photo: "Фото",
    contacts: "Контакты",
    phone: "Номер телефона",
    publish: "Опубликовать",
    simple: "Простой",
    personal: "Личные данные",
    fio: "Ф.И.О",
    birthday: "День рождение",
    location: "Местоположение",
    region: "Регион",
    contactInfo: "Контактные данные",
    email: "Электронная почта",
    save: "Сохранить",
    sex: "Пол",
    balance: "Баланс",
    addMoney: "Пополнить",
    settings: "Настройки",
    adverts: "Объявления",
    operations: "Операции",
    messages: "Сообщения",
    favorites: "Избранные",
    male: "Мужчина",
    female: "Женщина",
    buyBussines: "Купить бизнес профиль",
    logout: "Выйти из аккаунта",
    deleteAccount: "Удалить аккаунт",
    sort: "Сортировка",
    see: "Посмотреть",
    edit: "Редактировать",
    off: "Отключить",
    promote: "Рекламировать",
    publishBy: "Опубликовал",
    addedOn: "Добавлено в",
    write: "Напишите письмо",
    id: "Идентификатор",
    addedTime: "Дата и время добавления",
    onMap: "На карте",
    contactFace: "Контактное лицо",
    inSystem: "В системе Topin",
    advertsCount: "Кол-во объявлений",
    accType: "Тип аккаунта",
    simpleAcc: "Частный",
    call: "Позвонить",
    writeMess: "Написать",
    showProfile: "Посмотреть профиль",
    sameAds: "ПОХОЖИЕ ОБЪЯВЛЕНИЕ",
    bussinessAcc: "Бизнес",
    promotion: "Реклама",
    yourBalance: "Ваш баланс",
    chooseAmount: "Выберите сумму пополнения",
    amountAdder: "Пополнение баланса",
    paymentType: "Выберите способ оплаты",
    choose: "Выбрать",
    agreement: "Войдя в аккаунт вы соглашаетесь с",
    agree: "условиями использования приложения",
    login: "Войти в аккаунт",
    verify_code: "Введите отправленный код",
    again: "Отправить заного",
    chooseTypeAcc: "Выберите тип аккаунта",
    continue: "Продолжить",
    notExist: "Пока ничего нет",
    enable: "Активировать",
    moneyPlus: "Приход",
    moneyMinus: "Расход",
    banner: "Баннер",
    bussinessName: "Юридическое название фирмы",
    sphere: "Сфера деятельности",
    inn: "ИНН",
    workdays: "Режим работы",
    website: "Веб сайт",
    updateLimit: "Увеличить лимит",
    payBusiness: "Оплатить бизнес профиль",
    premiumCount: "Кол-во премиум показов",
    count: "шт",
    promoCount: "Кол-во рекламы",
    notFound: "Ничего не найдено",
    filterSimple: "Фильтр",
    addFavorite: "В избранные",
  },
  {
    ///uz
    add: "E‘lon berish",
    profile: "Profil",
    uzbekistan: "Butun O'zbekiston",
    keyword: "Qidiruv ...",
    every: "Barcha tumanlar",
    everyChild: "Tuman boyicha",
    adcount: "e‘lonlar",
    showMap: "XARITADA KO'RISH",
    filter: "Kengaytirilgan filtr",
    priceFrom: "Narx dan",
    priceTo: "Narx gacha",
    adview: "E‘lon korinishi",
    applyFilter: "Topish",
    apply: "Topish",
    allAds: "Barcha e‘lonlar",
    chooseList: "Ro'yxatdan tanlang",
    all: "Barchasi",
    from: "Dan",
    to: "Gacha ...",
    price: "Narx",
    pricePlace: "Narxni kiriting",
    addAdvert: "E‘lon berish",
    addRazdel: "E‘lon bo'limi",
    addName: "Sarlavha",
    addNamePlace: "Sarlavhani kiriting",
    addCategory: "Bo'limni tanlang",
    addDescr: "Ta‘rif",
    addDescrPlace: "Ta‘rifni kiriting",
    addDetails: "E‘lon tafsiloti",
    address: "Manzil",
    addressDescr:
      "Tog'ri manzilni kiritish koproq qiziqitirishga yordam beradi",
    addressInfo: "Manzil tafsiloti",
    photo: "Rasm",
    contacts: "Aloqalar",
    phone: "Telefon raqami",
    publish: "Tasdiqlayman",
    simple: "Oddiy",
    personal: "Shaxsiy ma‘lumotlar",
    fio: "To'liq Ismi Sharif",
    birthday: "Tug'ilgan kun",
    location: "Xaritada joylashuvi",
    region: "Tuman",
    contactInfo: "Aloqa ma'lumotlari",
    email: "Elektron pochta",
    save: "Saqlash",
    sex: "Jins",
    balance: "Balans",
    addMoney: "To'ldirish",
    settings: "Sozlamalar",
    adverts: "E‘lonlar",
    operations: "Operatsiyalar",
    messages: "Xatlar",
    favorites: "Sevimlilar",
    male: "Erkak",
    female: "Ayol",
    buyBussines: "Biznes profilini sotib olish",
    logout: "Profildan chiqish",
    deleteAccount: "Profilni o'chirish",
    sort: "Tartiblash",
    see: "Ko'rish",
    edit: "Tahrirlash",
    off: "O'chirish",
    promote: "Reklama qilish",
    publishBy: "Yuklagan",
    addedOn: "Qo'shilgan vaqti",
    write: "Xat yozing",
    id: "Identifikator",
    addedTime: "Qo'shilgan vaqti",
    onMap: "Xaritada",
    contactFace: "Bog'lanish uchun",
    inSystem: "Topin tizimida",
    advertsCount: "E‘lonlar soni",
    accType: "Profil turi",
    simpleAcc: "Oddiy",
    call: "Qo'ng'iroq qilish",
    writeMess: "Xat yozish",
    showProfile: "Profilni korish",
    sameAds: "O'xshash e‘lonlar",
    bussinessAcc: "Biznes",
    promotion: "Reklama",
    yourBalance: "Sizning balansingiz",
    chooseAmount: "Hisobni to'ldirish miqdorini tanlang",
    amountAdder: "Hisobni to'ldirish",
    paymentType: "To'lov usulini tanlang",
    choose: "Tanlash",
    agreement: "Profilga bo'limiga kirib",
    agree: "Foydalanish shartlariga rozilik bildirgan bo'lasiz",
    login: "Profilga kirish",
    verify_code: "Yuvorilgan kodni kiriting",
    again: "Qaytadan yuvorish",
    chooseTypeAcc: "Profil turini tanlang",
    continue: "Davom ettirish",
    notExist: "Xali hech narsa y'oq",
    enable: "Aktivlash",
    moneyPlus: "To'ldirish",
    moneyMinus: "Sarflash",
    banner: "Banner",
    bussinessName: "Yuridik nom",
    sphere: "Belgilangan soha",
    inn: "INN",
    workdays: "Ish vaqti",
    website: "Veb sayt",
    updateLimit: "Limitni oshirish",
    payBusiness: "Biznes profilni to'lash",
    premiumCount: "Premium korsatuvlar soni",
    count: "dona",
    promoCount: "Reklama soni",
    notFound: "Hech narsa topilmadi",
    filterSimple: "Filtr",
    addFavorite: "Sevimli",
  },
  {
    ///kr
    add: "Эълон бериш",
    profile: "Профил",
    uzbekistan: "Бутун Ўзбекистон",
    keyword: "Қидирув ...",
    every: "Барча туманлар",
    everyChild: "Туман бойича",
    adcount: "эълонлар",
    showMap: "ХАРИТАДА КЎРИШ",
    filter: "Кенгайтирилган филтр",
    priceFrom: "Нарх дан",
    priceTo: "Нарх гача",
    adview: "Эълон кориниши",
    applyFilter: "Топиш",
    apply: "Топиш",
    allAds: "Барча эълонлар",
    chooseList: "Рўйхатдан танланг",
    all: "Барчаси",
    from: "Дан",
    to: "Гача ...",
    price: "Нарх",
    pricePlace: "Нархни киритинг",
    addAdvert: "Эълон бериш",
    addRazdel: "Эълон бўлими",
    addName: "Сарлавҳа",
    addNamePlace: "Сарлавҳани киритинг",
    addCategory: "Бўлимни танланг",
    addDescr: "Та‘риф",
    addDescrPlace: "Та‘рифни киритинг",
    addDetails: "Эълон тафсилоти",
    address: "Манзил",
    addressDescr: "Тоғри манзилни киритиш копроқ қизиқитиришга ёрдам беради",
    addressInfo: "Манзил тафсилоти",
    photo: "Расм",
    contacts: "Алоқалар",
    phone: "Телефон рақами",
    publish: "Тасдиқлайман",
    simple: "Оддий",
    personal: "Шахсий ма‘лумотлар",
    fio: "Тўлиқ Исми Шариф",
    birthday: "Туғилган кун",
    location: "Харитада жойлашуви",
    region: "Туман",
    contactInfo: "Алоқа маълумотлари",
    email: "Электрон почта",
    save: "Сақлаш",
    sex: "Жинс",
    balance: "Баланс",
    addMoney: "Тўлдириш",
    settings: "Созламалар",
    adverts: "Эълонлар",
    operations: "Операциялар",
    messages: "Хатлар",
    favorites: "Севимлилар",
    male: "Эркак",
    female: "Аёл",
    buyBussines: "Бизнес профилини сотиб олиш",
    logout: "Профилдан чиқиш",
    deleteAccount: "Профилни ўчириш",
    sort: "Тартиблаш",
    see: "Кўриш",
    edit: "Таҳрирлаш",
    off: "Ўчириш",
    promote: "Реклама қилиш",
    publishBy: "Юклаган",
    addedOn: "Қўшилган вақти",
    write: "Хат ёзинг",
    id: "Идентификатор",
    addedTime: "Қўшилган вақти",
    onMap: "Харитада",
    contactFace: "Боғланиш учун",
    inSystem: "Топин тизимида",
    advertsCount: "Эълонлар сони",
    accType: "Профил тури",
    simpleAcc: "Оддий",
    call: "Қўнғироқ қилиш",
    writeMess: "Хат ёзиш",
    showProfile: "Профилни кориш",
    sameAds: "Ўхшаш эълонлар",
    bussinessAcc: "Бизнес",
    promotion: "Реклама",
    yourBalance: "Сизнинг балансингиз",
    chooseAmount: "Ҳисобни тўлдириш миқдорини танланг",
    amountAdder: "Ҳисобни тўлдириш",
    paymentType: "Тўлов усулини танланг",
    choose: "Танлаш",
    agreement: "Профилга бўлимига кириб",
    agree: "Фойдаланиш шартларига розилик билдирган бўласиз",
    login: "Профилга кириш",
    verify_code: "Юворилган кодни киритинг",
    again: "Қайтадан ювориш",
    chooseTypeAcc: "Профил турини танланг",
    continue: "Давом эттириш",
    notExist: "Хали ҳеч нарса йоқ",
    enable: "Активлаш",
    moneyPlus: "Тўлдириш",
    moneyMinus: "Сарфлаш",
    banner: "Баннер",
    bussinessName: "Юридик номи",
    sphere: "Белгиланган соҳа",
    inn: "ИНН",
    workdays: "Иш вақти",
    website: "Веб сайт",
    updateLimit: "Лимитни ошириш",
    payBusiness: "Бизнес профилни тўлаш",
    premiumCount: "Премиум корсатувлар сони",
    count: "дона",
    promoCount: "Реклама сони",
    notFound: "Ҳеч нарса топилмади",
    filterSimple: "Филтр",
    addFavorite: "Севимли",
  },
];
export default strings;
