<template>
  <div class="enter">
    <form class="enter-modal" @submit.prevent="submit">
      <div class="enter-modal_logo">
        <img src="../assets/images/logo1.svg" alt />
      </div>
      <TheMask
        type="tel"
        :mask="'(##) ###-##-##'"
        required="required"
        v-model="phone"
        :id="'phone'"
        :placeholder="$store.getters.strings[$store.getters.currentLang].phone"
        :masked="false"
        :class="!valid ? 'fail' : ''"
      />
      <div class="enter-modal_text">
        <span>{{
          $store.getters.strings[$store.getters.currentLang].agreement
        }}</span>
        <br />
        <router-link to="/policy">{{
          $store.getters.strings[$store.getters.currentLang].agree
        }}</router-link>
      </div>
      <div class="enter-modal_button">
        <button>
          {{ $store.getters.strings[$store.getters.currentLang].login }}
        </button>
      </div>
    </form>
    <loading v-if="loading" />
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import { TheMask } from "vue-the-mask";
import Loading from "./Loading.vue";

export default {
  components: { TheMask, Loading },
  data() {
    return {
      phone: "",
      valid: true,
      loading: false,
    };
  },
  watch: {
    phone(value) {
      this.valid = value.length === 9;
    },
  },
  methods: {
    submit: function () {
      if (this.valid) {
        this.loading = true;
        this.verify(this.phone)
          .then((value) => {
            if (value) {
              this.$router.push("/verify");
            }
          })
          .catch((e) => {
            this.loading = false;
            this.phone = "";
            document.querySelector("#phone").focus();
          });
      } else {
        document.querySelector("#phone").focus();
      }
    },
    ...mapActions(["verify"]),
  },
  computed: {
    ...mapGetters(["token", "user"]),
  },
};
</script>
