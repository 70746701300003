<template>
  <form class="add-product container" @submit.prevent="submitData($event)">
    <div class="loading" v-if="advertUpload || advertLoad">
      <AdvertLoading />
    </div>
    <template v-if="!advertLoad">
      <div class="title">
        <span>
          {{ $store.getters.strings[$store.getters.currentLang].addAdvert }}
        </span>
      </div>
      <div class="publication">
        <div class="publication-header">
          <div class="publication-header_title">
            {{ $store.getters.strings[$store.getters.currentLang].addRazdel }}
          </div>
        </div>
        <div class="publication-body">
          <div class="form">
            <div class="blog-full">
              <label>
                {{
                  $store.getters.strings[$store.getters.currentLang].addName
                }}:
              </label>
              <input
                required="required"
                v-model="name"
                type="text"
                maxlength="100"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang]
                    .addNamePlace
                "
              />
            </div>
          </div>
          <div class="form">
            <div class="blog-full">
              <label>
                {{ $store.getters.strings[$store.getters.currentLang].price }}:
              </label>
              <input
                required="required"
                v-model="price"
                type="number"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang].pricePlace
                "
              />
            </div>
          </div>
          <div class="form">
            <div class="blog-full">
              <label>
                {{
                  $store.getters.strings[$store.getters.currentLang]
                    .addCategory
                }}:
              </label>
              <SearchMulti
                :parent="categories"
                :children="childCategories"
                :chosen="String(advert.category_id)"
                :setter="getTypesByCategory"
                v-if="categories.length > 0"
              />
              <div
                class="form-region"
                style="overflow: hidden; justify-content: center"
                v-else
              >
                <ActivityLoader />
              </div>
            </div>
          </div>
          <div class="form">
            <div class="blog-full">
              <label>
                {{
                  $store.getters.strings[$store.getters.currentLang].addDescr
                }}:
              </label>
              <textarea
                v-model="description"
                maxlength="250"
                :placeholder="
                  $store.getters.strings[$store.getters.currentLang]
                    .addDescrPlace
                "
              ></textarea>
            </div>
          </div>
        </div>
      </div>
      <div
        class="publication"
        v-if="childTypes.length || advert.advert_type.length"
      >
        <div class="publication-header">
          <div class="publication-header_title">
            {{ $store.getters.strings[$store.getters.currentLang].addDetails }}
          </div>
        </div>
        <div class="publication-body">
          <div v-if="!chosenTypes.length">
            <div
              class="advert_typer"
              v-for="(type, key) in advert.advert_type"
              :key="key"
            >
              <span>
                <template v-if="$store.getters.currentLang == 1">{{
                  type.name_ru
                }}</template>
                <template v-if="$store.getters.currentLang == 2">{{
                  type.name_uz
                }}</template>
                <template v-if="$store.getters.currentLang == 3">{{
                  type.name_kr
                }}</template
                >:
              </span>
              <span>
                <template v-if="$store.getters.currentLang == 1">{{
                  type.pivot.value.name_ru
                }}</template>
                <template v-if="$store.getters.currentLang == 2">{{
                  type.pivot.value.name_uz
                }}</template>
                <template v-if="$store.getters.currentLang == 3">{{
                  type.pivot.value.name_kr
                }}</template>
              </span>
            </div>
            <div class="form">
              <p>Для изменения выберите категорию заново</p>
            </div>
          </div>
          <template v-for="type in childTypes">
            <div :key="type.id">
              <div class="form">
                <SelectAdd
                  v-if="type.changeable != 1"
                  :data="type.children"
                  :type="type"
                />
                <TextAdd v-else :data="type" />
              </div>
              <template v-for="inner in typesChildren">
                <div
                  v-if="
                    inner.children.length >= 1 &&
                    checkForRelevance(inner) &&
                    parseInt(type.changeable) === 2
                  "
                  :key="inner.id + 'child'"
                  class="form"
                >
                  <SelectAdd :data="inner.children" :type="inner" />
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
      <div class="publication">
        <div class="publication-header">
          <div class="publication-header_title">
            {{ $store.getters.strings[$store.getters.currentLang].address }}
          </div>
          <span>
            {{
              $store.getters.strings[$store.getters.currentLang].addressDescr
            }}
          </span>
        </div>
        <div class="publication-body">
          <div class="form">
            <div class="blog-wrapp">
              <label>Город:</label>
              <div class="multi-wrap">
                <div class="blog" @click="select = !select">
                  <span>
                    {{ this.currentRegion }}
                  </span>
                  <div class="icon" :class="{ active: select === true }">
                    <img src="../assets/images/dropdown2.svg" alt />
                  </div>
                </div>
                <div class="regions" v-if="select">
                  <div class="regions-title">
                    <span>
                      {{
                        $store.getters.strings[$store.getters.currentLang].every
                      }}
                    </span>
                    <div class="icon">
                      <img src="../assets/images/drop-right-dark.svg" alt />
                    </div>
                  </div>
                  <div
                    class="reg"
                    v-for="region in getMainRegions"
                    :key="region.id"
                    @click="updateChooseParent(region)"
                  >
                    <span v-if="$store.getters.currentLang == 1">
                      {{ region.name_ru }}
                    </span>
                    <span v-else-if="$store.getters.currentLang == 2">
                      {{ region.name_uz }}
                    </span>
                    <span v-else-if="$store.getters.currentLang == 3">
                      {{ region.name_kr }}
                    </span>
                    <div class="icon">
                      <img src="../assets/images/drop-right.svg" alt />
                      <img src="../assets/images/drop-right-hover.svg" alt />
                    </div>
                  </div>
                </div>
                <div class="region-city" v-if="region_id && isOpen === true">
                  <div class="region-city_title">
                    <span
                      @click="updateChooseRegion(getChildRegions[0].parent_id)"
                    >
                      {{
                        $store.getters.strings[$store.getters.currentLang]
                          .everyChild
                      }}
                    </span>
                    <div class="icon">
                      <img src="../assets/images/drop-right-dark.svg" alt />
                    </div>
                  </div>
                  <div
                    class="region-city_tash"
                    v-for="(reg, index) in getChildRegions"
                    :key="index"
                    @click="updateChooseRegion(reg.id)"
                  >
                    <span v-if="$store.getters.currentLang == 1">
                      {{ reg.name_ru }}
                    </span>
                    <span v-else-if="$store.getters.currentLang == 2">
                      {{ reg.name_uz }}
                    </span>
                    <span v-else-if="$store.getters.currentLang == 3">
                      {{ reg.name_kr }}
                    </span>
                    <div class="icon">
                      <img src="../assets/images/drop-right.svg" alt />
                      <img src="../assets/images/drop-right-hover.svg" alt />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="form">
            <div class="blog-full">
              <GmapMap
                :center="center"
                :zoom="15"
                :options="{
                  mapTypeControl: false,
                  streetViewControl: false,
                  rotateControl: false,
                  fullscreenControl: false,
                  disableDefaultUI: false,
                  clickable: false,
                }"
                style="
                  width: 100%;
                  height: 400px;
                  margin-top: 10px;
                  border-radius: 20px;
                  overflow: hidden;
                "
              >
                <GmapMarker
                  :position="center"
                  :clickable="false"
                  :draggable="true"
                  @dragend="updateCoordinates"
                  :icon="markerOptions"
                />
              </GmapMap>
            </div>
          </div>
        </div>
      </div>
      <div class="publication">
        <div class="publication-header">
          <div class="publication-header_title">
            {{ $store.getters.strings[$store.getters.currentLang].photo }}
          </div>
        </div>
        <div class="photo-body">
          <template v-if="advert.image.length">
            <template v-if="advert.image[0].split('/').length > 5">
              <div
                class="picker"
                v-for="(image, key) in advert.image"
                @click="removeImage(key)"
                :key="key + 'images'"
              >
                <img src="../assets/images/trash.svg" class="trash" alt />
                <img :src="image" class="img" alt />
              </div>
            </template>
          </template>
          <template v-if="files.length">
            <div
              class="picker"
              v-for="(file, key) in files"
              @click="removeFile(key)"
              :key="key"
            >
              <img src="../assets/images/trash.svg" class="trash" alt />
              <img :src="url[key]" class="img" alt />
            </div>
          </template>
          <img
            src="../assets/images/photo2.png"
            class="img"
            @click="focuser"
            alt="add"
          />
          <input
            type="file"
            style="width: 0"
            multiple="multiple"
            ref="picker"
            @change="handleFileChange($event)"
          />
        </div>
      </div>
      <div class="publication">
        <div class="publication-header">
          <div class="publication-header_title">
            {{ $store.getters.strings[$store.getters.currentLang].contacts }}
          </div>
        </div>
        <div class="publication-body">
          <div class="form">
            <div class="blog-wrapp">
              <label>
                {{ $store.getters.strings[$store.getters.currentLang].phone }}:
              </label>
              <input
                type="text"
                disabled="disabled"
                :value="phone"
                id="contacts"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button type="submit" class="color">
          {{ $store.getters.strings[$store.getters.currentLang].publish }}
        </button>
      </div>
    </template>
  </form>
</template>

<script>
// import Fasad from "@/components/Fasad";
import { mapGetters, mapActions } from "vuex";
import Vue from "vue";
import SelectAdd from "@/components/SelectAdd";
import SearchMulti from "@/components/SearchMulti";
import AdvertLoading from "./AdvertLoading";
import ActivityLoader from "@/components/Preloaders/ActivityLoader";
import TextAdd from "@/components/TextAdd";
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import * as VueGoogleMaps from "vue2-google-maps";

Vue.use(Toast);
const mapMarker = require("@/assets/images/map-marker.svg");
export default {
  components: {
    SelectAdd,
    SearchMulti,
    ActivityLoader,
    TextAdd,
    AdvertLoading,
  },
  data() {
    return {
      markerOptions: {
        url: mapMarker,
        size: { width: 170, height: 50, f: "px", b: "px" },
        scaledSize: { width: 170, height: 50, f: "px", b: "px" },
        labelColor: "white",
      },
      center: { lat: 41.311081, lng: 69.240562 },
      files: [],
      url: [],
      select: false,
      isOpen: false,
      name: "",
      description: "",
      price: "",
      addressInfo: "",
      category_id: 0,
      regionUpdated: false,
      coordinates: {
        lat: 41.311081,
        lng: 69.240562,
      },
      region_id: 2,
      parent_id: 1,
      // this.advert.region
    };
  },
  mounted() {
    this.setAdvertInfo();
  },
  methods: {
    setAdvertInfo: async function () {
      const routeId = this.$route.params.id;
      const app = this;
      this.getOwnAdvert(routeId).then((advert) => {
        app.name = advert.name;
        app.description = advert.description;
        app.category_id = advert.category_id;
        app.price = advert.price;
        app.addressInfo = advert.address_info;
        app.coordinates = {
          lat: Number(advert.lat) || 41.311081,
          lng: Number(advert.long) || 69.240562,
        };
        app.center = {
          lat: Number(advert.lat) || 41.311081,
          lng: Number(advert.long) || 69.240562,
        };
        if (!!advert.region) {
          app.region_id = advert.region.id;
          app.parent_id = advert.region.parent_id;
        }
      });

      // this.updateChooseRegion(this.advert.region_id);
    },
    updateCoordinates: function (location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    submitData: function ($event) {
      const getTypes = document.querySelectorAll(".type_id");
      const getAdvertTypes = document.querySelectorAll(".advert_type");
      const input_file = document.querySelector('input[type="file"]');
      let advert_type = [];
      let type_id = [];
      getTypes.forEach((element) => {
        type_id.push(parseInt(element.value));
      });
      getAdvertTypes.forEach((element) => {
        advert_type.push(parseInt(element.value));
      });
      const checker = () => {
        if (
          !advert_type.length &&
          !type_id.length &&
          !this.advert.advert_type.length
        ) {
          return false;
        }
        return (
          !advert_type.length &&
          !type_id.length &&
          !this.advert.advert_type.length
        );
      };
      if (
        checker() ||
        !this.category_id ||
        !this.description.length ||
        !this.name.length
      ) {
        return this.$toast.warning("Нужно заполнить все!", {
          timeout: 1800,
        });
      }
      const body = {
        advert_type: advert_type,
        type_id: type_id,
        category_id: this.category_id,
        region_id: this.region_id,
        name: this.name,
        description: this.description,
        price: this.price,
        phone: this.phone,
        lat: this.coordinates.lat.toFixed(6),
        long: this.coordinates.lng.toFixed(6),
        files: this.files,
        address_info: '',
        id: this.advert.id,
      };
      this.updateAdvert(body).then((value) => {
        if (value) {
          this.$toast.success("Успешно изменён!", {
            timeout: 1000,
          });
          setTimeout(() => this.$router.push({ path: "/ads" }), 1000);
        } else {
          this.$toast.warning("Что-то пошло не так!", {
            timeout: 1000,
          });
        }
      });
    },
    focuser: function () {
      this.$refs.picker.click();
    },
    handleFileChange: function ($event) {
      const files = $event.target.files;
      this.files = [...files, ...this.files];
      this.files.forEach(
        (file, key) => (this.url[key] = URL.createObjectURL(file))
      );
    },
    removeFile: function (key) {
      this.files = this.files.filter((file, index) => index !== key);
      this.url = this.url.filter((file, index) => index !== key);
    },
    removeImage: async function (key) {
      const file = this.advert.image[key];
      const name = file.split("/")[6];
      const isDeleted = await this.removeAdvertImage({
        id: this.advert.id,
        file_name: name,
      });
      if (isDeleted)
        this.advert.image = this.advert.image.filter(
          (file, index) => index !== key
        );
    },
    checkForRelevance: function (inner) {
      return this.chosenTypes.filter(
        (choice) => choice.value === parseInt(inner.parent_id)
      ).length;
    },
    getTypesByCategory: function (id) {
      this.searchTypeByCategory(parseInt(id));
      this.category_id = id;
      this.advert.advert_type = [];
    },
    updateChooseParent: function (region) {
      this.isOpen = true;
      this.select = true;
      this.parent_id = region.id;
      // console.log(region.id);
      // this.handleChooseParent(region);
    },
    updateChooseRegion: function (id) {
      this.isOpen = false;
      this.select = false;
      const { lat, long } = this.regions.find((region) => region.id == id);
      this.center = {
        lat: Number(lat),
        lng: Number(long),
      };
      this.coordinates = {
        lat: Number(lat),
        lng: Number(long),
      };
      // this.handleChooseRegion(id);
      this.region_id = id;
      this.regionUpdated = true;
    },
    handleSelect: function () {
      this.select = !this.select;
    },
    ...mapActions([
      "regionHandler",
      "handleChooseRegion",
      "handleChooseParent",
      "searchTypeByCategory",
      "updateAdvert",
      "getOwnAdvert",
      "removeAdvertImage",
    ]),
  },
  computed: {
    ...mapGetters([
      "regions",
      "getMainRegions",
      "getMainParent",
      "childCategories",
      "categories",
      "childTypes",
      "typesChildren",
      "chosenTypes",
      "phone",
      "advertUpload",
      "advert",
      "advertLoad",
    ]),
    currentRegion: function () {
      const find = this.regions.find((reg) => reg.id == this.region_id);
      let name = "";
      if (!find) return name;
      const currentLang = this.$store.getters.currentLang;
      if (currentLang == 1) {
        name = find.name_ru;
      }
      if (currentLang == 2) {
        name = find.name_uz;
      }
      if (currentLang == 3) {
        name = find.name_kr;
      }
      return name;
    },
    getChildRegions: function () {
      const childs = this.regions.filter(
        (reg) => reg.parent_id === this.parent_id
      );
      return childs;
    },
  },
};
</script>
