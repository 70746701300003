<template>
  <div class="container">
    <div
      v-if="$store.getters.currentLang == 2 || $store.getters.currentLang == 3"
    >
      <br />
      <p><strong>Фойдаланувчи шартномаси</strong></p>
      <br />
      <p>
        <a href="http://www.topin.uz">www.topin.uz</a> веб-сайти &laquo;TOPIN
        TECHNICAL SYSTEMS&raquo; МЧЖ мулки ҳисобланади ва вақтинчалик эълонларни
        жойлаштириш учун алоқа майдончасидир.
      </p>
      <p>
        Ушбу фойдаланувчи шартномаси (кейинги ўринларда Шартнома деб юритилади)
        <a href="http://www.topin.uz">www.topin.uz</a> веб-сайтида ва Иловаларда
        (IOS, Android) эълонларни жойлаштириш хизматларини кўрсатиш учун
        &laquo;TOPIN TECHNICAL SYSTEMS&raquo; МЧЖнинг оммавий офертаси
        ҳисобланади. Шартнома
        <a href="http://www.topin.uz">www.topin.uz</a> веб-сайтидан тармоқ
        фойдаланувчилари томонидан фойдаланиш қоидалари ва шартларини
        белгилайди.
      </p>
      <p>
        Ўзбекистон Республикаси Фуқаролик Кодексининг 367-375-моддаларига
        мувофиқ, қуйида келтирилган оммавий оферта шартлари қабул қилинган
        (акцепт) тақдирда, ҳар қандай муомалага лаёқатли жисмоний ёки юридик
        шахс (бундан буён матнда Фойдаланувчи деб юритилади) ушбу Шартнома
        шартларига риоя қилишни ўз зиммасига олади.
      </p>
      <p><strong>1. УМУМИЙ ҚОИДАЛАР.</strong></p>
      <br />
      <p>
        1.1. Ушбу Шартномада, агар контекстда бошқача кўрсатилмаган бўлса,
        қуйида келтирилган атамалар қуйидаги маъноларга эга:
      </p>
      <p>
        &bull; Веб-сайт - маъмурияти учун жавобгарликни Ижрочи ўз зиммасига
        олувчи
        <a href="http://www.topin.uz">www.topin.uz</a> веб-сайти.
      </p>
      <p>
        &bull; Иловалар (Топин) - (IOS, Android) смартфонлар, электрон
        планшетлар, тақиладиган плеерлар ва бошқа баъзи қурилмалар учун мобил
        операцион тизим.
      </p>
      <p>
        &bull; Ижрочи - &laquo;TOPIN TECHNICAL SYSTEMS&raquo; масъулияти
        чекланган жамияти, юридик манзили: Тошкент ш., Яшнобод тумани, Янгилик
        кўчаси, 37-уй.
      </p>
      <p>
        &bull; Фойдаланувчи - ушбу Шартнома шартларини қабул қилган ва
        Ижрочининг хизматларидан фойдаланадиган ҳар қандай муомала лаёқатига эга
        жисмоний ёки юридик шахс;
      </p>
      <p>
        &bull; Эълон - фойдаланувчининг чексиз кўп сонли бошқа фойдаланувчиларга
        фуқаролик-ҳуқуқий битим тузиш учун (товарлар, хизматларни сотиб
        олиш/сотиш, ижарага бериш ва ҳ.к.) оммавий офертаси (таклифи).
      </p>
      <p>
        &bull; товарлар, хизматлар, ишлар - ҳар қандай мулк, шунингдек,
        фойдаланувчи эълон эълон қилган фуқаролик-ҳуқуқий битимнинг объекти
        бўлган ишлар ва хизматлар.
      </p>
      <p>
        &bull; <a href="http://www.topin.uz">www.topin.uz</a> хизмати - Ижрочи
        томонидан <a href="http://www.topin.uz">www.topin.uz</a> сайти ва
        иловалари (IOS ва Android) дан фойдаланган ҳолда тақдим этиладиган ҳар
        қандай пуллик ва бепул хизматлар.
      </p>
      <p>
        &bull; Ҳисоб қайдномаси/аккаунт - фойдаланувчининг
        <a href="http://www.topin.uz">www.topin.uz</a> сайтидаги функционал
        тизимидаги электрон кабинети, у билан
        <a href="http://www.topin.uz">www.topin.uz</a> сайтидаги эълонларини
        бошқариши мумкин.
      </p>
      <p>
        &bull; Рўйхатдан ўтиш - Фойдаланувчи томонидан ушбу Шартномани тузиш
        тўғрисидаги таклифни қабул қилиш ва Фойдаланувчи Сайтнинг тегишли
        шаклларини тўлдириш орқали Сайт хизматларидан фойдаланиш учун керакли
        маълумотларни тақдим этиш тартиби. Сайтда эълон қилинган кўрсатмаларга
        мувофиқ барча босқичлари муваффақиятли якунланган тақдирдагина рўйхатдан
        ўтиш тугалланган ҳисобланади.
      </p>
      <p>
        &bull; Шахсий маълумотлар - бу уларнинг ёрдами билан аниқланган ёки
        аниқланиши мумкин бўлган жисмоний ёки юридик шахс тўғрисидаги
        маълумотлар ёки маълумотлар тўпламидир.
      </p>
      <p>
        1.2. Ижрочи шу билан фойдаланувчиларга
        <a href="http://www.topin.uz">www.topin.uz</a> сайтидан эълон
        жойлаштириш учун хизматларни таклиф қилади.
      </p>
      <p>
        1.3. <a href="http://www.topin.uz">www.topin.uz</a> хизматидан
        фойдаланиш шуни англатадики, Фойдаланувчи ушбу Шартнома билан таниш,
        унинг шартларини тўлиқ ва ҳеч қандай эслатма ва истисноларсиз тушунади
        ва қабул қилади. Агар фойдаланувчи ушбу Шартноманинг бирон бир қоидасига
        рози бўлмаса, фойдаланувчи
        <a href="http://www.topin.uz">www.topin.uz</a> хизматидан фойдаланиш
        ҳуқуқига эга эмас.
      </p>
      <p>
        1.4. Ушбу Шартнома тўлиқ ёки қисман &laquo;TOPIN TECHNICAL
        SYSTEMS&raquo; МЧЖ томонидан исталган вақтда махсус огоҳлантиришсиз
        ўзгартирилиши мумкин. Шартноманинг янги таҳрири Сайтда ва Иловаларда
        (IOS ва Android) эълон қилинган пайтдан бошлаб кучга киради.
      </p>
      <p>
        1.5. Ушбу сайтда жойлашган маълумотлар Сайт маъмуриятининг (IOS ва
        Android) маъмуриятининг ёзма рухсатисиз Сайтнинг функционал
        имкониятларидан бошқача тарзда кўпайтирилиши ёки ишлатилиши мумкин эмас.
        Ушбу Сайтдаги маълумотларни нусхалаш фақат шахсий фойдаланиш учун
        жисмоний шахслар томонидан амалга оширилиши мумкин.
      </p>
      <p><strong>2. ХИЗМАТДАН ФОЙДАЛАНИШ ҚОИДАЛАРИ.</strong></p>
      <br />
      <p>
        2.1. Фойдаланувчи
        <a href="http://www.topin.uz">www.topin.uz</a> веб-сайтида ёки Топин
        дастурларида (IOS ва Android) эълонларни жойлаштириш ҳуқуқини таклиф
        этилаётган товарлар ёки хизматларнинг параметрлари кўрсатилган махсус
        шаклни тўлдиргандан сўнг олади.
      </p>
      <p>
        2.2. <a href="http://www.topin.uz">www.topin.uz</a> веб-сайтига ёки ушбу
        сайтнинг Иловаларига (IOS ва Android) киришда, фойдаланувчи ўзининг
        мобил рақамини кўрсатади ва рўйхатдан ўтишни якунлаш учун керакли
        майдонда кўрсатилиши керак бўлган кодни ўз ичига олган СМС-хабар олади.
        Шунингдек, фойдаланувчи рўйхатдан ўтганидан ва авторизация қилинганидан
        сўнг ҳақиқий электрон почта манзилини, унга фақат фойдаланувчи кириш
        ҳуқуқига эга бўлган телефон рақамини, танланган паролни, шунингдек
        рўйхатдан ўтиш учун зарур бўлган бошқа маълумотларни кўрсатадиган шаклни
        тўлдириш орқали қўшимча хизматларни олиш ҳуқуқига эга.
      </p>
      <p>
        2.3. Фойдаланувчи унга берилган паролнинг махфийлигини сақлаш ва уни
        учинчи шахсларга ошкор қилмаслик мажбуриятини олади.
      </p>
      <p>
        2.4. Фойдаланувчи <a href="http://www.topin.uz">www.topin.uz</a> сайти
        хизматларидан фақат ўзининг электрон почта манзили, телефон рақами ва
        пароли билан фойдаланиш ҳуқуқига эга ва Сайтга кириш учун электрон почта
        манзили, телефон рақами ва паролидан фойдаланган ҳолда барча ҳаракатлар
        учун жавобгардир.
      </p>
      <p>2.5. Сайтда фойдаланувчи фақат битта аккаунтга эга бўлиши мумкин.</p>
      <p>
        2.6. Фойдаланувчиларнинг ўзаро муносабатларни енгиллаштириш учун Ижрочи
        бошқа фойдаланувчиларнинг алоқа маълумотларига чекланган киришни
        ўрнатиши мумкин. Бошқа фойдаланувчилар томонидан тақдим этилган
        маълумотлардан фойдаланиш ҳуқуқи ушбу Шартнома билан чекланган.
      </p>
      <p>
        <strong
          >3. САЙТНИНГ ПУЛЛИ ХИЗМАТЛАРИДАН ФОЙДАЛАНИШ ҚОИДАЛАРИ. ТЎЛОВ
          ТАРТИБИ</strong
        >
      </p>
      <p>3.1. Ижрочи Сайтда қуйидаги пуллик хизматларни тақдим этади:</p>
      <p>&bull; Имтиёзли - эълон премиум эълонлар рўйхатига ўтказилади;</p>
      <p>
        &bull; Ажратиб кўрсатиш - одатий эълонлар рўйхатида эълон ранг билан
        ажратиб кўрсатилади;
      </p>
      <p>
        &bull; Кўтариш - одатий эълонлар рўйхатининг юқори қисмида эълон эълон
        қилинади
      </p>
      <p>
        3.2. Пуллик хизматлардан фақат
        <a href="http://www.topin.uz">www.topin.uz</a> сайтида ёки ушбу сайтнинг
        Иловаларида (IOS ва Android) рўйхатдан ўтганлар, ўзларининг шахсий
        идентификаторлари ва пароллари бўлган фойдаланувчилар фойдаланишлари
        мумкин.
      </p>
      <p>
        3.3. Сайтнинг <a href="http://www.topin.uz">www.topin.uz</a> пуллик
        хизматларини буюртма қилиш (қабул қилиш) Фойдаланувчи томонидан шахсий
        кабинетда, шунингдек Сайтнинг
        <a href="http://www.topin.uz">www.topin.uz</a> бошқа тегишли бўлимларида
        ёки Иловаларда (IOS ва Android) амалга оширилади.
      </p>
      <p>
        3.4. Фойдаланувчи <a href="http://www.topin.uz">www.topin.uz</a> сайт
        хизматининг пуллик хизматлари нархлари билан танишиб чиқиши зарур,
        шундан сўнг у ушбу хизматларга буюртма бериши мумкин. Барча
        хизматларнинг нархи тўғрисида батафсил маълумот "Пуллик хизматлар"
        бўлимида кўрсатилган (http://_____________)
      </p>
      <p>
        3.5. Компаниянинг пуллик хизматлари учун тўлов, "Пулли хизматлар"
        бўлимида (http://_____________) "Балансни тўлдириш" ва фойдаланувчи
        томонидан бонусларни (аванс маблағлари сифатида хизмат қилувчи виртуал
        шартли бирликлар) олиш тартибида амалга оширилади.
      </p>
      <p>
        3.6. Пуллик хизматларнинг нархи, шунингдек, 1 (битта) бонуснинг қиймати
        "Пуллик хизматлар" бўлимида кўрсатилган (http://_____________)
      </p>
      <p>
        3.7. Хизматларни тақдим этиш муддати Фойдаланувчи хизматга буюртма бериш
        (қабул қилиш) пайтида белгиланади.
      </p>
      <p>
        3.8. Сайтда эълонни жойлаштириш муддати тугагандан сўнг, эълонни
        &ldquo;ажратиб кўрсатиш&rdquo;, "кўтариш" ва "Имтиёзли" мақоми бекор
        қилинади. Белгиланган эълон жойлаштириш муддатини узайтирганда,
        ажратилган эълон учун тўловни қайта тўлаш керак.
      </p>
      <p>
        3.9. Эълонни кўтаришда унинг сайтда эълон қилинган санаси ўзгармайди.
        Эълон фақат сарлавҳадаги эълонларнинг умумий рўйхатида юқорига
        кўтарилади.
      </p>
      <p>
        3.10. Кўрсатилмаган хизматлар учун тўланган пулни қайтариш ижрочи
        томонидан фойдаланувчининг ёзма талабига биноан амалга оширилади, бунда
        фойдаланувчи қуйидаги ҳолларда тўловни тасдиқловчи ҳужжатларни тақдим
        этиши шарт:
      </p>
      <p>
        3.10.1. Агар бирон бир техник носозлик сабабли хизмат кўрсатилмаган
        бўлса.
      </p>
      <p>
        3.10.2. Тўловларни келажакда пулли бонуслардан фойдаланиш имконияти
        билан фойдаланувчининг шахсий кабинетида кўрсатиш орқали қайтариш
        мумкин.
      </p>
      <p>
        3.10.3. Ушбу Шартнома талабларига жавоб бермайдиган эълонларни
        жойлаштириш учун тўланган маблағ эълон олиб ташланганида ёки ҳисоб
        блокланганида қайтарилмайди.
      </p>
      <p><strong>4. САЙТДА ЭЪЛОНЛАРНИ ЖОЙЛАШТИРИШ ҚОИДАЛАРИ.</strong></p>
      <br />
      <p>
        4.1. Товарлар, ишлар ёки хизматлар тўғрисидаги эълонларни www.topin.uz
        сайтида ва ушбу сайтнинг иловаларида (IOS ва Android) жойлаштирган
        фойдаланувчи ушбу Шартнома ва Сайтда тақдим этилган қоидаларга мувофиқ
        улар ҳақида маълумот жойлаштиришни ўз зиммасига олади.
      </p>
      <p>
        4.2. Ўзбекистонлик интернетдан фойдаланувчиларнинг эълонлари давлат
        тилида кирилл ёки лотин ёки рус тилида кириллда ёзилган ҳолда нашрга
        қабул қилинади (хорижий тиллардан фақат моделлар, брендлар, компаниялар
        номлари ва ўзбек ёки рус тилларига таржима қилиб бўлмайдиган бошқа
        сўзларни кўрсатганда фойдаланишга рухсат берилади).
      </p>
      <p>
        4.3. Эълон предмети тақиқланмаган ва муомалада чекланмаган, Ўзбекистон
        Республикаси қонунларига мувофиқ товарлар, ишлар ёки хизматлар бўлиши
        мумкин.
      </p>
      <p>
        4.4. Фойдаланувчи маҳсулот, иш ёки хизмат тўғрисидаги маълумотларни
        жойлаштириш орқали ушбу маҳсулотни сотиш, ишларни бажариш ёки ушбу
        хизматни тақдим этиш ҳуқуқини Ўзбекистон Республикаси қонунчилиги
        талабларига мувофиқ тасдиқлайди. Фойдаланувчи ўзи томонидан тақдим
        этилаётган товарлар, ишлар ва хизматлар, уларни амалга ошириш ёки
        бажариш (тақдим этиш) учун сертификатлаш, махсус рухсат ёки лицензиялаш
        зарур бўлган тақдирда ва Ўзбекистон Республикаси қонунчилиги талабларига
        мувофиқ фақат кўрсатилган ҳужжатлар мавжуд бўлганда сотилиши/бажарилиши
        (тақдим этилиши) кафолатланади.
      </p>
      <p>
        4.5. www.topin.uz сайтида эълон жойлаштирган фойдаланувчи сайтда
        кўрсатилган товар, иш ва хизматлар ҳақидаги барча маълумотларни диққат
        билан текшириши ва агар нотўғри маълумотлар топилган бўлса, керакли
        ўзгаришларни киритиши ёки эълонни ўчириб ташлаб, тўғри эълонни қайта
        жойлаштириши шарт.
      </p>
      <p>
        4.6. Ижрочи эълонда кўрсатилган маълумотларнинг тўғрилиги учун жавобгар
        эмас.
      </p>
      <p>
        4.7. Эълонни жойлаштиришда фойдаланувчи қуйидаги талабларни бажариши
        шарт:
      </p>
      <p>
        4.7.1. эълонда жойлаштирилган маълумотлар ушбу Шартнома талабларига ва
        Ўзбекистон Республикаси қонунчилигига зид бўлмаслиги керак;
      </p>
      <p>
        4.7.2. битта аниқ объект учун битта эълон жойлаштиришга рухсат берилади.
        Турли хил электрон почта манзилларидан бир хил эълонларни нашр этишга
        йўл қўйилмайди;
      </p>
      <p>
        4.7.3. эълонлар www.topin.uz сайтининг тегишли функционал созламаларида
        танланган географик ҳудуд ва шаҳарга мос келиши керак
      </p>
      <p>
        4.7.4. эълонлар Сайтнинг тегишли функционал созламаларида танланган
        www.topin.uz (тасниф) бўлимига мос келиши керак;
      </p>
      <p>
        4.7.5. жойлаштирилган эълон сарлавҳаси эълон матнининг ўзига мос келиши
        ва фойдаланувчи ҳақидаги алоқа ёки шахсий маълумотларини (телефон
        рақами, электрон почта манзили, Интернет-ресурс манзили), маҳсулот ёки
        объектнинг аниқ жойлашган жойини (харита), таклиф қилинадиган
        товарлар/ишлар (хизматлар) нархлари, ундов ва савол белгилари, кўплаб
        тиниш белгилари, шунингдек маҳсулот/иш (хизмат) ни таклиф қилаётган
        компаниянинг номи ёки веб-сайт манзили (URL) ўз ичига олмаслиги керак;
      </p>
      <p>
        4.7.6. эълон маҳсулот/иш (хизмат) ҳақида батафсил ва ишончли
        маълумотларни ўз ичига олиши керак;
      </p>
      <p>
        4.7.7. эълонда таклиф мавзусининг нотўғри хусусиятларини, шу жумладан
        маҳсулот ёки хизматнинг ҳақиқий сотиш нархига мос келмайдиган нархни
        кўрсатишга йўл қўйилмайди. Нарх тўлиқ маҳсулот ёки иш (хизмат) учун
        тўлиқ кўрсатилиши керак.
      </p>
      <p>
        4.7.8. бир хил бўлмаган нарсаларга ва предметларга оид маълумотларни
        битта эълонга жойлаштириш тақиқланади. Нотўғри: "Кийим ва пояфзал
        сотаман", "Телевизор ва музлатгич сотаман", "Суратдаги ҳамма нарсани
        сотаман" ва бошқалар. Барчаси умумий нархга тўплам сифатида сотилганда
        битта алоҳида эълон ёки битта эълондаги бир нечта нарсалар тўғрисида
        маълумотларга рухсат берилади.
      </p>
      <p>
        4.7.9. эълонга илова қилинган ва маҳсулот/иш (хизмат) ни намойиш этган
        фотосуратлар эълон сарлавҳаси ва матнига мос келиши, тасвирни аниқ
        ажратиб олишга имкон берадиган сифатли бўлиши керак. Фотосуратда тавсия
        этилган маҳсулот, иш (хизмат) ёки савдо маркаси кўрсатилиши керак.
        Суратда ФАҚАТ алоқа маълумотлари бўлмаслиги керак: телефон рақами,
        электрон почта ва ҳк. Интернетдан юкланган расм ва/ёки фотосуратлар
        тақиқланади;
      </p>
      <p>
        4.7.10. етказиб бериш шартлари товарлар тавсифига, ишларни бажариш
        (хизматлар кўрсатиш) шартлари эса иш (хизмат) тавсифига киритилиши
        керак. Фойдаланувчи томонидан тузилган товарларни сотиш ва ишларни
        бажариш (хизматлар кўрсатиш) шартлари ушбу Шартномага ва Ўзбекистон
        Республикасининг амалдаги қонунчилигига зид бўлмаслиги керак.
      </p>
      <p>
        4.7.11. "Кўчмас мулк" бўлимида одатий аккаунт/фойдаланувчи билан
        индивидуал равишда фойдаланувчи иккитадан кўп бўлмаган фаол эълонларни
        жойлаштириши мумкин. Чекловлар "Риелтор" мақомини олиш учун барча
        керакли ҳужжатларни тақдим этган бизнес-аккаунт/фойдаланувчига, кўчмас
        мулк агентликларига тааллуқли эмас.
      </p>
      <p>
        4.7.12. ишларни бажариш (хизматлар кўрсатиш) бўйича таклифлар билан
        эълон юборишда якка тартибдаги тадбиркорлик фаолияти билан шуғулланувчи
        жисмоний шахс якка тартибдаги тадбиркорнинг давлат рўйхатидан ўтганлиги
        тўғрисидаги гувоҳноманинг рақами ва санасини, шунингдек сертификат
        берган давлат органининг номини кўрсатиши шарт;
      </p>
      <p>
        4.7.13. лицензиялашга тегишли ишларни (хизматларни кўрсатишни) бажариш
        бўйича таклифлар билан эълон юборишда эълон матнида лицензия рақами
        (серияси) ва лицензияни берган давлат органининг номи кўрсатилиши,
        шунингдек эълон таркибидаги фотосуратлар орасида лицензиянинг сканер
        сканерланган расми юкланган бўлиши керак;
      </p>
      <p>
        4.7.14. сертификатлаштириладиган товарларни, ишларни (хизматларни) сотиш
        бўйича таклифлар билан эълон тақдим этишда эълон матнида сертификат
        рақами (серияси) ва сертификат берган давлат органининг номи
        кўрсатилиши, шунингдек, фотосуратлар орасида сертификатнинг сканерланган
        расмини эълон таркибига юклаш шарт.
      </p>
      <p>4.8. Эълон жойлаштиришда қуйидагилар тақиқланади:</p>
      <p>
        &bull; Худди шу эълонларни бир хил телефон рақамидан, электрон почта
        манзилидан нашр этиш;
      </p>
      <p>
        &bull; Таркибига ўхшаш, худди шу таклифга мурожаат қиладиган эълонларни
        нашр этиш;
      </p>
      <p>
        &bull; Турли хил телефон рақамлари, электрон почта манзилларидан бир хил
        эълонларни нусхалаш;
      </p>
      <p>
        &bull; Сайтнинг бўлимида эълон таркибига мос келмайдиган эълонларни
        жойлаш;
      </p>
      <p>
        &bull; Сарлавҳада такрорланган тиниш белгиларини ва/ёки алифбо бўлмаган
        белгиларни ўз ичига олган эълон жойлаштиринг;
      </p>
      <p>
        &bull; бир-бирига боғлиқ бўлмаган, ўқиб бўлмайдиган эълонларни,
        тавсифини ва/ёки сарлавҳасини/фотосуратларини нашр этиш;
      </p>
      <p>
        &bull; Бир вақтнинг ўзида бир нечта маҳсулот ва хизматларни таклиф
        қилувчи эълонларни нашр этиш;
      </p>
      <p>
        &bull; Эълонга зарарли элементлар ёки сайтнинг бош саҳифасига
        ҳаволаларни ўз ичига олган манбаларга ҳаволалар киритиш;
      </p>
      <p>
        &bull; маҳсулот ёки иш (хизмат) тўғрисида эълон жойлаштириш, агар бундай
        жойлаштириш Ўзбекистон Республикаси қонунчилигининг бузилишига олиб
        келиши мумкин бўлса;
      </p>
      <p>
        &bull; Ижрочининг рақобатчилари томонидан тақдим этиладиган хизматлар,
        шу жумладан: бошқа эълонлар тахталари, савдо майдончалари,
        онлайн-ауксионлар ва/ёки интернет-дўконлари тўғрисидаги маълумотларни
        тарқатиш; www.topin.uz сайтида сотилиши тақиқланган товарлар ва
        хизматларни таклиф қилувчи Интернет-ресурслар.
      </p>
      <p>
        4.9. Қуйидаги нарсаларни сотишни эълон қилувчи эълон жойлаштириш
        тақиқланади.
      </p>
      <p>
        муомаладан чекланган ёки олиб қўйилган нарсалар, шу жумладан ушбу
        Шартномада санаб ўтилган нарсалар (ишлар, хизматлар) бундан мустасно:
      </p>
      <p>
        4.9.1. гиёҳвандлик, психотроп моддалар ва уларнинг ўрнини босувчи
        моддалар, шунингдек уларни тайёрлаш учун ишлатиладиган ўсимликлар ва
        ингредиентлар. Галлюциноген ўсимликлар, замбуруғлар ва улардан
        олинадиган маҳсулотлар;
      </p>
      <p>
        4.9.2. инсон аъзолари, донорлик хизматлари (қон/сперма ва шунга ўхшаш
        нарсаларни сотиб олиш), суррогат хизматлари, она сути;
      </p>
      <p>4.9.3. қурол-яроғ, шунингдек улар учун ўқ-дорилар ва аксессуарлар;</p>
      <p>
        4.9.4. браконерлик воситалари ва жиҳозлари (электр таёқчалар, тўрлар ва
        бошқалар);
      </p>
      <p>
        4.9.5. фаол ва пассив ҳимоя воситалари (электр шокерлар, газ баллонлари,
        газли автоматлар, резина ўқли қуроллар, ёруғлик ва шовқин эффектлари,
        сигнализация мосламалари, резина таёқчалар, кишанлар), ҳуқуқни муҳофаза
        қилиш идоралари томонидан фойдаланилувчи фаол ҳимоя қилишнинг махсус
        воситаларига тегишли бўлган кўз ёши ва тирнаш хусусияти берувчи
        дориларни ўз ичига олган махсус ускуналар;
      </p>
      <p>4.9.6. тутун бомбалари, пиротехника ва бошқалар;</p>
      <p>4.9.7. ҳаёт ва соғлиқ учун хавфли бўлган нарсалар;</p>
      <p>4.9.8. ҳар қандай токсик моддалар;</p>
      <p>
        4.9.9. фармацевтика маҳсулотлари, тиббий (доривор) воситалар,
        ветеринария препаратлари, витамин ва биологик фаол қўшимчалар,
        стероидлар, анаболик стероидлар ва прекурсорлар;
      </p>
      <p>4.9.10. косметика;</p>
      <p>4.9.11. хўжалик кимёвий моддалари;</p>
      <p>
        4.9.12. ҳар қандай кучга эга бўлган алкоголли ичимликлар, самолётлар,
        этил спиртини ўз ичига олган маҳсулотлар;
      </p>
      <p>4.9.13. сигареталар, тамаки ва бошқа тамаки маҳсулотлари;</p>
      <p>
        4.9.14. тамаки, тамаки маҳсулотлари ва алкоголли ичимликлар номи, савдо
        белгиси ёки тасвиридан фойдаланган ҳолда товарлар ёки хизматлар;
      </p>
      <p>
        4.9.15. Халқаро Қизил китобга, Ўзбекистон ёки ҳар қандай мамлакат,
        минтақа, вилоятнинг Қизил китобларига киритилган ҳайвонлар ва
        ўсимликлар, шунингдек ҳайвонларнинг қонун билан муҳофаза қилинадиган
        бошқа барча турлари;
      </p>
      <p>
        4.9.16. қонун ҳужжатларида белгиланган тартибда олинган тегишли ишлаб
        чиқарувчи сертификатига эга бўлган маҳсулотда бўлмаган морс туслари, фил
        тишлари ва бошқа шунга ўхшаш нарсалар; ноёб ва йўқолиб кетиш хавфи
        остида бўлган ҳайвонларнинг терилари, шохлари, оёқ-қўллари ва тери
        маҳсулотлари;
      </p>
      <p>
        4.9.17. ишлаб чиқарилмайдиган қимматбаҳо металлар ва қимматбаҳо тошлар;
      </p>
      <p>
        4.9.18. чет эл валютаси ва/ёки бошқа валюта қимматликлари (нумизматик
        мақсадларда яроқсиз банкноталарни сотиш бундан мустасно);
      </p>
      <p>
        4.9.19. виртуал пуллар, виртуал валюталарни топиш ёки ишлаб чиқариш учун
        воситалар, дастурлар, қурилмалар ва уларнинг таркибий қисмлари;
      </p>
      <p>4.9.20. қимматли қоғозлар;</p>
      <p>
        4.9.21. фойдаланувчи сотиш ҳуқуқига эга бўлмаган нарсалар (контрабанда
        моллари, ўғирланган мол-мулк, қидирилаётган мулк ва бошқалар);
      </p>
      <p>
        4.9.22. муаллифлик ҳуқуқи эгасидан ёки ҳуқуқни муҳофаза қилиш
        идораларидан Ижрочига расмий сўров юборилган товарлар ва хизматлар;
      </p>
      <p>4.9.23. озиқ-овқат маҳсулотлари;</p>
      <p>4.9.24. мавжуд бўлмаган товарлар;</p>
      <p>
        4.9.25. шахсий ҳаётни бузадиган, фуқаролар ва юридик шахсларнинг шаъни,
        қадр-қиммати ва ишбилармонлик обрўсига путур етказадиган материаллар;
      </p>
      <p>
        4.9.26. алоқа каналларидан маълумотларни олиб ташлаш учун махсус техник
        воситалар, махфий равишда олишнинг бошқа воситалари
        (микро-минигарнитуралар, асосий фоблар, камералар, ўрнатилган камерали
        кўзойнаклар, соат камералари ва бошқалар); ҳуқуқни муҳофаза қилиш
        органлари фаолияти билан боғлиқ нарсалар (радиоэлектрон ва махсус техник
        воситалар);
      </p>
      <p>
        4.9.27. антирадарлар, радар детекторлари, парда рамкалари, телефонни
        тинглашга қарши воситалар ва шунга ўхшаш техник воситалар;
      </p>
      <p>4.9.28. ҳужжатларсиз транспорт воситалари;</p>
      <p>
        4.9.29. давлат рақамлари (стандарт, шахсийлаштирилган, элита ва шунга
        ўхшашлар) универсал калитлар (кириш эшиги учун, ҳар хил қулфлар учун);
      </p>
      <p>4.9.30. давлат мукофотлари;</p>
      <p>
        4.9.31. ҳақиқий ва амал қилиш муддати тугаган шахсий ҳужжатлар; амалдаги
        давлат гувоҳномалари ва дунёнинг мавжуд бўлган ҳар қандай
        мамлакатларининг ҳужжатлари (паспортлар, ID-карталар, ҳуқуқлар,
        талабалар карталари, баҳолаш китоблари, дипломлар, саёҳат карталари,
        йўлланмалар, рухсатномалар, сертификатлар, лицензиялар ва бошқалар),
        шунингдек ушбу ҳужжатлар;
      </p>
      <p>
        4.9.32. ҳар қандай ҳужжатлар шакллари, хусусан расмий ҳужжатлар
        шакллари, қатъий ҳисобдорлик ҳужжатлари;
      </p>
      <p>
        4.9.33. маълумотлар базалари, шу жумладан шахсий маълумотлар, телефон
        рақамлари;
      </p>
      <p>
        4.9.34. рухсатсиз почта орқали хабар юборишга ёрдам берадиган товарлар
        ва хизматларнинг спам-маълумотлар базалари;
      </p>
      <p>
        4.9.35. давлат, банк ёки тижорат сирларини ўз ичига олган материаллар;
      </p>
      <p>
        4.9.36. тайёр диплом, муддат, магистрлик диссертациялари, шунингдек
        уларни ёзиш бўйича хизматлар;
      </p>
      <p>
        4.9.37. филмлар, мусиқа, ўйинларнинг ноқонуний нусхалари бўлган ҳар
        қандай ахборот ташувчилар;
      </p>
      <p>4.9.38. дастурий таъминот;</p>
      <p>
        4.9.39. сотилиши учинчи шахсларнинг муаллифлик ҳуқуқи ва/ёки турдош
        ҳуқуқларини, савдо белгиларига ёки патентларга бўлган ҳуқуқларини
        бузадиган буюмлар ва материаллар;
      </p>
      <p>
        4.9.40. қонун ва тартиб, ахлоқ ва одоб-ахлоқ асосларига мос келмайдиган
        ҳар қандай нарсалар ва хизматлар;
      </p>
      <p>4.9.41. порнографик ёки шаҳвоний таркиб ёки нарсалар;</p>
      <p>4.9.42. фашистларнинг рамзлари тасвирланган буюмлар.</p>
      <p>
        4.9.43. тарғибот ва/ёки зўравонлик, ирқий нафрат, ноқонуний ҳаракатларга
        чақириш;
      </p>
      <p>
        4.9.44. оммавий акцияларга чақирув (флеш-моблар, митинглар, юришлар ва
        бошқа акциялар);
      </p>
      <p>
        4.9.45. дин билан боғлиқ таклифлар, товарлар/нарсалар, шунингдек ушбу
        мавзу бўйича адабиётлар;
      </p>
      <p>
        4.9.46. миллий, ирқий, диний, жинсий ва бошқа асослар бўйича камситиш;
      </p>
      <p>
        4.9.47. ҳар қандай шаклда моддий ёрдам сўраш ёки таклиф қилиш, шу
        жумладан "Мен ҳомий бўламан", "Мен ҳомий излаяпман", "Мен моддий ёрдам
        кўрсатаман", "Мен моддий ёрдам қидираман", "тижорат" ва шунга ўхшаш
        нарсалар;
      </p>
      <p>
        4.9.48. фирибгарлик ва/ёки товламачиликнинг ҳар қандай воситаларига оид
        тавсифлар ва/ёки изоҳлар;
      </p>
      <p>
        4.9.49. бизнес бўлимидан ташқари франчайзинг, кўп даражали (тармоқ)
        маркетинг (Avon, Oriflame, Amway ва бошқалар) таклифи бўйича
        маълумотлар;
      </p>
      <p>4.9.50. ишга жойлашиш бўйича таклифлар;</p>
      <p>4.9.51. автоуловнинг юриш масофасини орқага қайтариш хизматлари;</p>
      <p>
        4.9.52. дастур фаоллаштирувчилари, компакт-диск тугмачалари, рўйхатдан
        ўтиш рақамлари, оригинал дастурий таъминотсиз таклифлар;
      </p>
      <p>
        4.9.53. гаровларни ўз ичига олган қимор тизимларида ёрдам таклиф қилиш
        билан боғлиқ хизматлар.
      </p>
      <p>
        4.9.54. яширин мавзуларга оид таклифлар, товарлар/нарсалар (севги
        афсунлари, фитналар, фолбинлик, шайтонийлик, маросимлар, жодугарлар,
        сеҳргарлар, руҳшунослар ва бошқалар), шунингдек ушбу мавзу бўйича
        адабиётлар;
      </p>
      <p>
        4.9.55. шахсни ёки одамлар гуруҳини туҳмат қилиш учун ишлатилиши мумкин
        бўлган материаллар;
      </p>
      <p>
        4.9.56. шахсий маълумотлар ёки электрон почта рўйхатлари; мессенжер қайд
        ёзувлари (ICQ, Skype ва шунга ўхшашлар), электрон почта манзиллари,
        ижтимоий тармоқдаги аккаунтлар;
      </p>
      <p>
        4.9.57. ягона мақсади бошқа Интернет-ресурсларни ёки Интернет-дўконларни
        тарғиб қилиш бўлган маълумотлар;
      </p>
      <p>4.9.58. офлайн дўкон эълони;</p>
      <p>
        4.9.59. эротик, порнография, шу жумладан шаҳвоний ёки порнографик
        таркибдаги фотосуратлар;
      </p>
      <p>
        4.9.60. жинсий алоқа хизматлари, фоҳишабозлик, эҳтирос табиатига оид ҳар
        қандай хизматлари, эротик массаж, тана массажи. Шунингдек, яқин (жинсий)
        мақсадга мўлжалланган ҳар қандай товарлар.
      </p>
      <p>
        4.9.61. шубҳали иш таклифлари, шу жумладан Интернетда иш берувчининг
        жисмоний манзили ва тўғридан-тўғри алоқаларини кўрсатмасдан (ҳамма учун
        ишлаш, электрон почтани уйда ишлаш, қўшимчасиз ишлаш ва ҳк).
      </p>
      <p>
        4.9.62. чет эл тунги клубларида иш таклифлари; веб-моделлар; свингер
        клуб хизмат таклифлари; эскорт хизматлари ва бошқалар;
      </p>
      <p>
        4.9.63. бошқа аъзолар, субагентлар, суб-дистрибюторлар ва бошқаларни
        жалб қилишни талаб қиладиган агентлик фаолияти таклифи бўйича
        маълумотлар;
      </p>
      <p>
        4.9.64. танишлар, дўстлик, муносабатлар, оила яратиш ҳақида ҳар қандай
        эълон.
      </p>
      <p>
        4.10. Шунингдек, мазмуни бўлмаган ёки қуйидагиларни ўз ичига олган эълон
        тақиқланади:
      </p>
      <p>
        4.10.1. Дағал ва ҳақоратли шу жумладан, ирқчи ва диний мазмундаги
        сўзлар;
      </p>
      <p>4.10.2. Таржима қилинган маълумотлар;</p>
      <p>
        4.10.3. Ўзбек ва рус тилларидан ташқари ҳар қандай чет тилида ёзилган
        маълумотлар (баъзи ҳолларда инглиз тилига рухсат берилади);
      </p>
      <p>4.10.4. Бузиб кўрсатадиган ва чалғитадиган бир нечта имло хатолари;</p>
      <p>
        4.11. Эълонлар танлов асосида жойлаштирилиши ёки Ижрочи томонидан
        олдиндан модераторлик қилиниши мумкин.
      </p>
      <p>
        4.12. Эълоннинг ушбу Шартномада кўрсатилган талабларга ҳар қандай мос
        келмаслиги Ижрочига эълонни олиб ташлаш ҳуқуқини беради.
      </p>
      <p>
        <strong
          >5. ИЖРОЧИ ВА ФОЙДАЛАНУВЧИНИНГ ҲУҚУҚЛАРИ ВА МАЖБУРИЯТЛАРИ.</strong
        >
      </p>
      <p>5.1. Ижрочининг ҳуқуқлари:</p>
      <p>
        &bull; Фойдаланувчининг эълон матнига имло ва тиниш белгиларига оид,
        эълон таркибининг умумий маъносига таъсир қилмайдиган ўзгартиришлар
        киритиш;
      </p>
      <p>
        &bull; Жойлаштириш учун қулайроқ бўлим аниқланганда, эълонларни Сайтнинг
        бошқа бўлимларига ўтказиш;
      </p>
      <p>
        &bull; танланган бўлимларнинг мавзуларига мос келмаса ёки ушбу
        Шартномани бузса, шунингдек, Сайтдан фойдаланиш сабабларини
        тушунтирмасдан қулайлиги учун битта фойдаланувчи томонидан эълонларнинг
        сонини чеклаб қўйган бўлса, эълонларни нашр этишдан бош тортиш;
      </p>
      <p>
        &bull; муаллифлик ҳуқуқи эгасининг ёки ваколатли давлат органларининг
        илтимосига биноан эълонларни олиб ташлаш;
      </p>
      <p>
        &bull; Унинг фикрига кўра қонун устуворлиги ва жамоат ахлоқи асосларига
        мос келмайдиган эълонларни олиб ташлаш;
      </p>
      <p>
        &bull; Сайт хизматларидан фойдаланишга вақти-вақти билан чекловлар,
        хусусан, эълонларни сақлаш учун максимал кунлар ва уларнинг ҳажмини
        белгилаш;
      </p>
      <p>
        &bull; Исталган вақтда Сайт хизматлари фаолиятини олдиндан
        огоҳлантирмасдан ўзгартириш;
      </p>
      <p>
        &bull; Исталган вақтда фойдаланувчиларга олдиндан хабар бериб, сайт
        хизматларининг ишлашини тўхтатиш. Агар сайт хизматининг пуллик
        хизматининг муддати сайт тугатилишигача тугамаган бўлса, Ижрочи ушбу
        давр учун фойдаланувчига мутаносиб равишда олдиндан тўланган авансни
        қайтариб беради;
      </p>
      <p>
        &bull; Сайтда Фойдаланувчининг фаол эълон сонини чеклаш, шунингдек
        Сайтдаги Фойдаланувчининг ҳаракатларини чеклаш.
      </p>
      <p>
        &bull; Агар фойдаланувчи ушбу Шартнома шартларини бузса, фойдаланувчини
        сайтга кириш ҳуқуқидан маҳрум қилиш. Агар Фойдаланувчига Сайтлар
        қоидалари ва учинчи шахсларнинг ҳуқуқларини бузадиган ҳаракатлар содир
        этилганлиги тўғрисида Ижрочи хабар берган бўлса, бузилиш фактлари
        тасдиқланган ҳисобланади;
      </p>
      <p>
        &bull; Исталган вақтда фойдаланувчи томонидан ушбу Шартнома шартлари
        бузилган тақдирда, фойдаланувчининг ҳисоб қайдномасини ўчириб ташлаш ёки
        ўчириб қўйиш, шунингдек, фойдаланувчини илгари бундай ўчириш тўғрисида
        хабардор қилган барча жойлаштирилган фойдаланувчи эълонларини ўчириб
        ташлаш;
      </p>
      <p>
        &bull; Шартномалар ёки бошқа асослар бўйича Сайтни барча хизматлари ва
        таркиби, шу жумладан Фойдаланувчиларнинг шахсий маълумотлари билан ўз
        ворисига ўтказиш. Бошқа фойдаланувчиларга ушбу трансфер тўғрисида хабар
        бериш амалдаги қонунчилик талабларига мувофиқ амалга оширилади.
      </p>
      <p>
        &bull; Рўйхатдан ўтиш пайтида фойдаланувчидан ўзи томонидан кўрсатилган
        маълумотларни тасдиқлашини талаб қилиш ва шу муносабат билан,
        фойдаланувчининг ихтиёрига биноан, уни тақдим этмаслик, тасдиқловчи
        ҳужжатларни (шу жумладан нусхалари/шахсни тасдиқловчи ҳужжатларнинг
        нусхаларини) талаб қилиш. нотўғри маълумот бериш ... Агар
        фойдаланувчининг ўзи томонидан тақдим этилган ҳужжатларда кўрсатилган
        маълумотлари рўйхатдан ўтиш пайтида ва (ёки) эълон жойлаштириш пайтида
        кўрсатилган маълумотларга мос келмаса, шунингдек рўйхатдан ўтиш пайтида
        кўрсатилган маълумотлар фойдаланувчини идентификациялашга имкон бермаса.
        Фойдаланувчига Сайтни хизматларидан фойдаланувчини олдиндан огоҳлантириш
        билан ёки огоҳлантирмасдан фойдаланиш ҳуқуқини рад этишга ҳақлидир.
      </p>
      <p>5.2. Ижрочи қуйидагиларга мажбур:</p>
      <p>&bull; Сайт хизматларининг тўғри ишлашини таъминлаш;</p>
      <p>
        &bull; фойдаланувчи томонидан тақдим этилган шахсий маълумотларни учинчи
        шахсларга тарқатмаслик, қонунда назарда тутилган ҳоллар бундан мустасно;
      </p>
      <p>5.3. Фойдаланувчининг ҳуқуқлари:</p>
      <p>
        &bull; Тескари алоқа шакли орқали Сайтнинг фаолияти тўғрисида шикоят
        юбориш. Шикоятлар келиб тушган пайтдан бошлаб ёки шикоят моҳияти
        тўғрисида тўлиқ маълумот олган пайтдан бошлаб икки иш куни ичида кўриб
        чиқилади.
      </p>
      <p>5.4. Фойдаланувчи қуйидагиларга мажбур:</p>
      <p>
        &bull; Ижрочининг олдиндан ёзма рухсатисиз Сайтда мавжуд бўлган ҳар
        қандай маълумотни (Фойдаланувчининг ўзи томонидан тақдим этилган
        маълумотлардан ташқари) нусха кўчирмаслик, кўпайтирмаслик,
        ўзгартирмаслик, тарқатмаслик ёки оммага тақдим қилммаслик;
      </p>
      <p>
        &bull; Бошқа фойдаланувчилар томонидан тақдим этилган маълумотни бошқа
        фойдаланувчи томонидан ёзма рухсатисиз ушбу фойдаланувчи билан
        тўғридан-тўғри транзаксия қилишдан ташқари, бошқа мақсадларда
        ишлатмаслик.
      </p>
      <p>5.5. Фойдаланувчига қуйидагилар тақиқланади:</p>
      <p>
        &bull; www.topin.uz сайти модераторларининг ҳаракатларини фақат
        модераторлар билан электрон почта орқали ёзишмаларидан бошқа усулда
        муҳокама қилиш;
      </p>
      <p>
        &bull; www.topin.uz сайтида бошқа фойдаланувчилар номларига ўхшаш
        исмлардан фойдаланиш, уларни тақлид қилиш учун, шунингдек бошқа
        фойдаланувчилар номидан хабар ёзиш.
      </p>
      <p>
        5.6. Сайтнинг мазмуни www.topin.uz дан, шунингдек, хизматларнинг бошқа
        ҳар қандай элементларидан фойдаланиш фақат Сайтнинг у ёки бу хизмати
        томонидан тақдим этиладиган функциялар доирасида амалга оширилади.
        Муаллифлик ҳуқуқи эгасининг олдиндан рухсатисиз www.topin.uz сайтининг
        бирон бир элементидан бошқа усулда фойдаланиш мумкин эмас. Фойдаланиш
        деганда қуйидагилар назарда тутилади: кўпайтириш, нусхалаш, қайта ишлаш,
        ҳар қандай асосда тарқатиш ва ҳк.
      </p>
      <p>
        5.7. Www.topin.uz сайтига юборилган эълон таркибидаги барча маълумотлар,
        фойдаланувчининг шахсий бўлимига кириш учун парол ва ушбу бўлимдаги
        маълумотлар бундан мустасно.
      </p>
      <p>
        5.8. Эълон таркибидаги маълумотларни ўзгартириш учун фойдаланувчига
        кириш фақат фойдаланувчи рўйхатдан ўтиш пайтида танлаган ноёб
        фойдаланувчи номи ва парол асосида амалга оширилади. Фойдаланувчи парол
        хавфсизлиги учун жавобгардир.
      </p>
      <p><strong>6. ЖАВОБГАРЛИК</strong></p>
      <br />
      <p>
        6.1. Сайт www.topin.uz хизматларидан фойдаланган ҳолда, фойдаланувчи ўзи
        томонидан жойлаштирилган эълонларнинг мазмуни учун ўзи жавобгар
        эканлигини тасдиқлайди ва шунингдек, маълумотларни жойлаштириш учун
        барча керакли ҳуқуқларга, лицензияларга, рухсатномаларга,
        сертификатларга эга. Сайтдаги эълон, шу жумладан барча патентлар, савдо
        белгилари, тижорат сири ҳуқуқлари, муаллифлик ҳуқуқлари чекланмаган
        ҳолда ёки эълонда кўрсатилган барча шахсларнинг ўзларининг исмлари ёки
        расмларидан фойдаланишга тегишли ёзма розилиги, лицензияси ёки рухсатига
        эга.
      </p>
      <p>
        6.2. Сайт www.topin.uz хизматларидан фойдаланган ҳолда, фойдаланувчи ўз
        хавфи ва таваккалида сайт ва унинг хизматларидан фойдаланиши, Сайтда
        жойлаштирилган эълонлардан фойдаланиш билан боғлиқ барча хавфларни
        баҳолаши ва ўз зиммасига олишига розилигини тасдиқлайди.
      </p>
      <p>
        6.3. Ижрочи фойдаланувчилар томонидан эълонларда жойлаштирилган
        маълумотларнинг тўғрилигини назорат қила олмайди. Маҳсулот ёки
        хизматнинг сифати, хавфсизлиги, қонунийлиги ва уларнинг тавсифига
        мувофиқлиги, шунингдек, фойдаланувчининг маҳсулот/хизматни сотиш ёки
        сотиб олиш қобилияти Ижрочининг назорати остида эмас.
      </p>
      <p>
        6.4. Ижрочи Фойдаланувчилар ёки унинг томони ўртасидаги битимнинг
        ташкилотчиси/ташаббускори эмас. www.topin.uz сайти ва ушбу сайтнинг
        иловалари (IOS ва Androidи) - бу фойдаланувчиларга ҳар қандай вақтда,
        ҳар қандай жойдан ва ҳар қандай нархда сотиш, қонуний рухсат берилган
        товарлар ва хизматларни сотиш, сотиб олиш учун хабар бериш имкониятини
        берувчи савдо-сотиқ платформаси.
      </p>
      <p>
        6.5. Фойдаланувчилар ўзларининг контрагентлари вояга етмаган ёки бошқа
        шахсни тақлид қилиши мумкинлигидан эҳтиёт бўлишлари ва хабардор
        бўлишлари керак. Фойдаланувчи ҳар қандай маҳсулотни, ишни ёки хизматни
        сотиш ёки сотиб олиш бўйича таклиф Сайтда эълонни жойлаштириш фактига
        асосланиб қонуний деб хулоса қила олмайди.
      </p>
      <p>6.6. Ижрочи қуйидагилар учун жавобгар эмас:</p>
      <p>&bull; Сайтда жойлаштирилган эълонларнинг таркиби учун;</p>
      <p>
        &bull; www.topin.uz сайтида жойлаштирилган эълонлардан фойдаланиш
        натижасида юзага келадиган ҳар қандай йўқотиш ва зарарлар учун;
      </p>
      <p>
        &bull; www.topin.uz сайти фойдаланувчилари ўртасида ўтказилган битим ёки
        битим қатнашчиларидан бирининг нотўғри хатти-ҳаракатлари натижасида
        етказилган зарар учун.
      </p>
      <p>
        &bull; Сайт серверларига рухсатсиз кириш ёки улардан рухсатсиз
        фойдаланиш учун ва/ёки уларда сақланган фойдаланувчилар тўғрисидаги ҳар
        қандай маълумот, шунингдек, сайтга ёки Сайт орқали учдан бирига
        ўтказилиши мумкин бўлган ҳар қандай хатолар, вируслар ва бошқалар учун.
      </p>
      <p>
        &bull; форс-мажор ҳолатлари (масалан, расмийларнинг қарорлари, умумий
        алоқа тизимидаги узилишлар ва ҳ.к.) сабабли Сайтга киришни таъминлаш
        бўйича мажбуриятларни бажармаганлик ёки бажаришдаги қийинчиликлар учун.
      </p>
      <p>
        &bull; Ускуналар ва дастурий таъминотнинг ишлашидаги техник узилишлар
        натижасида келиб чиққан Сайт ишидаги носозликлар учун.
      </p>
      <p>
        6.7. Фойдаланувчилар ўртасидаги барча низолар ва баҳсларни улар мустақил
        равишда Ижрочининг иштирокисиз ҳал қилади. Агар фойдаланувчи Сайт
        хизматларидан иккинчиси фойдаланиши натижасида бошқа фойдаланувчига
        қарши даъволар бўлса, фойдаланувчи ушбу талабларни Ижрочини жалб
        қилмасдан ўз-ўзидан тақдим этишга розилик беради.
      </p>
      <p>
        6.8. Фойдаланувчи Ижрочини бошқа фойдаланувчи томонидан Сайтдаги
        ҳаволадан фойдаланган ҳолда (http://________/) ўз ҳуқуқларини бузганлиги
        тўғрисида хабардор қилишга ҳақлидир. Агар фойдаланувчининг шикоятлари
        асосли бўлса, ижрочи фойдаланувчи ҳуқуқларини бузадиган эълонни олиб
        ташлайди.
      </p>
      <p><strong>7. ШАРТНОМАНИНГ АМАЛ ҚИЛИШ МУДДАТИ</strong></p>
      <br />
      <p>
        7.1. Ушбу Шартнома Фойдаланувчи Сайтнинг ҳар қандай хизматидан
        фойдаланишни бошлаган пайтдан ёки Фойдаланувчи www.topin.uz сайтида
        рўйхатдан ўтган пайтдан бошлаб кучга киради ва амал қилиш муддати
        чексиздир.
      </p>
      <p>
        7.2. Фойдаланувчи Сайтда рўйхатдан ўтишни ижрочини олдиндан
        огоҳлантирмасдан ва сабабларини тушунтирмасдан бир томонлама равишда
        бекор қилишга ҳақлидир.
      </p>
      <p>
        7.3. Агар Ижрочи ушбу Шартномада белгиланган тартибда Шартномага бирон
        бир ўзгартириш киритган бўлса ва Фойдаланувчи улар билан рози бўлмаса, у
        Сайт www.topin.uz хизматидан фойдаланишни тўхтатиши шарт. Сайтдан
        фойдаланишни давом эттириш учун асос Шартноманинг тегишли нашри билан
        Фойдаланувчининг розилигини тасдиқлашдир.
      </p>
      <p><strong>8. БОШҚА ШАРТЛАР</strong></p>
      <br />
      <p>
        8.1. Фойдаланувчининг эълонлари билан боғлиқ бўлган сайт тизимидаги
        хабарлар Сайтга эълон жойлаштириш пайтида фойдаланувчи томонидан тақдим
        этилган электрон почта манзилига етказилади. Агар фойдаланувчи бундай
        хабарларни олишни истамаса, фойдаланувчи www.topin.uz сайтидан тегишли
        эълонларни олиб ташлашга ҳақлидир.
      </p>
      <p>
        8.2. Фойдаланувчиларнинг кенг доираси учун мўлжалланган маълумотли
        хабарлар Сайтда эълон қилинади ва/ёки тегишли Сайтда эълонларни нашр
        этиш/рўйхатдан ўтиш жараёнида бундай хабарларни олишга розилигини
        тасдиқлаган фойдаланувчиларнинг электрон почта манзилларига
        юборилади/www.topin.uz. Фойдаланувчилар ўзларининг электрон почта
        манзилларига исталган вақтда фойдаланувчи ҳисобидаги "Почта хабаридан
        обунани бекор қилиш" функсияси орқали ахборот хабарларини олишдан бош
        тортиш ҳуқуқига эга.
      </p>
      <p>
        8.3. Фойдаланувчи ва Ижрочи ўртасида музокаралар йўли билан ҳал
        этилмайдиган барча тортишувлар ва келишмовчиликлар Ижрочининг жойлашган
        жойида судда кўриб чиқилади.
      </p>
      <p>
        8.4. Суд томонидан ушбу Шартноманинг бирон бир қоидасини ҳақиқий эмас
        ёки ижро этилиши мумкин эмас деб тан олиш ушбу Шартноманинг бошқа
        қоидаларининг ҳақиқий эмаслиги ёки бажарилмаслигига олиб келмайди.
      </p>
      <p>
        8.5. Ушбу Шартнома Ўзбекистон Республикаси қонунчилигига мувофиқ
        тартибга солинади ва шарҳланади. Ушбу Шартнома билан тартибга солинмаган
        масалалар Ўзбекистоннинг амалдаги қонунчилигига мувофиқ ҳал қилинади.
        Ушбу Шартнома билан тартибга солинадиган муносабатлардан келиб чиқадиган
        барча мумкин бўлган низолар, Ўзбекистон Республикаси қонунчилиги
        нормаларига мувофиқ, Ўзбекистон қонунчилигида белгиланган тартибда ҳал
        этилади.
      </p>
    </div>
    <div v-else>
      <p><strong>ПОЛЬЗОВАТЕЛЬСКОЕ СОГЛАШЕНИЕ</strong></p>
      <p>
        Сайт <a href="http://www.topin.uz">www.topin.uz</a> является
        собственностью OOO &laquo;TOPIN TECHNICAL SYSTEMS&raquo; и представляет
        собой коммуникационную платформу для размещения временных объявлений.
      </p>
      <p>
        Настоящее Пользовательское соглашение (далее &mdash; Соглашение)
        является публичной офертой OOO &laquo;TOPIN TECHNICAL SYSTEMS&raquo; на
        оказание услуг по размещению объявлений на сайте
        <a href="http://www.topin.uz">www.topin.uz</a> и приложениях (IOS,
        Android). Соглашением определяются условия и правила использования
        пользователями сети сайта <a href="http://www.topin.uz">www.topin.uz</a>
      </p>
      <p>
        В соответствии со статьями 367-375 Гражданского Кодекса Республики
        Узбекистан, в случае принятия (акцепта) изложенных ниже условий
        публичной оферты любое дееспособное физическое или юридическое лицо
        (далее Пользователь) обязуется выполнять условия этого Соглашения.
      </p>
      <p><strong>1. ОБЩИЕ ПОЛОЖЕНИЯ.</strong></p>
      <p>
        1.1 В настоящем Соглашении, если контекст не предполагает иного,
        нижеприведенные термины имеют следующие значения:
      </p>
      <ul>
        <li>
          Сайт &ndash; интернет-сайт
          <a href="http://www.topin.uz">www.topin.uz</a> ответственность за
          администрирование которого несет Исполнитель
        </li>
        <li>
          Приложения (Topin) &ndash; (IOS, Android) мобильная&nbsp;<a
            href="https://ru.wikipedia.org/wiki/%25D0%259E%25D0%25BF%25D0%25B5%25D1%2580%25D0%25B0%25D1%2586%25D0%25B8%25D0%25BE%25D0%25BD%25D0%25BD%25D0%25B0%25D1%258F_%25D1%2581%25D0%25B8%25D1%2581%25D1%2582%25D0%25B5%25D0%25BC%25D0%25B0"
            >операционная система</a
          >для&nbsp;<a
            href="https://ru.wikipedia.org/wiki/%25D0%25A1%25D0%25BC%25D0%25B0%25D1%2580%25D1%2582%25D1%2584%25D0%25BE%25D0%25BD"
            >смартфонов</a
          >,&nbsp;<a
            href="https://ru.wikipedia.org/wiki/%25D0%259F%25D0%25BB%25D0%25B0%25D0%25BD%25D1%2588%25D0%25B5%25D1%2582%25D0%25BD%25D1%258B%25D0%25B9_%25D0%25BA%25D0%25BE%25D0%25BC%25D0%25BF%25D1%258C%25D1%258E%25D1%2582%25D0%25B5%25D1%2580"
            >электронных планшетов</a
          >,&nbsp;<a
            href="https://ru.wikipedia.org/wiki/%25D0%259F%25D0%25BE%25D1%2580%25D1%2582%25D0%25B0%25D1%2582%25D0%25B8%25D0%25B2%25D0%25BD%25D1%258B%25D0%25B9_%25D0%25BC%25D1%2583%25D0%25BB%25D1%258C%25D1%2582%25D0%25B8%25D0%25BC%25D0%25B5%25D0%25B4%25D0%25B8%25D0%25B9%25D0%25BD%25D1%258B%25D0%25B9_%25D0%25BF%25D1%2580%25D0%25BE%25D0%25B8%25D0%25B3%25D1%2580%25D1%258B%25D0%25B2%25D0%25B0%25D1%2582%25D0%25B5%25D0%25BB%25D1%258C"
            >носимых проигрывателей</a
          >&nbsp;и некоторых других устройств.
        </li>
        <li>
          Исполнитель &ndash; Общество с ограниченной ответственностью
          &laquo;TOPIN TECHNICAL SYSTEMS&raquo;, юридический адрес: г. Ташкент,
          Яшнабадский район, ул. Янгилик, дом 37.
        </li>
        <li>
          Пользователь &ndash; любое дееспособное физическое или юридическое
          лицо, принявшее условия данного Соглашения и пользующееся услугами
          Исполнителя;
        </li>
        <li>
          Объявление &ndash; публичная оферта (предложение) пользователя
          неограниченному кругу других пользователей заключить
          гражданско-правовую сделку (о покупке/продаже, аренде и так далее
          товаров, оказании услуг и др.)
        </li>
        <li>
          Товар, услуга, работа &ndash; любое имущество, а также работы и
          услуги, являющиеся объектом гражданско - правовой сделки, о заключении
          которой Пользователь разместил объявление.
        </li>
        <li>
          Сервис <a href="http://www.topin.uz">www.topin.uz</a> &ndash; любые
          платные и бесплатные услуги, оказываемые Исполнителем при помощи Сайта
          <a href="http://www.topin.uz">www.topin.uz</a> и приложения (IOS и
          Android).
        </li>
        <li>
          Учетная запись/аккаунт &ndash; электронный кабинет Пользователя в
          функциональной системе Сайта
          <a href="http://www.topin.uz">www.topin.uz</a> с помощью которого он
          может управлять своими объявлениями на Сайте
          <a href="http://www.topin.uz">www.topin.uz</a>.
        </li>
        <li>
          Регистрация &ndash; принятие Пользователем оферты на заключение
          данного Соглашения и процедура, в ходе которой Пользователь
          посредством заполнения соответствующих форм Сайта предоставляет
          необходимую информацию для использования сервисов Сайта. Регистрация
          считается завершенной только в случае успешного прохождения всех ее
          этапов в соответствии с опубликованными на Сайте инструкциями.
        </li>
        <li>
          Персональные данные - это сведения или совокупность сведений о
          физическом и юридическом лице, которое при их помощи идентифицировано
          или может быть конкретно идентифицировано.
        </li>
      </ul>
      <p>
        1.2. Настоящим Исполнитель предлагает Пользователям услуги по
        использованию Сайта <a href="http://www.topin.uz">www.topin.uz</a>для
        размещения объявлений.
      </p>
      <p>
        1.3. Использование Сервиса
        <a href="http://www.topin.uz">www.topin.uz</a> означает, что
        Пользователь ознакомлен с настоящим Соглашением, понимает и принимает
        его условия в полном объёме, без оговорок и исключений. В случае
        несогласия Пользователя с какими-либо из положений настоящего
        Соглашения, Пользователь не вправе использовать Сервис
        <a href="http://www.topin.uz">www.topin.uz</a>
      </p>
      <p>
        1.4. Настоящее Соглашение полностью или в части может быть изменено OOO
        &laquo;TOPIN TECHNICAL SYSTEMS&raquo; в любое время без какого-либо
        специального уведомления. Новая редакция Соглашения вступает в силу с
        момента ее опубликования на Сайте и Приложениях (IOSи Androd).
      </p>
      <p>
        1.5. Информация, содержащаяся на данном сайте, не может быть
        воспроизведена или использована иным образом, чем в предусмотрено
        функционалом Сайта, без письменного разрешения администрации Сайта и
        Приложениях (IOSи Androd). Копирование информации, содержащейся на
        данном Сайте, может осуществляться только физическими лицами для личного
        пользования.
      </p>
      <p><strong>2. ПРАВИЛА ИСПОЛЬЗОВАНИЯ СЕРВИСА.</strong></p>
      <p>
        2.1. Пользователь получает право размещать объявления на Сайте
        <a href="http://www.topin.uz">www.topin.uz</a>или в приложениях Topin
        (IOS и Android) после заполнения специальной формы с указанием
        параметров предлагаемых товаров или услуг.
      </p>
      <p>
        2.2. При входе на сайт
        <a href="http://www.topin.uz">www.topin.uz</a> или в Приложения (IOS и
        Android) данного сайта, Пользователь выводит свой мобильный номер и
        получает СМС-сообщение, содержащее код, который необходимо указать в
        нужном поле для завершения регистрации. Пользователь также имеет право
        получения дополнительных сервисов после регистрации и авторизации,
        заполнив формуляр с указанием действительного e-mail адреса, номера
        телефона, к которому имеет доступ только Пользователь, выбранного
        пароля, а также других данных, необходимых для регистрации.
      </p>
      <p>
        2.3. Пользователь обязуется сохранять конфиденциальность переданного ему
        пароля и не раскрывать его третьим лицам.
      </p>
      <p>
        2.4. Пользователь имеет право пользоваться сервисами Сайта
        <a href="http://www.topin.uz">www.topin.uz</a> только при помощи
        собственного e-mail адреса, номера телефона и пароля и несет
        ответственность за все действия с использованием его электронного
        адреса, телефонного номера и пароля для входа на Сайт.
      </p>
      <p>2.5. У Пользователя может быть только один аккаунт на сайте.</p>
      <p>
        2.6. Чтобы облегчить взаимодействие между Пользователями, Исполнитель
        может устанавливать ограниченный доступ к контактной информации других
        пользователей. Право использования сведений, предоставленных другими
        Пользователями, ограничивается настоящим Соглашением.
      </p>
      <p>
        <strong
          >3. ПРАВИЛА ПОЛЬЗОВАНИЯ ПЛАТНЫМИ УСЛУГАМИ САЙТА. ПОРЯДОК
          ОПЛАТЫ</strong
        >
      </p>
      <p>
        3.1. Исполнителем предоставляются на Сайте следующие платные услуги:
      </p>
      <ul>
        <li>
          Премирование - объявление будет перенесено в список премиум
          объявлений;
        </li>
        <li>
          Выделение - объявление будет выделено цветом в списке обычных
          объявлений;
        </li>
        <li>
          Поднятие - объявление будет поднято наверх в списке обычных объявлений
        </li>
      </ul>
      <p>
        3.2. Платные услуги доступны только зарегистрированным на Сайте
        <a href="http://www.topin.uz">www.topin.uz</a> или в Приложениях (IOS и
        Android) данного сайта, пользователям, имеющим свой персональный ID и
        пароль.
      </p>
      <p>
        3.3. Заказ (акцептирование) платных услуг Сайта
        <a href="http://www.topin.uz">www.topin.uz</a> осуществляется
        Пользователем в личной учетной записи, а также в любых других
        соответствующих разделах Сайта
        <a href="http://www.topin.uz">www.topin.uz</a> или в Приложениях (IOS и
        Android) данного сайта.
      </p>
      <p>
        3.4. Пользователь обязуется ознакомиться с ценами на платные услуги
        Сервиса Сайта <a href="http://www.topin.uz">www.topin.uz</a> после чего
        может заказать такие услуги. Подробная информация о стоимости всех услуг
        указана в разделе &ldquo;Платные услуги&rdquo; (http://_____________)
      </p>
      <p>
        3.5. Оплата платных услуг Компании осуществляется в порядке, в разделе
        &ldquo;Платные услуги&rdquo; (http://_____________) путем
        &laquo;Пополнить баланс&raquo; и приобретения Пользователем бонусов
        (виртуальных условных единиц, которые служат в качестве авансовых
        средств).
      </p>
      <p>
        3.6. Стоимость платных услуг а так же стоимость 1 (одного) бонуса
        указываются в разделе &ldquo;Платные услуги&rdquo;
        (http://_____________)
      </p>
      <p>
        3.7. Срок оказания услуг определяется Пользователем при заказе (акцепте)
        услуги.
      </p>
      <p>
        3.8. По истечении срока размещения объявления на сайте прекращается
        действие услуги по выделению, &laquo;поднятию&raquo; и статуса
        &laquo;Премиум&raquo; объявления. При продлении срока размещения
        выделенного объявления необходимо осуществление повторной оплаты за
        выделение объявления.
      </p>
      <p>
        3.9. При поднятии объявления, дата его публикация на сайте не
        изменяется. Объявление поднимается наверх только в общем списке
        объявлений в рубрике.
      </p>
      <p>
        3.10. Возврат оплаченных денежных средств за не оказанные услуги
        осуществляется Исполнителем по письменному требованию Пользователя при
        условии предоставления Пользователем доказательств оплаты в следующих
        случаях:
      </p>
      <p>
        3.10.1. Если по причине любой технической неполадки услуга не
        предоставлялась.
      </p>
      <p>
        3.10.2. Возврат денежных средств может осуществляться путем отображения
        в персональном кабинете Пользователя с возможностью использования
        оплаченных бонусов в будущем.
      </p>
      <p>
        3.10.3. Денежные средства, оплаченные за размещение объявлений,
        несоответствующих требованиям, предъявляемым настоящим Соглашением, при
        удалении объявления или блокировании учетной записи не возвращаются.
      </p>
      <p><strong>4. ПРАВИЛА РАЗМЕЩЕНИЯ НА САЙТЕ ОБЪЯВЛЕНИЙ.</strong></p>
      <p>
        4.1. Пользователь, размещающий объявления о товарах, работах или услугах
        на Сайте <a href="http://www.topin.uz">www.topin.uz</a> и в приложениях
        (IOS и Android) данного сайта, обязуется разместить информацию о них в
        соответствии настоящим Соглашением и правилами, представленными на
        Сайте.
      </p>
      <p>
        4.2. К публикации принимаются объявления от пользователей Интернета из
        Узбекистана, написанные на государственном языке кириллицей или
        латиницей или на русском языке кириллицей (допускается использовать
        иностранные языки только при указании названий моделей, брэндов,
        компаний и других слов, непереводимых на узбекский или русский язык).
      </p>
      <p>
        4.3. Предметом объявлений могут быть товары, работы или услуги, не
        запрещенные и не ограниченные в обороте, в соответствии с
        законодательством Республики Узбекистан.
      </p>
      <p>
        4.4. Размещая информацию о товаре, работе или услуге, Пользователь
        подтверждает, что он имеет право продавать этот товар, выполнять работу
        или оказывать данную услугу в соответствии с требованиями
        законодательства Республики Узбекистан. Пользователь гарантирует, что
        предлагаемые им товары, работы и услуги, в случае, если их реализация
        или выполнение (оказание) требует сертификации, специального разрешения
        или лицензирования, будут реализовываться/выполняться (оказываться)
        только при наличии указанных документов и в соответствии с требованиями
        законодательства Республики Узбекистан.
      </p>
      <p>
        4.5. Пользователь, размещая объявление на Сайте
        <a href="http://www.topin.uz">www.topin.uz</a>, обязан тщательно
        проверить всю информацию о товарах, работах и услугах, указанных им на
        Сайте, и, в случае обнаружения неверной информации, внести необходимые
        изменения либо, удалив объявление, разместить повторно верное
        объявление.
      </p>
      <p>
        4.6. Исполнитель не несёт ответственности за достоверность информации,
        указанной в объявлении.
      </p>
      <p>
        4.7. При размещении объявления, Пользователь обязан соблюдать следующие
        требования:
      </p>
      <ol>
        <ol>
          <ol>
            <li>
              информация, размещаемая в объявлении не должна противоречить
              требованиям настоящего Соглашения и законодательству Республики
              Узбекистан;
            </li>
            <li>
              разрешается разместить одно объявление касательно одного
              конкретного объекта. Публикация одинаковых объявлений с разных
              E-mail адресов не допускается;
            </li>
            <li>
              объявления должны соответствовать географической области и городу,
              выбранной в соответствующих функциональных настройках Сайта
              <a href="http://www.topin.uz">www.topin.uz</a>
            </li>
            <li>
              объявления должны соответствовать разделу Сайта
              <a href="http://www.topin.uz">www.topin.uz</a> (классификации),
              выбранной в соответствующих функциональных настройках Сайта
              <a href="http://www.topin.uz">www.topin.uz</a>;
            </li>
            <li>
              заголовок размещаемого объявления должен соответствовать тексту
              самого объявления и не должен содержать контактную или
              персональную информацию о Пользователе (телефон, электронные
              адрес, адрес интернет &ndash; ресурса), точное местонахождения
              товара или объекта (Указать на карте), цены предлагаемых
              товара/работы (услуги), восклицательные и вопросительные знаки,
              многочисленные знаки пунктуации, а также названия или адреса сайта
              (URL) компании, предлагающей товар/работу (услугу);
            </li>
            <li>
              объявление должно содержать подробную и достоверную информацию о
              товаре/ работе (услуге);
            </li>
            <li>
              не допускается указание некорректных характеристик предмета
              предложения в объявлении, в том числе указание цены, не
              соответствующей фактической цене реализации товара или услуги.
              Цена должна быть указана полностью за весь товар или работу
              (услугу).
            </li>
            <li>
              запрещается размещать в одном объявлении информацию о разнородных
              вещах и предметах. Некорректно: &laquo;Продаю платье и
              туфли&raquo;, &laquo;Продам телевизор и холодильник&raquo;,
              &laquo;Продаю все, что на фото&raquo; и подобное. Допускается
              информация об одном предмете в одном отдельном объявлении или
              несколько предметов в одном объявлении, когда все они продаются
              комплектом за общую цену.
            </li>
            <li>
              фото, прикреплённые к объявлению и демонстрирующее товар/работу
              (услугу) должно соответствовать заголовку и тексту объявления,
              должна быть хорошего качества, позволяющего четко различать
              изображение. На фото должен быть изображен предлагаемый товар,
              работа (услуга) или товарный знак. Фото не должно содержать ТОЛЬКО
              контактную информацию: телефон, E-mail и т.д. Стоковые фото и/или
              фото, загруженные из сети Интернет, запрещены;
            </li>
            <li>
              условия доставки должны включаться в описание товара, а условия
              выполнения работ (оказания услуг) в описание работы (услуги).
              Условия продажи товара и выполнения работ (оказания услуг),
              составленные Пользователем, не должны противоречить настоящему
              Соглашению и действующему законодательству РУ.
            </li>
            <li>
              При обычной аккаунте/пользователя в разделе
              &laquo;Недвижимость&raquo;, пользователь в качестве физического
              лица может размещать не более 2-х активных объявлений. Ограничения
              не касаются Бизнес аккаунта/пользователя, агентств недвижимости,
              которые предоставили все необходимые документы для получения
              статуса &laquo;Риелтор&raquo;.
            </li>
            <li>
              при подаче объявления с предложениями выполнения работ (оказания
              услуг) физическое лицо, осуществляющее индивидуальную
              предпринимательскую деятельность, обязано указать номер и дату
              Свидетельства о государственной регистрации индивидуального
              предпринимателя, а также наименование государственного органа,
              выдавшего свидетельство;
            </li>
            <li>
              при подаче объявления с предложениями выполнения работ (оказания
              услуг), подлежащих лицензированию, в тексте объявления требуется
              указывать номер (серию) лицензии и наименование государственного
              органа, выдавшего лицензию, а также в обязательном порядке
              загружать среди фото к объявлению скан-изображение лицензии;
            </li>
            <li>
              при подаче объявления с предложениями реализации товаров, работ
              (услуг), подлежащих сертификации, в тексте объявления требуется
              указывать номер (серию) сертификата и наименование
              государственного органа, выдавшего сертификат, а также в
              обязательном порядке загружать среди фото к объявлению
              скан-изображение сертификата.
            </li>
          </ol>
        </ol>
      </ol>
      <p>4.8. При размещении объявлений запрещается:</p>
      <ul>
        <li>
          Публиковать одинаковые объявления с одного и того же номера телефона,
          e-mail адреса;
        </li>
        <li>
          Публиковать схожие по содержанию объявления, в которых речь идет об
          одном и том же предложении;
        </li>
        <li>
          Дублировать одинаковые объявления с разных телефонных номеров, e-mail
          адресов;
        </li>
        <li>
          Публиковать объявления в разделе Сайта, который не соответствует
          содержанию объявления;
        </li>
        <li>
          Публиковать объявления, в заголовке которых содержатся повторяющиеся
          знаки пунктуации и/или небуквенные символы;
        </li>
        <li>
          Публиковать объявления, описание и/или заголовок/фотографии которых
          являются несвязанными, нечитаемыми;
        </li>
        <li>
          Публиковать объявления с предложением нескольких товаров и услуг
          одновременно;
        </li>
        <li>
          Вставлять в объявлении ссылки на ресурсы, которые содержат вредоносные
          элементы либо ссылки на главную страницу сайта;
        </li>
        <li>
          Размещать объявление о товаре или работе (услуге), если такое
          размещение может привести к нарушению законодательства Республики
          Узбекистан;
        </li>
        <li>
          распространять информацию об услугах, предоставляемых конкурентами
          Исполнителя, в том числе: информацию о других досках объявлений,
          торговых площадках, интернет-аукционах и/или интернет-магазинах;
          интернет - ресурсах, предлагающих товары и услуги, запрещённые к
          продаже на Сайте <a href="http://www.topin.uz">www.topin.uz</a>.
        </li>
      </ul>
      <p>4.9. Запрещается размещать объявления, рекламирующие продажу:</p>
      <p>
        вещей, ограниченных или изъятых из оборота, в том числе, но не исключая
        вещи (работы, услуги), перечисленные настоящим Соглашением:
      </p>
      <ol>
        <ol>
          <ol>
            <li>
              наркотических, психотропных веществ и их заменителей, а также
              растений и ингредиентов, используемых для их приготовления.
              Галлюциногенных растений, грибов и производных из них продуктов;
            </li>
            <li>
              человеческих органов, донорских услуг (сдам/куплю кровь/сперму и
              подобное), услуг суррогатного материнства, грудного молока;
            </li>
            <li>оружия, а также боеприпасов и комплектующих к ним;</li>
            <li>
              браконьерских инструментов и оборудования (электро-удочки, сети и
              подобное);
            </li>
            <li>
              средств активной и пассивной защиты (электро-шокеры, газовые
              баллончики, газовые пистолеты, оружие с резиновыми пулями,
              свето-шумовые эффекты, сигнализаторы, резиновые дубинки,
              наручники), спецсредства, содержащие препараты слезоточивого и
              раздражающего действия, которые относятся к специальным средствам
              активной обороны, применяемым правоохранительными органами;
            </li>
            <li>дымовых шашек, пиротехники и т.п.;</li>
            <li>предметов, представляющих опасность для жизни и здоровья;</li>
            <li>любых отравляющих веществ;</li>
            <li>
              фармацевтической продукции, медицинских (лекарственных)
              препаратов, ветеринарных препаратов, витаминных и биологически
              активных добавок, стероидов, анаболиков и прекурсоров;
            </li>
            <li>косметических средств;</li>
            <li>средств бытовой химии;</li>
            <li>
              алкогольных напитков любой крепости, самогонных аппаратов,
              продукции, содержащей этиловый спирт;
            </li>
            <li>сигарет, табака и иной табачной продукции;</li>
            <li>
              товаров или услуг, с использованием наименования, товарного знака
              или изображения табака, табачных изделий и алкогольных напитков;
            </li>
            <li>
              животных и растений, занесенных в Международную Красную Книгу,
              Красную Книгу Узбекистана или какой-либо страны, региона, области,
              а также всех иных видов животных, охраняемых законом;
            </li>
            <li>
              клыков моржа, бивней слона и других подобных вещей не в изделии с
              соответствующим сертификатом производителя, полученным в
              установленном законом порядке; шкур и изделий из шкур, рогов,
              конечностей и чучел редких и находящихся под угрозой исчезновения
              видов животных;
            </li>
            <li>драгоценных металлов и драгоценных камней не в изделии;</li>
            <li>
              иностранной валюты и/или иных валютных ценностей (за исключением
              продажи недействующих денежных знаков для нумизматических целей);
            </li>
            <li>
              виртуальных денег, инструментов, программ, приборов и их
              комплектующих для заработка или генерации виртуальных валют;
            </li>
            <li>ценных бумаг;</li>
            <li>
              предметов, продавать которые Пользователь не имеет права
              (контрабандные товары, краденое имущество, имущество находящееся в
              розыске и так далее);
            </li>
            <li>
              товаров и услуг, по которым в адрес Исполнителя был направлен
              официальный запрос от правообладателя или правоохранительных
              органов;
            </li>
            <li>продуктов питания;</li>
            <li>несуществующих товаров;</li>
            <li>
              материалы, нарушающие тайну частной жизни, посягающие на честь,
              достоинство и деловую репутацию граждан и юридических лиц;
            </li>
            <li>
              специальных технических средств для снятия информации с каналов
              связи, другие средства негласного получения информации
              (микро-наушники, брелки-камеры, очки со встроенной камерой,
              часы-камера и подобное); предметов, связанных с деятельностью
              правоохранительных органов (радиоэлектронные и специальные
              технические средства);
            </li>
            <li>
              антирадаров, радаров-детекторов, рамки-шторки, анти-прослушки,
              антижучки и подобные технические приспособления;
            </li>
            <li>транспортных средств без документов;</li>
            <li>
              номерных знаков (стандартных, именных, элитных и подобное)
              универсальных ключей (для входной двери, для различных замков);
            </li>
            <li>государственных наград;</li>
            <li>
              персональных документов, как действующих, так и истекшим сроком
              действия; имеющие силу государственные удостоверения личности и
              документы любых существующих стран мира (паспорта, id-карты,
              права, студенческие билеты, зачетные книжки, дипломы, проездные
              билеты, пропуска, разрешения, сертификаты, лицензии и подобное), а
              также бланки этих документов;
            </li>
            <li>
              бланков любых документов, в частности бланки официальных
              документов, документов строгой отчетности;
            </li>
            <li>
              баз данных, в том числе содержащих персональные данные, номера
              телефонов;
            </li>
            <li>
              спамерских баз товаров и услуг, которые могут способствовать
              несанкционированной рассылке;
            </li>
            <li>
              материалов, содержащих государственную, банковскую или
              коммерческую тайну;
            </li>
            <li>
              готовых дипломных, курсовых, магистерских работ, а также услуг по
              их написанию;
            </li>
            <li>
              любых информационных носителей с нелегальными копиями фильмов,
              музыки, игр;
            </li>
            <li>программного обеспечения;</li>
            <li>
              предметов и материалов, продажа которых нарушает авторские и/или
              смежные права третьих лиц, права на товарные знаки или патенты;
            </li>
            <li>
              любых предметов и услуг, не соответствующих основам правопорядка,
              морали и нравственности;
            </li>
            <li>
              материалов порнографического или эротического содержания или
              предметов;
            </li>
            <li>предметов с изображением нацистской символики.</li>
            <li>
              пропаганду и/или призывы к насилию, расовой ненависти,
              противоправным действиям;
            </li>
            <li>
              призывы на массовые акции (флешмобы, митинги, шествия и прочие
              акции);
            </li>
            <li>
              предложения, товары/предметы, связанные с религией, а также
              литература по данной тематике;
            </li>
            <li>
              дискриминацию по национальному, расовому, религиозному, половому и
              другим признакам;
            </li>
            <li>
              просьба или предложение материальной помощи и поддержки в
              какой-либо форме, в том числе объявления &laquo;стану
              спонсором&raquo;, &laquo;ищу спонсора&raquo;, &laquo;окажу
              материальную поддержку&raquo;, &laquo;ищу материальную
              поддержку&raquo;, &laquo;коммерция&raquo; и подобное;
            </li>
            <li>
              описание и/или комментарии относительно любых способов
              мошенничества и/или вымогательства;
            </li>
            <li>
              информацию с предложением франчайзинга, многоуровневого (сетевого)
              маркетинга (Avon, Oriflame, Amway и подобные) кроме раздела
              Бизнес;
            </li>
            <li>предложения услуг по трудоустройству;</li>
            <li>услуги отмотки пробега автомобиля;</li>
            <li>
              активаторы программ, ключи компакт-диска, регистрационные номера,
              предложения без оригинального программного обеспечения;
            </li>
            <li>
              услуги, связанные с предложением помощи в системах азартных игр,
              которые содержат пари.
            </li>
            <li>
              предложения, товары/предметы, связанные с оккультной тематикой
              (привороты, заговоры, гадания, сатанизм, ритуалы, колдовство,
              ведьмы, магия, экстрасенсы и т.д.), а также литература по данной
              тематике;
            </li>
            <li>
              материалы, которые могут использоваться как клевета на человека
              или группу людей;
            </li>
            <li>
              анкетные данные или списки адресов электронной почты; учетные
              записи мессенджеров (ICQ, Skype и подобное), адреса электронной
              почты, аккаунты социальных сетей;
            </li>
            <li>
              информацию, единственной целью которой является продвижение других
              интернет-ресурсов или интернет-магазинов;
            </li>
            <li>рекламу оффлайн-магазинов;</li>
            <li>
              эротику, порнографию, в т.ч фото эротического или
              порнографического содержания;
            </li>
            <li>
              секс услуги, проституция, любые услуги интимного характера,
              эротический массаж, боди массаж. А также любые товары интимного
              (сексуального) назначения.
            </li>
            <li>
              сомнительные предложения работы, в том числе в сети Интернет без
              указания физического адреса и прямых контактов работодателя
              (работа для всех, обработка электронной почты на дому, работа без
              вложений и подобное).
            </li>
            <li>
              предложения работы в ночных клубах за рубежом; веб-моделями;
              предложения услуг свингер-клубов; эскорт-услуги и т.п.;
            </li>
            <li>
              информацию с предложением агентской деятельности, которая требует
              вербовки других членов, субагентов, субдистрибьюторов и т.д.;
            </li>
            <li>
              любые объявления о знакомствах, дружбе, отношениях, создании
              семьи.
            </li>
          </ol>
        </ol>
      </ol>
      <p>
        4.10. Также запрещены объявления, которые не имеют смысла либо содержат
        следующее:
      </p>
      <ol>
        <ol>
          <ol>
            <li>
              Ненормативную лексику, а также оскорбительные высказывания, в т.ч.
              расистского и религиозного толка;
            </li>
            <li>Информацию, написанную транслитом;</li>
            <li>
              Информацию, написанную на любом иностранном языке, кроме
              узбекского и русского (в отдельных случаях английский язык
              допускается);
            </li>
            <li>
              Множественные орфографические ошибки, которые искажают смысл и
              вводят в заблуждение;
            </li>
          </ol>
          <li>
            Объявления могут проходить выборочную пост или премодерацию
            Исполнителем.
          </li>
          <li>
            Любое несоответствие объявление требованиям, указанным в настоящем
            Соглашении, дает Исполнителю право удалить объявление.
          </li>
        </ol>
      </ol>
      <p><strong>5. ПРАВА И ОБЯЗАННОСТИ ИСПОЛНИТЕЛЯ И ПОЛЬЗОВАТЕЛЯ.</strong></p>
      <p>5.1. Исполнитель вправе:</p>
      <ul>
        <li>
          Вносить в текст объявления Пользователя правки, касающиеся орфографии
          и пунктуации, не влияющие на общий смысл содержания объявления;
        </li>
        <li>
          Переносить объявления в другие рубрики Сайта в случае выявления более
          подходящей рубрики для их размещения;
        </li>
        <li>
          Отказать в публикации объявлений, если они не соответствуют тематике
          выбранных разделов, либо нарушают настоящее Соглашение, а также
          ограничивать количество объявлений от одного Пользователя в целях
          удобства пользования Сайтом без объяснения причин;
        </li>
        <li>
          Удалять объявления по требованию правообладателя или компетентных
          государственных органов;
        </li>
        <li>
          Удалять любые объявления, которые, по его мнению, не соответствуют
          основам правопорядка и устоям общественной морали;
        </li>
        <li>
          Устанавливать периодически ограничения по использованию сервисов
          Сайта, в частности, максимальное количество дней хранения объявлений и
          их размер;
        </li>
        <li>
          В любой момент изменить работу сервисов Сайта без предварительного
          уведомления;
        </li>
        <li>
          В любой момент прекратить работу сервисов Сайта, предварительно
          уведомив Пользователей. Если срок оплаченной услуги сервиса Сайта не
          истек к моменту прекращения работы сайта, Исполнитель возвращает
          Пользователю за этот период оплаченный аванс в пропорциональном
          размере;
        </li>
        <li>
          Ограничивать количество активных объявлений Пользователя на Сайте, а
          также ограничивать действия Пользователя на Сайте.
        </li>
        <li>
          Запретить Пользователю доступ на Сайт, если Пользователь нарушает
          условия настоящего Соглашения. Факт нарушения считается
          подтвержденным, если Пользователь был оповещен Исполнителем о
          совершении действий, нарушающих правила Сайтов и прав третьих лиц;
        </li>
        <li>
          В случае нарушения условий настоящего Соглашения Пользователем в любой
          момент удалить или отключить учетную запись Пользователя, а также
          удалить все размещенные объявления Пользователя, предварительно
          уведомив Пользователя о таком отключении;
        </li>
        <li>
          Передать Сайт со всеми его сервисами и содержанием, включая личную
          информацию Пользователей, своему правопреемнику по договорам или иным
          основаниям. Передача и уведомление Пользователей о такой передаче
          осуществляется в соответствии с требованиями действующего
          законодательства.
        </li>
        <li>
          Потребовать от Пользователя подтверждения данных, указанных им при
          регистрации, и запросить в связи с этим подтверждающие документы (в
          том числе &ndash; копии/заверенные копии документов, удостоверяющих
          личность), не предоставление которых, по усмотрению Пользователя,
          может быть приравнено к предоставлению недостоверной информации. В
          случае если данные Пользователя, указанные в предоставленных им
          документах, не соответствуют данным, указанным при регистрации и (или)
          при размещении объявления, а также в случае когда данные, указанные
          при регистрации, не позволяют идентифицировать Пользователя,
          Исполнитель вправе отказать Пользователю в доступе к использованию
          сервисов Сайтов с предварительным уведомлением Пользователя или без
          такового.
        </li>
      </ul>
      <p>5.2. Исполнитель обязан:</p>
      <ul>
        <li>обеспечить корректную работу Сервисов Сайта;</li>
        <li>
          не распространять третьим лицам персональных данных, предоставленных
          Пользователем, за исключением случаев, предусмотренных
          законодательством;
        </li>
      </ul>
      <p>5.3. Пользователь вправе:</p>
      <ul>
        <li>
          Направлять жалобы по вопросам работы Сайта через форму обратной связи.
          Жалобы будут рассмотрены в течение двух рабочих дней с момента их
          поступления или с момента получения полной информации по сути жалобы.
        </li>
      </ul>
      <p>5.4. Пользователь обязан:</p>
      <ul>
        <li>
          Не копировать, не воспроизводить, не изменять, не распространять и не
          представлять общественности любую информацию, содержащуюся на Сайте
          (кроме сведений, предоставленных самим Пользователем) без
          предварительного письменного разрешения Исполнителя;
        </li>
        <li>
          Не использовать сведения, предоставленные другими Пользователями, в
          иных целях, кроме как для совершения сделки непосредственно с этим
          Пользователем, без письменного разрешения другого Пользователя.
        </li>
      </ul>
      <p>5.5. Пользователю запрещается:</p>
      <ul>
        <li>
          Обсуждать действия модераторов Сайта
          <a href="http://www.topin.uz">www.topin.uz</a> иным образом, кроме как
          посредством электронной переписки с модераторами;
        </li>
        <li>
          Использовать на Сайте
          <a href="http://www.topin.uz">www.topin.uz</a> имена, похожие на имена
          других Пользователей, с целью выдать себя за них, а так же писать
          сообщения от имени других Пользователей.
        </li>
      </ul>
      <p>
        5.6. Использование контента Сайта
        <a href="http://www.topin.uz">www.topin.uz</a>, а также каких-либо иных
        элементов сервисов возможно только в рамках функционала, предлагаемого
        тем или иным сервисом Сайта. Никакие элементы содержания Сайта
        <a href="http://www.topin.uz">www.topin.uz</a> не могут быть
        использованы иным образом без предварительного разрешения
        правообладателя. Под использованием подразумеваются, в том числе:
        воспроизведение, копирование, переработка, распространение на любой
        основе и т.д.
      </p>
      <p>
        5.7. Вся информация, содержащаяся в поданных на Сайт
        <a href="http://www.topin.uz">www.topin.uz</a> объявлениях, является
        общедоступной, за исключением пароля для доступа к личному разделу
        пользователя и содержащейся в данном разделе информации.
      </p>
      <p>
        5.8. Доступ Пользователя к изменению информации, содержащейся в
        объявлениях, производится только на основе уникальных логина и пароля,
        выбранных пользователем при регистрации. Ответственность за сохранность
        пароля несет Пользователь.
      </p>
      <p><strong>6. ОТВЕТСТВЕННОСТЬ</strong></p>
      <p>
        6.1. Воспользовавшись сервисами Сайта
        <a href="http://www.topin.uz">www.topin.uz</a>, Пользователь
        подтверждает, что несет исключительную ответственность за содержание
        размещенных им объявлений, а также обладает всеми необходимыми правами,
        лицензиями, разрешениями, сертификатами на размещение информации в
        объявлении на Сайте, включая без ограничений все патенты, товарные
        знаки, права на коммерческую тайну, авторские права, или же имеет
        соответствующее письменное согласие, лицензию или разрешение всех
        идентифицируемых в объявлении лиц для использования их имен или
        изображений.
      </p>
      <p>
        6.2. Воспользовавшись сервисами Сайта
        <a href="http://www.topin.uz">www.topin.uz</a>, Пользователь
        подтверждает свое согласие с тем, что он использует Сайт и его сервисы
        на свой страх и риск, оценивает и несет все риски, связанные с
        использованием размещенных на Сайте объявлений.
      </p>
      <p>
        6.3. Исполнитель не может контролировать достоверность размещаемой
        пользователями в объявлениях информации. Качество, безопасность,
        законность и соответствие товара или услуги их описанию, а также
        возможность Пользователя продать или приобрести товар/услугу находятся
        вне контроля Исполнителя.
      </p>
      <p>
        6.4. Исполнитель не является организатором/инициатором сделки между
        Пользователями или ее стороной. Сайт
        <a href="http://www.topin.uz">www.topin.uz</a> и приложения (IOS и
        Androdi) данного сайта, является торговой коммуникационной платформой,
        которая предоставляет возможность Пользователям размещать для
        реализации, продавать и приобретать разрешенные законом товары и услуги
        в любое время, откуда угодно и по любой цене.
      </p>
      <p>
        6.5. Пользователи должны соблюдать осторожность и осознавать, что его
        контрагент может оказаться несовершеннолетним или выдавать себя за
        другое лицо. Пользователь не может делать вывод, что предложение продажи
        или приобретения какого-либо товара, работа или услуги является
        действительным и легальным исходя из факта размещения объявления на
        Сайте.
      </p>
      <p>6.6. Исполнитель не несет ответственности:</p>
      <ul>
        <li>за содержание размещенных на Сайте объявлений;</li>
        <li>
          за любые убытки и потери, ставшие результатом использования
          размещенных на Сайте
          <a href="http://www.topin.uz">www.topin.uz</a> объявлений;
        </li>
        <li>
          за какой-либо ущерб, причиненный в результате совершения сделки между
          пользователями Сайта
          <a href="http://www.topin.uz">www.topin.uz</a> или ненадлежащего
          поведения какой-либо из сторон сделки.
        </li>
        <li>
          за любой несанкционированный доступ или несанкционированное
          использование серверов Сайта и/или любой хранящейся на них информации
          о пользователях, а также за любые ошибки, вирусы и т.д., которые могут
          быть переданы на Сайт или через Сайт третьими лицами.
        </li>
        <li>
          за неисполнение или затруднения в исполнении обязательств по
          предоставлению доступа к Сайту ввиду возникновения обстоятельств
          непреодолимой силы (таких как решения властей, обрывы в общей системе
          коммуникаций и т.д.).
        </li>
        <li>
          за неполадки в работе Сайта, вызванные техническими перебоями в работе
          оборудования и программного обеспечения.
        </li>
      </ul>
      <p>
        6.7. Все споры и конфликты между Пользователями решаются ими
        самостоятельно без привлечения Исполнителя. Если у Пользователя
        возникают претензии к другому Пользователю в результате использования
        последним услуг Сайта, Пользователь соглашается предъявлять эти
        требования самостоятельно, без привлечения Исполнителя.
      </p>
      <p>
        6.8. Пользователь вправе сообщить Исполнителю о факте нарушения его прав
        другим Пользователем, воспользовавшись ссылкой на Сайте
        (http://________/). В случае обоснованности жалоб Пользователя
        Исполнитель удаляет объявление, которое нарушает права Пользователя.
      </p>
      <p><strong>7. СРОК ДЕЙСТВИЯ СОГЛАШЕНИЯ</strong></p>
      <p>
        7.1. Настоящее Соглашение вступает в силу с момента начала использования
        Пользователем какого-либо сервиса Сайта либо с момента регистрации
        Пользователя на Сайте <a href="http://www.topin.uz">www.topin.uz</a> и
        действует бессрочно.
      </p>
      <p>
        7.2. Пользователь имеет право прекратить свою регистрацию на Сайте в
        одностороннем порядке, без предварительного уведомления об этом
        Исполнителя и объяснения причин.
      </p>
      <p>
        7.3. В случае если Исполнителем были внесены какие-либо изменения в
        Соглашение в порядке, предусмотренном настоящим Соглашением, и
        Пользователь не согласен с ними, он обязан прекратить использование
        сервиса Сайта <a href="http://www.topin.uz">www.topin.uz</a>. Факт
        продолжения использования сайта является подтверждением согласия
        Пользователя с соответствующей редакцией Соглашения.
      </p>
      <p><strong>8. ПРОЧИЕ УСЛОВИЯ</strong></p>
      <p>
        8.1. Системные сообщения Сайта, относящиеся к объявлениям Пользователя,
        доставляются по электронному адресу, предоставленному Пользователем при
        размещении объявления на Сайте. В случае нежелания Пользователя получать
        такие сообщения Пользователь вправе удалить соответствующие объявления с
        Сайта <a href="http://www.topin.uz">www.topin.uz</a>.
      </p>
      <p>
        8.2. Информационные сообщения, предназначенные для широкого круга
        Пользователей, публикуются на Сайте и/или рассылаются по электронным
        адресам Пользователей, подтвердивших свое согласие на получение таких
        сообщений в процессе публикации объявлений/регистрации на
        соответствующем Сайте <a href="http://www.topin.uz">www.topin.uz</a>.
        Пользователи вправе отказаться от получения информационных сообщений на
        свой электронный адрес в любой момент через функцию &laquo;Отписаться от
        рассылки&raquo;, содержащуюся в учетной записи Пользователя.
      </p>
      <p>
        8.3. Все споры и разногласия между Пользователем и Исполнителем, которые
        не удалось разрешить путем переговоров, рассматриваются в суде по месту
        нахождения Исполнителя.
      </p>
      <p>
        8.4. Признание судом какого-либо положения настоящего Соглашения
        недействительным или не подлежащим принудительному исполнению не влечет
        недействительности или неисполнимости иных положений данного Соглашения.
      </p>
      <p>
        8.5. Настоящее Соглашение регулируется и толкуется в соответствии с
        законодательством Республики Узбекистан. Вопросы, не урегулированные
        настоящим Соглашением, подлежат разрешению в соответствии с действующим
        законодательством Узбекистана. Все возможные споры, вытекающие из
        отношений, регулируемых настоящим Соглашением, разрешаются в порядке,
        установленном законодательством Узбекистан, по нормам права Республики
        Узбекистан.
      </p>
    </div>
  </div>
</template>

<script>
export default {};
</script>