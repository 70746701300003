export default {
  state: {
    categories: [],
    childCategories: [],
    categoriesLoading: true,
  },
  actions: {
    fetchData({ commit }) {
      fetch("https://admin.topin.uz/api/category")
        .then((res) => res.json())
        .then((val) => commit("update", val))
        .catch((e) => commit("update", []));
    },
  },
  mutations: {
    update(state, data) {
      state.categories = data.filter((cat) => cat.parent_id === null);
      state.childCategories = data.filter((cat) => cat.parent_id !== null);
      state.categoriesLoading = false;
    },
  },
  getters: {
    categories(state) {
      return state.categories;
    },
    childCategories(state) {
      return state.childCategories;
    },
    categoriesLoading(state) {
      return state.categoriesLoading;
    },
  },
};
