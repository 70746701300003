<template>
  <div class="businessReg">
    <div class="container">
      <div class="row centered">
        <div class="buisness-reg_right">
          <form @submit.prevent="submit($event)" class="reg-buisness form-business">
            <div class="reg-buisness_header">
              <span>
                {{
                $store.getters.strings[$store.getters.currentLang].profile
                }}
              </span>
              <span class="primary">
                {{
                $store.getters.strings[$store.getters.currentLang].bussinessAcc
                }}
              </span>
            </div>
            <div class="reg-buisness_body">
              <div class="title">
                {{
                $store.getters.strings[$store.getters.currentLang].personal
                }}
              </div>
              <div class="photo">
                <div class="photo-logo">
                  <label>Logo (60x60):</label>
                  <div class="image">
                    <input type="file" name="image" />
                    <!-- <img v-bind:src="require('../assets/images/photo.png')" alt /> -->
                  </div>
                </div>
                <div class="photo-banner">
                  <label>{{ $store.getters.strings[$store.getters.currentLang].banner }} (640x300):</label>
                  <div class="image">
                    <input type="file" name="banner" class="banner" />
                    <!-- <img v-bind:src="require('../assets/images/banner.png')" alt /> -->
                  </div>
                </div>
              </div>
              <div class="info">
                <div class="info-block">
                  <label>{{ $store.getters.strings[$store.getters.currentLang].bussinessName }}:</label>
                  <input type="text" maxlength="110" v-model="name" required="required" />
                </div>
                <div class="info-block">
                  <label>{{ $store.getters.strings[$store.getters.currentLang].sphere }}:</label>
                  <SearchMulti
                    :parent="categories"
                    :children="childCategories"
                    :setter="setCategory"
                    v-if="categories.length > 0"
                  />
                  <div class="form-region" style="overflow:hidden;justify-content:center;" v-else>
                    <ActivityLoader />
                  </div>
                </div>
                <div class="info-block">
                  <label>{{ $store.getters.strings[$store.getters.currentLang].inn }}:</label>
                  <input type="number" maxlength="110" v-model="inn" required="required" />
                </div>
                <div class="info-block">
                  <label>{{ $store.getters.strings[$store.getters.currentLang].workdays }}:</label>
                  <input
                    type="text"
                    maxlength="110"
                    placeholder="Пн, Вт, Ср, Чт, Пт, Сб, Вс"
                    v-model="workdays"
                    required="required"
                  />
                </div>
              </div>
              <div class="description">
                <label>{{ $store.getters.strings[$store.getters.currentLang].addDescr }}:</label>
                <textarea v-model="description" maxlength="110" required="required"></textarea>
              </div>
              <div class="location">
                <div
                  class="location-title"
                >{{ $store.getters.strings[$store.getters.currentLang].location }}:</div>
                <div class="select-region">
                  <label>{{ $store.getters.strings[$store.getters.currentLang].region }}:</label>
                  <div class="country">
                    <input
                      type="hidden"
                      name="country"
                      v-bind:value="this.currentRegion ? $store.getters.strings[$store.getters.currentLang].uzbekistan : this.currentRegion"
                    />
                    <div class="form-region" @click="handleSelect">
                      <span>
                        {{
                        !this.currentRegion ? $store.getters.strings[$store.getters.currentLang].uzbekistan : this.currentRegion
                        }}
                      </span>
                      <div class="icon" :class="{ active: select === true }">
                        <img src="../assets/images/dropdown2.svg" alt />
                      </div>
                    </div>
                    <div class="regions" v-if="select">
                      <div class="regions-title">
                        <span>{{ $store.getters.strings[$store.getters.currentLang].every }}</span>
                        <div class="icon">
                          <img src="../assets/images/drop-right-dark.svg" alt />
                        </div>
                      </div>
                      <div
                        class="reg"
                        v-for="region in getMainRegions"
                        :key="region.id"
                        @click="updateChooseParent(region)"
                      >
                        <span>
                          <template v-if="$store.getters.currentLang == 1">{{ region.name_ru }}</template>
                          <template v-else-if="$store.getters.currentLang == 2">{{ region.name_uz }}</template>
                          <template v-else-if="$store.getters.currentLang == 3">{{ region.name_kr }}</template>
                        </span>
                        <div class="icon">
                          <img src="../assets/images/drop-right.svg" alt />
                          <img src="../assets/images/drop-right-hover.svg" alt />
                        </div>
                      </div>
                    </div>
                    <div class="region-city" v-if="region_id && isOpen === true">
                      <div class="region-city_title">
                        <span
                          @click="updateChooseRegion(getChildRegions[0].parent_id)"
                        >По всему региону</span>
                        <div class="icon">
                          <img src="../assets/images/drop-right-dark.svg" alt />
                        </div>
                      </div>
                      <div
                        class="region-city_tash"
                        v-for="(reg, index) in getChildRegions"
                        :key="index"
                        @click="updateChooseRegion(reg.id)"
                      >
                        <span>
                          <template v-if="$store.getters.currentLang == 1">{{ reg.name_ru }}</template>
                          <template v-else-if="$store.getters.currentLang == 2">{{ reg.name_uz }}</template>
                          <template v-else-if="$store.getters.currentLang == 3">{{ reg.name_kr }}</template>
                        </span>
                        <div class="icon">
                          <img src="../assets/images/drop-right.svg" alt />
                          <img src="../assets/images/drop-right-hover.svg" alt />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="adress">
                  <label>{{ $store.getters.strings[$store.getters.currentLang].address }}:</label>
                  <input type="text" maxlength="110" v-model="address" required="required" />
                </div>
              </div>
              <div class="contacts">
                <div
                  class="contacts-title"
                >{{ $store.getters.strings[$store.getters.currentLang].contactInfo }}</div>
                <div class="contacts-info">
                  <div class="contacts-info_block">
                    <label>{{ $store.getters.strings[$store.getters.currentLang].website }}:</label>
                    <input type="text" maxlength="110" v-model="website" required="required" />
                  </div>
                  <div class="contacts-info_block">
                    <label>{{ $store.getters.strings[$store.getters.currentLang].email }}:</label>
                    <input type="text" maxlength="110" v-model="email" required="required" />
                  </div>
                  <div class="contacts-info_block">
                    <label>{{ $store.getters.strings[$store.getters.currentLang].phone }}:</label>
                    <input
                      type="text"
                      placeholder="+998 99 888 88 88"
                      disabled="disabled"
                      :value="phone"
                    />
                  </div>
                </div>
                <div class="contacts-button">
                  <div class="block"></div>
                  <button
                    type="submit"
                  >{{ $store.getters.strings[$store.getters.currentLang].save }}</button>
                  <div class="block"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import SearchMulti from "@/components/SearchMulti";
import ActivityLoader from "@/components/Preloaders/ActivityLoader";
import Loading from "./Loading.vue";
export default {
  components: { Loading, SearchMulti, ActivityLoader },
  data() {
    return {
      select: false,
      isOpen: false,
      description: "",
      name: "",
      address: "",
      inn: "",
      workdays: "",
      email: "",
      website: "",
      loading: false,
      regionId: 0,
      categoryId: 0,
      gender: [
        {
          id: 1,
          name_ru: "Мужчина",
          name_uz: "Erkak",
          name_kr: "Эркак",
        },
        {
          id: 2,
          name_ru: "Женщина",
          name_uz: "Ayol",
          name_kr: "Аёл",
        },
      ],
    };
  },
  methods: {
    updateCoordinates: function (location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    setCategory: function (value) {
      this.categoryId = value;
    },
    submit: function (submitEvent) {
      this.loading = true;
      const inputs = submitEvent.target.elements;
      const { banner, image } = inputs;
      const body = {
        email: this.email,
        name: this.name,
        work_time: this.workdays,
        inn: this.inn,
        description: this.description,
        address: this.address,
        website: this.website,
        category_id: this.categoryId,
        country: this.currentRegion
          ? this.$store.getters.strings[this.$store.getters.currentLang]
              .uzbekistan
          : this.currentRegion,
        is_business: 1,
        ...(banner.files.length && { banner: banner.files[0] }),
        ...(image.files.length && { image: image.files[0] }),
      };
      this.updateAccount(body)
        .then((value) => {
          if (value) {
            this.$router.push("/profile/business");
          }
          this.loading = false;
        })
        .catch((e) => (this.loading = false));
    },
    updateChooseParent: function (region) {
      this.isOpen = true;
      this.select = true;
      this.handleChooseParent(region);
    },
    updateChooseRegion: function (id) {
      this.isOpen = false;
      this.select = false;
      this.handleChooseRegion(id);
      this.regionId = id;
    },
    handleSelect: function () {
      this.select = !this.select;
    },
    ...mapActions([
      "regionHandler",
      "handleChooseRegion",
      "handleChooseParent",
      "updateAccount",
    ]),
  },
  computed: {
    ...mapGetters([
      "categories",
      "childCategories",
      "regions",
      "currentRegion",
      "region_id",
      "parent_id",
      "getMainRegions",
      "getMainParent",
      "getChildRegions",
      "phone",
      "business",
      "user",
    ]),
  },
};
</script>
</script> 
