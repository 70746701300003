<template>
  <div class="blog-wrapp">
    <label>{{ title }}</label>
    <div class="blog" @click="show ? show = false : handleSelect(-1)">
      <span>{{ getSelected }}</span>
      <div class="icon" :class="{ active: show === true }">
        <img src="../assets/images/dropdown2.svg" alt/>
      </div>
      <input
          type="hidden"
          :name="name ? name : ''"
          required="required"
          :value="getSelectedValue"
      />
    </div>
    <div class="regions" v-if="show">
      <div
          v-for="(value, index) in data"
          :class="'reg ' + (currentSelect === value.id ? 'active' : '')"
          :key="index"
          @click="handleSelect(value.id)"
      >
        <span>{{ value.name_ru }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      show: false,
      currentSelect: 0
    };
  },
  methods: {
    handleSelect: function (id) {
      if (!this.show) {
        this.show = true;
      }
      if (id >= 0) {
        if (id != this.currentSelect) {
          this.currentSelect = id;
        }
        this.show = false;
      }
    }
  },
  computed: {
    getSelected: function () {
      if (this.currentSelect == 0) {
        return this.$store.getters.strings[this.$store.getters.currentLang].chooseList;
      }
      const merged = this.data.find(data => data.id == this.currentSelect);
      if (this.$store.getters.currentLang == 1) return merged.name_ru;
      if (this.$store.getters.currentLang == 2) return merged.name_uz;
      if (this.$store.getters.currentLang == 3) return merged.name_kr;
    },
    getSelectedValue: function () {
      if (this.currentSelect == 0) {
        return "";
      }
      return this.data.find(data => data.id == this.currentSelect).id;
    },
  }
};
</script>
