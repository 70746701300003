<template>
  <div class="mobile">
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
    <div class="item">
      <content-loader
        :width="80"
        :height="80"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="80" height="80" />
      </content-loader>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  components: {
    ContentLoader
  }
};
</script>
