export default {
  state: {
    operations: [],
    favorites: [],
    messages: [],
    balance: 0,
    showMessages: [],
    innerAdvert: null,
    profileLoading: false,
    myAdverts: [],
  },
  actions: {
    async getOperations({ commit, getters }) {
      commit("setProfileLoading", true);
      const response = await fetch(
        "https://admin.topin.uz/api/balance/history",
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getters.token}`,
          },
        }
      );
      const value = await response.json();
      if (response.status === 200) {
        commit("setOperations", value.data);
      }
      commit("setProfileLoading", false);
    },
    async getMyAdverts({ commit, getters }, type) {
      commit("setProfileLoading", true);
      const getStatus = () => {
        if (type == 1) return "ACTIVE";
        if (type == 2) return "MODERATION";
        if (type == 3) return "DISABLED";
      };
      const body = {
        status: getStatus(),
      };
      const response = await fetch(
        "https://admin.topin.uz/api/my/adverts?" + new URLSearchParams(body),
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getters.token}`,
          },
        }
      );
      const value = await response.json();
      if (response.status === 200) {
        commit("setMyAdverts", value.data);
      }
      commit("setProfileLoading", false);
    },
    async switchStatus({ commit, getters }, id) {
      const response = await fetch(
        "https://admin.topin.uz/api/switch/status/" + id,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getters.token}`,
          },
        }
      );
      return response.status === 200;
    },
    async getFavorites({ commit, getters }) {
      commit("setProfileLoading", true);
      const response = await fetch("https://admin.topin.uz/api/favorite", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
      });
      const value = await response.json();
      if (response.status === 200) {
        commit("setFavorites", value.data);
      }
      commit("setProfileLoading", false);
    },
    async getMessages({ commit, getters }) {
      commit("setProfileLoading", true);
      const response = await fetch("https://admin.topin.uz/api/messages", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
      });
      const value = await response.json();
      if (response.status === 200) {
        commit("setMessages", value.data);
      }
      commit("setProfileLoading", false);
    },
    async getInnerMessages({ commit, getters }, id) {
      commit("setProfileLoading", true);
      const response = await fetch(
        "https://admin.topin.uz/api/messages/advert/" + id,
        {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
            Authorization: `Bearer ${getters.token}`,
          },
        }
      );
      const value = await response.json();
      if (response.status === 200) {
        commit("setInnerMessages", value.data);
      }
    },
    async getBalance({ commit, getters }) {
      commit("setProfileLoading", true);
      const response = await fetch("https://admin.topin.uz/api/balance", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
      });
      const value = await response.json();
      if (response.status === 200) {
        commit("setMessages", value.data);
      }
      commit("setProfileLoading", true);
    },
    async sendMessage({ getters }, body) {
      const response = await fetch("https://admin.topin.uz/api/send/message", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
        body: JSON.stringify(body),
      });
      if (response.status === 200) {
        return response.status === 200;
      }
    },
  },
  mutations: {
    setOperations(state, data) {
      state.operations = data;
    },
    setMyAdverts(state, data) {
      state.myAdverts = data;
    },
    setFavorites(state, data) {
      state.favorites = data;
    },
    setMessages(state, data) {
      state.messages = data;
    },
    setProfileLoading(state, data) {
      state.profileLoading = data;
    },
    setInnerMessages(state, data) {
      state.showMessages = data.messages;
      state.innerAdvert = data.advert;
      state.profileLoading = false;
    },
    setBalance(state, data) {
      state.balance = data;
    },
  },
  getters: {
    operations(state) {
      return state.operations;
    },
    profileLoading(state) {
      return state.profileLoading;
    },
    showMessages(state) {
      return state.showMessages;
    },
    innerAdvert(state) {
      return state.innerAdvert;
    },
    favorites(state) {
      return state.favorites;
    },
    messages(state) {
      return state.messages;
    },
    myAdverts(state) {
      return state.myAdverts;
    },
    balance(state) {
      return state.balance;
    },
  },
};
