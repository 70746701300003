<template>
  <div class="container">
    <loading v-if="loading" />
    <div class="row">
      <div class="buisness-reg">
        <div class="buisness-reg_left">
          <UserSideBar />
        </div>
        <div class="buisness-reg_right">
          <form
            @submit.prevent="submit($event)"
            class="reg-buisness form-business"
          >
            <div class="reg-buisness_header">
              <span>
                {{ $store.getters.strings[$store.getters.currentLang].profile }}
              </span>
              <span class="primary">
                {{
                  $store.getters.strings[$store.getters.currentLang]
                    .bussinessAcc
                }}
              </span>
            </div>
            <div class="reg-buisness_body">
              <div class="title">
                {{
                  $store.getters.strings[$store.getters.currentLang].personal
                }}
              </div>
              <div class="photo">
                <div class="photo-logo">
                  <label>
                    {{
                      $store.getters.strings[$store.getters.currentLang].photo
                    }}(60x60):
                  </label>
                  <div class="image">
                    <input type="file" name="image" />
                    <img
                      v-bind:src="
                        user.image
                          ? user.image
                          : require('../assets/images/photo.png')
                      "
                      alt
                    />
                  </div>
                </div>
                <div class="photo-banner">
                  <label
                    >{{
                      $store.getters.strings[$store.getters.currentLang].banner
                    }}(640x300):</label
                  >
                  <div class="image">
                    <input type="file" name="banner" class="banner" />
                    <img v-bind:src="user.banner" alt />
                  </div>
                </div>
              </div>
              <div class="info">
                <div class="info-block">
                  <label
                    >{{
                      $store.getters.strings[$store.getters.currentLang]
                        .bussinessName
                    }}:</label
                  >
                  <input
                    type="text"
                    maxlength="110"
                    v-model="name"
                    required="required"
                  />
                </div>
                <div class="info-block">
                  <label
                    >{{
                      $store.getters.strings[$store.getters.currentLang].sphere
                    }}:</label
                  >
                  <SearchMulti
                    :parent="categories"
                    :children="childCategories"
                    :setter="setCategory"
                    :chosen="String(user.category_id)"
                    v-if="categories.length > 0"
                  />
                  <div
                    class="form-region"
                    style="overflow: hidden; justify-content: center"
                    v-else
                  >
                    <ActivityLoader />
                  </div>
                </div>
                <div class="info-block">
                  <label
                    >{{
                      $store.getters.strings[$store.getters.currentLang].inn
                    }}:</label
                  >
                  <input
                    type="number"
                    maxlength="110"
                    v-model="inn"
                    required="required"
                  />
                </div>
                <div class="info-block">
                  <label
                    >{{
                      $store.getters.strings[$store.getters.currentLang]
                        .workdays
                    }}:</label
                  >
                  <input
                    type="text"
                    maxlength="110"
                    placeholder="Пн, Вт, Ср, Чт, Пт, Сб, Вс"
                    v-model="workdays"
                    required="required"
                  />
                </div>
              </div>
              <div class="description">
                <label
                  >{{
                    $store.getters.strings[$store.getters.currentLang].addDescr
                  }}:</label
                >
                <textarea
                  v-model="description"
                  maxlength="110"
                  required="required"
                ></textarea>
              </div>
              <div class="location">
                <div class="location-title">
                  {{
                    $store.getters.strings[$store.getters.currentLang].location
                  }}:
                </div>
                <div class="select-region">
                  <label
                    >{{
                      $store.getters.strings[$store.getters.currentLang].region
                    }}:</label
                  >
                  <div class="form-region">
                    <div class="left">
                      <img src="../assets/images/pin-dark.svg" alt />
                      <div class="title">{{ user.country }}</div>
                    </div>
                  </div>
                </div>
                <div class="adress">
                  <label
                    >{{
                      $store.getters.strings[$store.getters.currentLang]
                        .address
                    }}:</label
                  >
                  <input
                    type="text"
                    maxlength="110"
                    v-model="address"
                    required="required"
                  />
                </div>
                <!-- <div class="cart-image">
                  <GmapMap
                    :center="center"
                    :zoom="15"
                    :options="{
                      mapTypeControl: false,
                      streetViewControl: false,
                      rotateControl: false,
                      fullscreenControl: false,
                      disableDefaultUI: false,
                      clickable: false,
                    }"
                    style="width: 100%; height: 400px;margin-top:10px;border-radius:20px;overflow:hidden;"
                  >
                    <GmapMarker
                      :position="center"
                      :clickable="false"
                      :draggable="true"
                      @dragend="updateCoordinates"
                      :icon="markerOptions"
                    />
                  </GmapMap>
                </div>-->
              </div>
              <div class="contacts">
                <div class="contacts-title">
                  {{
                    $store.getters.strings[$store.getters.currentLang]
                      .contactInfo
                  }}
                </div>
                <div class="contacts-info">
                  <div class="contacts-info_block">
                    <label
                      >{{
                        $store.getters.strings[$store.getters.currentLang]
                          .website
                      }}:</label
                    >
                    <input
                      type="text"
                      maxlength="110"
                      v-model="website"
                      required="required"
                    />
                  </div>
                  <div class="contacts-info_block">
                    <label
                      >{{
                        $store.getters.strings[$store.getters.currentLang]
                          .email
                      }}:</label
                    >
                    <input
                      type="text"
                      maxlength="110"
                      v-model="email"
                      required="required"
                    />
                  </div>
                  <div class="contacts-info_block">
                    <label
                      >{{
                        $store.getters.strings[$store.getters.currentLang]
                          .phone
                      }}:</label
                    >
                    <input
                      type="text"
                      placeholder="+998 99 888 88 88"
                      disabled="disabled"
                      :value="phone"
                    />
                  </div>
                </div>
                <div class="contacts-button">
                  <div class="block"></div>
                  <button type="submit">
                    {{
                      $store.getters.strings[$store.getters.currentLang].save
                    }}
                  </button>
                  <div class="block"></div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSideBar from "@/components/UserSideBar";
import { mapGetters, mapActions } from "vuex";
import SearchMulti from "@/components/SearchMulti";
import ActivityLoader from "@/components/Preloaders/ActivityLoader";
import Loading from "./Loading.vue";
export default {
  components: { UserSideBar, Loading, SearchMulti, ActivityLoader },
  data() {
    return {
      // markerOptions: {
      //   url: mapMarker,
      //   size: { width: 170, height: 50, f: "px", b: "px" },
      //   scaledSize: { width: 170, height: 50, f: "px", b: "px" },
      //   labelColor: "white",
      // },
      // center: {
      //   lat: parseInt(this.$store.getters.user.lat) || 41.311081,
      //   lng: parseInt(this.$store.getters.user.long) || 69.240562,
      // },
      // coordinates: {
      //   lat: parseInt(this.$store.getters.user.lat) || 41.311081,
      //   lng: parseInt(this.$store.getters.user.long) || 69.240562,
      // },
      description: this.$store.getters.user.description,
      name: this.$store.getters.user.name,
      address: this.$store.getters.user.address,
      inn: this.$store.getters.user.inn,
      workdays: this.$store.getters.user.work_time,
      email: this.$store.getters.user.email,
      website: this.$store.getters.user.website,
      loading: false,
      regionId: 0,
      categoryId: 0,
      gender: [
        {
          id: 1,
          name_ru: "Мужчина",
          name_uz: "Erkak",
          name_kr: "Эркак",
        },
        {
          id: 2,
          name_ru: "Женщина",
          name_uz: "Ayol",
          name_kr: "Аёл",
        },
      ],
    };
  },
  methods: {
    updateCoordinates: function (location) {
      this.coordinates = {
        lat: location.latLng.lat(),
        lng: location.latLng.lng(),
      };
    },
    setCategory: function (value) {
      this.categoryId = value;
    },
    submit: function (submitEvent) {
      this.loading = true;
      const inputs = submitEvent.target.elements;
      const { banner, image } = inputs;
      const body = {
        email: this.email,
        name: this.name,
        work_time: this.workdays,
        inn: this.inn,
        description: this.description,
        address: this.address,
        website: this.website,
        category_id: this.categoryId,
        is_business: 1,
        ...(banner.files.length > 0 && { banner: banner.files[0] }),
        ...(image.files.length > 0 && { image: image.files[0] }),
      };
      this.updateAccount(body)
        .then((value) => {
          this.loading = false;
        })
        .catch((e) => (this.loading = false));
    },
    updateChooseParent: function (region) {
      this.isOpen = true;
      this.select = true;
      this.handleChooseParent(region);
    },
    updateChooseRegion: function (id) {
      this.isOpen = false;
      this.select = false;
      this.handleChooseRegion(id);
      this.regionId = id;
    },
    handleSelect: function () {
      this.select = !this.select;
    },
    ...mapActions([
      "regionHandler",
      "handleChooseRegion",
      "handleChooseParent",
      "updateAccount",
    ]),
  },
  computed: {
    ...mapGetters([
      "categories",
      "childCategories",
      "regions",
      "currentRegion",
      "region_id",
      "parent_id",
      "getMainRegions",
      "getMainParent",
      "getChildRegions",
      "phone",
      "business",
      "user",
    ]),
  },
};
</script>
