<template>
  <div class="container">
    <div class="chat">
      <div class="chat-left">
        <UserSideBar />
      </div>
      <div class="chat-right" v-if="!profileLoading && innerAdvert">
        <div class="chat-right_header">
          <router-link :to="'/prewiew/' + innerAdvert.id" class="mess-product">
            <div class="mess-product_left">
              <div class="img-right">
                <img src="../assets/images/top-right.svg" />
              </div>
              <div class="img-bottom">
                <img src="../assets/images/orange.svg" alt />
              </div>
            </div>
            <div class="mess-product_right">
              <div class="about">
                <div class="about-title">{{ innerAdvert.name }}</div>
                <div class="about-price">{{ innerAdvert.price }} UZS</div>
              </div>
              <div class="about">
                <span>
                  {{
                  innerAdvert.breadcrumb
                  .map((bread) => {
                  if ($store.getters.currentLang == 1) return bread.name_ru;
                  if ($store.getters.currentLang == 2) return bread.name_uz;
                  if ($store.getters.currentLang == 3) return bread.name_kr;
                  })
                  .join(" / ")
                  }}
                </span>
                <span>
                  {{
                  $store.getters.strings[$store.getters.currentLang]
                  .publishBy
                  }}: {{ innerAdvert.user.name }}
                </span>
              </div>
              <div class="about">
                <span v-if="$store.getters.currentLang == 1">
                  {{
                  innerAdvert.region.name_ru
                  }}
                </span>
                <span v-if="$store.getters.currentLang == 2">
                  {{
                  innerAdvert.region.name_uz
                  }}
                </span>
                <span v-if="$store.getters.currentLang == 3">
                  {{
                  innerAdvert.region.name_kr
                  }}
                </span>
                <span>
                  {{
                  $store.getters.strings[$store.getters.currentLang].addedOn
                  }}
                  {{ innerAdvert.created_at.slice(0, 10) }}
                </span>
              </div>
              <div class="about">
                <span>{{ innerAdvert.region.name_ru }}</span>
                <span class="price">{{ innerAdvert.price }} UZS</span>
              </div>
            </div>
          </router-link>
        </div>
        <div class="chat-right_body">
          <div class="date-wrap">
            <div class="date">
              <span>{{ innerAdvert.created_at.slice(0, 10) }}</span>
            </div>
          </div>
          <div class="messages">
            <template v-for="mess in showMessages">
              <div class="wrap" v-if="mess.user_id != user.id" :key="mess.id">
                <div class="entering-message">
                  <div class="text">
                    <span>{{ mess.body }}</span>
                  </div>
                  <div class="time">
                    <span>{{ mess.created_at.slice(0, 10) }}</span>
                  </div>
                </div>
                <div class="empty"></div>
              </div>
              <div class="wrapp" v-else :key="mess.id">
                <div class="empty"></div>
                <div class="outgoing-message">
                  <div class="text">
                    <span>{{ mess.body }}</span>
                  </div>
                  <div class="time">
                    <span>{{ mess.created_at.slice(0, 10) }}</span>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
        <div class="send-input">
          <input
            type="text"
            :placeholder="
              $store.getters.strings[$store.getters.currentLang].write
            "
            v-model="message"
            v-on:keyup.13="send"
          />
          <button @click="send">
            <img src="../assets/images/send-button.svg" alt />
          </button>
        </div>
      </div>
      <div class="chat-right" v-else>
        <AdvertLoading />
      </div>
    </div>
  </div>
</template>

<script>
import UserSideBar from "@/components/UserSideBar";
import AdvertLoading from "./AdvertLoading";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { UserSideBar, AdvertLoading },
  data() {
    return {
      date: "2 апреля 2020",
      message: "",
    };
  },
  methods: {
    send: function () {
      if (this.message.length < 3) return 0;
      const receiver_id =
        this.innerAdvert.user_id == this.user.id
          ? this.showMessages.find((mess) => mess.user_id != this.user.id)
              .user_id
          : this.innerAdvert.user_id;
      const data = {
        advert_id: this.innerAdvert.id,
        receiver_id: receiver_id,
        body: this.message,
      };
      this.sendMessage(data).then((value) =>
        this.getInnerMessages(this.innerAdvert.id)
      );
      this.message = "";
    },
    ...mapActions(["getInnerMessages", "sendMessage"]),
  },

  mounted() {
    const routeId = parseInt(this.$route.params.id);
    this.getInnerMessages(routeId);
  },
  computed: {
    ...mapGetters(["showMessages", "innerAdvert", "profileLoading", "user"]),
  },
};
</script>
