<template>
  <div>
    <div class="id-block" v-for="(operation, index) in operations" :key="index">
      <div class="id-block_info">
        <span>№ {{ index + 1 }}</span>
        <span>{{ operation.created_at.slice(0, 10) }}</span>
      </div>
      <div class="id-block_info">
        <span class="id-title">
          {{ operation.amount > 0
          ? $store.getters.strings[$store.getters.currentLang].moneyPlus
          : $store.getters.strings[$store.getters.currentLang].moneyMinus }}
        </span>
      </div>
      <div class="id-block_info">
        <span v-if="!operation.advert_id">Topin System</span>
        <span v-if="operation.advert_id">{{operation.advert.name}}</span>
        <span class="price">{{ operation.amount }} UZS</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    operations: {
      type: Array,
      required: true,
    },
  },
};
</script>