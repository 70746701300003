<template>
  <div class="home-slider">
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
    <div class="home-slider_category">
      <content-loader
        :width="260"
        :height="180"
        :speed="5"
        primaryColor="#5a5a5a"
        secondaryColor="#ff7800"
      >
        <rect x="0" y="0" rx="10" ry="10" width="260" height="180" />
      </content-loader>
    </div>
  </div>
</template>

<script>
import { ContentLoader } from "vue-content-loader";
export default {
  components: {
    ContentLoader
  }
};
</script>
