<template>
  <div>
    <div
      class="profile-product"
      v-for="(favorite, index) in favorites"
      :key="index"
    >
      <div class="profile-product_wrap">
        <div class="left">
          <div class="image">
            <div class="image-right">
              <img src="../assets/images/top-right.svg" />
            </div>
            <div class="image-bottom">
              <img src="../assets/images/orange.svg" alt />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-title">{{ favorite.advert.name }}</div>
          <div class="right-about">
            <span v-if="$store.getters.currentLang == 1">
              {{
              favorite.advert.region.name_ru
              }}
            </span>
            <span v-else-if="$store.getters.currentLang == 2">
              {{
              favorite.advert.region.name_uz
              }}
            </span>
            <span v-else-if="$store.getters.currentLang == 3">
              {{
              favorite.advert.region.name_kr
              }}
            </span>            
            <span>{{ favorite.price }}</span>
          </div>
        </div>
      </div>
      <div class="profile-product_buttons">
        <router-link :to="'messages/chat/' + favorite.id" class="lnk">
          <img src="../assets/images/small-mes.svg" alt />
          <span>Написать</span>
        </router-link>
        <button class="lnk">
          <img src="../assets/images/small-call.svg" alt />
          <span>Позвонить</span>
        </button>
        <button class="lnk">Удалить с избранных</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: {
    favorites: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {},
  computed: mapGetters(["currentLang"]),
};
</script>
