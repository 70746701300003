var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"myModal"}),_c('Navigator'),_c('router-view'),_c('Footer',{directives:[{name:"show",rawName:"v-show",value:(
      _vm.$route.name !== 'entering1' &&
      _vm.$route.name !== 'entering2' &&
      _vm.$route.name !== 'entering3' &&
      _vm.$route.name !== 'registration' &&
      _vm.$route.name !== 'Cart'
    ),expression:"\n      $route.name !== 'entering1' &&\n      $route.name !== 'entering2' &&\n      $route.name !== 'entering3' &&\n      $route.name !== 'registration' &&\n      $route.name !== 'Cart'\n    "}]})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }