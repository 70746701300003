<template>
  <div class="profile">
    <div class="container">
      <div class="profile-wrap">
        <div class="profile-left">
          <UserSideBar />
        </div>
        <div class="profile-right_block">
          <div class="profile-right_block_header">
            <div class="title">{{ $store.getters.strings[$store.getters.currentLang].favorites }}</div>
          </div>
          <div class="profile-right_block_body">
            <div class="sort">
              <div class="sort-date">
              </div>
            </div>
            <div class="wrap">
              <FavoriteItem v-if="favorites.length" :favorites="favorites" />
              <div class="centered">
                <img src="../assets/images/categoryLoader.gif" width="200" v-if="profileLoading" alt />                
                <template v-if="!favorites.length && !profileLoading">
                  <img src="../assets/images/no-favorites.svg" alt />
                  <p>{{ $store.getters.strings[$store.getters.currentLang].notExist }}</p>
                </template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import UserSideBar from "@/components/UserSideBar";
import FavoriteItem from "@/components/FavoriteItem";
import { mapGetters, mapActions } from "vuex";

export default {
  components: { UserSideBar, FavoriteItem },
  data() {
    return {
      date: false,
    };
  },
  mounted() {
    this.getFavorites();
  },
  computed: {
    ...mapGetters(["favorites","profileLoading"]),
  },
  methods: mapActions(["getFavorites"]),
};
</script>
