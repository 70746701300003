export default {
  state: {
    token: JSON.parse(localStorage.getItem("token")),
    user: JSON.parse(localStorage.getItem("user")),
    phone: JSON.parse(localStorage.getItem("phone")),
    business: JSON.parse(localStorage.getItem("business")),
    registered: JSON.parse(localStorage.getItem("registered")),
  },
  actions: {
    async logout({ commit, getters }) {
      const response = await fetch("https://admin.topin.uz/api/logout", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
      });
      if (response.status === 200) {
        commit("removeUserData");
      }
      return response.status === 200;
    },
    async verify({ commit }, phone) {
      commit("setPhone", phone);
      const response = await fetch("https://admin.topin.uz/api/verification", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone }),
      });
      const value = await response.json();
      commit("setPhone", value.statusCode === 200 ? phone : null);
      return value.statusCode === 200;
    },
    async authorize({ commit, dispatch, getters }, data) {
      const { verify_code } = data;
      const phone = getters.phone;
      const response = await fetch("https://admin.topin.uz/api/auth", {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phone, verify_code }),
      });
      const value = await response.json();
      if (response.status === 200) {
        commit("setToken", value.data.token);
        commit("setRegistered", value.data.user ? true : false);
        if (value.data.user) {
          commit("setUser", value.data.user);
        }
      }
      return value.data.user;
    },
    async updateAccount({ commit, getters }, datas) {
      const {
        name,
        work_time,
        inn,
        description,
        address,
        website,
        email,
        category_id,
        is_business,
        birthday,
        sex,
        country,
        image,
        banner,
      } = datas;
      const data = new FormData();
      data.append("name", name);
      if (work_time) data.append("work_time", work_time);
      if (category_id) data.append("category_id", category_id);
      if (inn) data.append("inn", inn);
      if (address) data.append("address", address);
      if (birthday) data.append("birthday", birthday);
      if (sex) data.append("sex", sex);
      if (country) data.append("country", country);
      if (description) data.append("description", description);
      if (website) data.append("website", website);
      if (email) data.append("email", email);
      if (is_business) data.append("is_business", is_business);
      if (image) data.append("image", image);
      if (banner) data.append("banner", banner);
      data.append("_method", "PUT");
      const response = await fetch("https://admin.topin.uz/api/account", {
        method: "POST",
        headers: {
          Authorization: `Bearer ${getters.token}`,
        },
        body: data,
      });
      const value = await response.json();
      if (response.status === 200) {
        commit("setRegistered", value.data.registered);
        commit("setUser", value.data);
      }
      return response.status === 200;
    },
    async getUserData({ commit, getters }) {
      const response = await fetch("https://admin.topin.uz/api/user", {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
          Authorization: `Bearer ${getters.token}`,
        },
      });
      const value = await response.json();
      if (response.status === 200) {
        commit("setUser", value);
        commit("setRegistered", value.registered);
        return getters.user;
      } else {
        commit("removeUserData");
        return false;
      }
    },
  },
  mutations: {
    removeUserData(state) {
      localStorage.removeItem("token");
      localStorage.removeItem("registered");
      localStorage.removeItem("phone");
      localStorage.removeItem("user");
      localStorage.removeItem("business");
      state.token = null;
      state.registered = null;
      state.phone = null;
      state.user = null;
      state.business = null;
    },
    setToken(state, data) {
      if (data) {
        localStorage.setItem("token", JSON.stringify(data));
      }
      state.token = data;
    },
    setRegistered(state, data) {
      if (data) {
        localStorage.setItem("registered", JSON.stringify(data));
      }
      state.registered = data;
    },
    setPhone(state, data) {
      if (data) {
        localStorage.setItem("phone", JSON.stringify(data));
      }
      state.phone = data;
    },
    setUser(state, data) {
      if (data) {
        localStorage.setItem("user", JSON.stringify(data));
      }
      state.user = data;
    },
    setBusiness(state, data) {
      if (data) {
        localStorage.setItem("business", JSON.stringify(data));
      }
      state.business = data;
    },
  },
  getters: {
    token(state) {
      return state.token;
    },
    user(state) {
      return state.user;
    },
    registered(state) {
      return state.registered;
    },
    business(state) {
      return state.business;
    },
    phone(state) {
      return state.phone;
    },
  },
};
