<template>
  <div class="enter">
    <form @submit.prevent="submit" class="enter-modal">
      <div class="enter-modal_logo">
        <img src="../assets/images/logo1.svg" alt />
      </div>
      <div
        class="enter-modal_type"
      >{{$store.getters.strings[$store.getters.currentLang].chooseTypeAcc}}</div>
      <div class="enter-modal_click">
        <div
          @click="business = false"
          :class="!business ? 'plain': 'business'"
        >{{$store.getters.strings[$store.getters.currentLang].simpleAcc}}</div>
        <div
          @click="business = true"
          :class="business ? 'plain': 'business'"
        >{{$store.getters.strings[$store.getters.currentLang].bussinessAcc}}</div>
      </div>
      <div class="enter-modal_btn">
        <button>{{$store.getters.strings[$store.getters.currentLang].continue}}</button>
      </div>
    </form>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";

export default {
  data() {
    return {
      business: false,
    };
  },
  methods: {
    submit: async function () {
      this.$store.commit("setBusiness", this.business);
      if (!this.business) {
        this.$router.push("/registration");
      } else {
        this.$router.push("/business/register");
      }
    },
    ...mapActions(["authorize"]),
  },
  computed: {
    ...mapGetters(["phone"]),
  },
};
</script>