import Vue from "vue";
import Router from "vue-router";
// import store from "./resource/store";

import Home from "@/pages/Home";
import Search from "@/pages/Search";
import AddProduct from "@/pages/AddProduct";
import EditProduct from "@/pages/EditProduct";
import Profile from "@/pages/Profile";
import User from "@/pages/User";
import UserBuisness from "@/pages/UserBuisness";
import Prewiew from "@/pages/Prewiew";
import Entering1 from "@/pages/Entering1";
import Entering2 from "@/pages/Entering2";
import Entering3 from "@/pages/Entering3";
import Registration from "@/pages/Registration";
import Replenishment from "@/pages/Replenishment";
import Reclam from "@/pages/Reclam";
import MessageChat from "@/pages/MessageChat";
import Settings from "@/pages/Settings";
import ProfileReg from "@/pages/ProfileReg";
import Ads from "@/pages/Ads";
import Operations from "@/pages/Operations";
import Messages from "@/pages/Messages";
import Favorite from "@/pages/Favorite";
import Cart from "@/pages/Cart";
import BuisnessReg from "@/pages/BuisnessReg";
import BusinessProfile from "@/pages/BusinessProfile";
import Policy from "@/pages/Policy";
import store from "./store";

Vue.use(Router);
let router = new Router({
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  base: "/",
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/search/:id",
      name: "search",
      component: Search,
    },
    {
      path: "/addProduct",
      name: "addProduct",
      component: AddProduct,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profile",
      name: "profile",
      component: Profile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/user/:id",
      name: "user",
      component: User,
    },
    {
      path: "/prewiew/:id",
      name: "prewiew",
      component: Prewiew,
    },
    {
      path: "/login",
      name: "entering1",
      component: Entering1,
    },
    {
      path: "/verify",
      name: "entering2",
      component: Entering2,
    },
    {
      path: "/choose/type",
      name: "entering3",
      component: Entering3,
    },
    {
      path: "/registration",
      name: "registration",
      component: Registration,
    },
    {
      path: "/business/register",
      name: "BuisnessReg",
      component: BuisnessReg,
    },
    {
      path: "/replenishment",
      name: "replenishment",
      component: Replenishment,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/reclam",
      name: "reclam",
      component: Reclam,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/messages/chat/:id",
      name: "MessageChat",
      component: MessageChat,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/settings",
      name: "Settings",
      component: Settings,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/profileReg",
      name: "ProfileReg",
      component: ProfileReg,
    },
    {
      path: "/ads",
      name: "Ads",
      component: Ads,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/operations",
      name: "Operations",
      component: Operations,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/messages",
      name: "Messages",
      component: Messages,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/edit/advert/:id",
      name: "EditProduct",
      component: EditProduct,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/favorite",
      name: "Favorite",
      component: Favorite,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/cart/:id",
      name: "Cart",
      component: Cart,
    },
    {
      path: "/user/business/profile/:name/:id",
      name: "UserBuisness",
      component: UserBuisness,
    },
    {
      path: "/profile/business",
      name: "BusinessProfile",
      component: BusinessProfile,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/policy",
      name: "Policy",
      component: Policy,
    },
  ],
});
router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (store.getters.registered) {
      next();
      return;
    }
    next("/login");
  } else {
    next();
  }
});

export default router;
