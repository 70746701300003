<template>
  <div>
    <div class="profile-product" v-for="(prof, index) in data" :key="index">
      <div class="profile-product_wrap">
        <div class="left">
          <div
            class="image"
            :style="
              !!prof.image ? `background-image: url(${prof.image[0]});` : ''
            "
          >
            <div class="image-right">
              <img src="../assets/images/top-right.svg" />
            </div>
            <div class="image-bottom">
              <img src="../assets/images/orange.svg" alt />
            </div>
          </div>
        </div>
        <div class="right">
          <div class="right-title">{{ prof.name }}</div>
          <div class="right-about" v-if="prof.region">
            <span v-if="$store.getters.currentLang == 1">
              {{ prof.region.name_ru }}
            </span>
            <span v-if="$store.getters.currentLang == 2">
              {{ prof.region.name_uz }}
            </span>
            <span v-if="$store.getters.currentLang == 3">
              {{ prof.region.name_kr }}
            </span>
            <span>{{ prof.price }} UZS</span>
          </div>
        </div>
      </div>
      <div class="profile-product_buttons">
        <router-link class="lnk" :to="'/prewiew/' + prof.id">
          {{ $store.getters.strings[$store.getters.currentLang].see }}
        </router-link>
        <router-link class="lnk" :to="'/edit/advert/' + prof.id">
          {{ $store.getters.strings[$store.getters.currentLang].edit }}
        </router-link>
        <button class="lnk" @click="switcher(prof.id)">
          <template v-if="prof.status != 'DISABLED'">
            {{ $store.getters.strings[$store.getters.currentLang].off }}
          </template>
          <template v-else>
            {{ $store.getters.strings[$store.getters.currentLang].enable }}
          </template>
        </button>
        <button
          :class="`lnk ${prof.promoted_count != 0 ? 'disabled' : ''}`"
          @click="prof.promoted_count == 0 && toReclam(prof.id)"
        >
          <template v-if="prof.promoted_count == 0">
            {{ $store.getters.strings[$store.getters.currentLang].promote }}
          </template>
          <template v-else>
            {{ $store.getters.strings[$store.getters.currentLang].promoCount }}
            {{ prof.promoted_count }}
          </template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    selected: {
      type: Number,
      required: false,
    },
  },
  computed: {
    ...mapGetters(["currentLang"]),
  },
  methods: {
    toReclam: function (id) {
      const advert = this.data.find((ad) => ad.id == id);
      this.$router.push({
        name: "reclam",
        params: { advert: advert },
      });
    },
    switcher: function (id) {
      this.switchStatus(parseInt(id)).then((status) => {
        if (status) {
          const filtered = this.$store.getters.myAdverts.filter(
            (item) => item.id !== id
          );
          this.$store.commit("setMyAdverts", filtered);
        }
      });
    },
    ...mapActions(["switchStatus", "getMyAdverts"]),
  },
};
</script>
